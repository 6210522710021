import school from './school'
import schools from './schools'
import auth from './auth'
import home from './home'
import notFound from './notFound'
import sitemap from './sitemap'
import services from './services'
import statistic from './statistic'
import adminComments from './adminComments'
import adminArticles from './adminArticles'

const index = {
  ...auth,
  ...home,
  ...school,
  ...schools,
  ...notFound,
  ...sitemap,
  ...services,
  ...statistic,
  ...adminComments,
  ...adminArticles,
}

export default index
