import { createStore as createReduxStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer, { initialState } from './reducers'
import middleware from './middlewares'

import type { IStore } from './reducers'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createStore = (initial: IStore | undefined = initialState) => {
  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  return createReduxStore(
    reducer,
    // @ts-ignore
    initial,
    composeWithDevTools(applyMiddleware(...middleware)),
  )
  // store.asyncReducers = {};

  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     const reducers = require('./reducers').default;
  //     store.replaceReducer(reducers(store.asyncReducers));
  //   });
  // }

  // return store
}

export default createStore
