import { TError as TErrorData } from '@services/handleError'
import { TCategory } from '@routers'

export type TIsReady = boolean
export type TError = TErrorData

export interface IState {
  isReady: TIsReady
  data: TGroups
  error: TError
}

export interface IGroupItem {
  category: TCategory
  region: string
  city: string
  id: string
}

export interface IGroup {
  name: {
    ua: string
    ru: string
  }
  id: string
  list: Array<IGroupItem>
}

export type TGroups = Array<IGroup> | []

export type TGroupIds = Array<string> | undefined

export interface IUseGroups {
  isReady: TIsReady
  groups: TGroups
  fetchGroups: (groupIds?: TGroupIds, callback?: () => void) => void
  addGroup: (group: IGroupForm) => void
  addToGroup: (
    groupId: string,
    item: IGroupItem,
    withItemUpdate?: boolean,
  ) => void
  deleteFromGroup: (groupId: string, item: IGroupItem) => void
}

export interface IGroupItemForm extends Omit<IGroupItem, 'ref'> {
  isNew: boolean
}

export interface IGroupForm extends Omit<IGroup, 'list'> {
  index: number
  isOpen: boolean
  isEdit: boolean
  isNew: boolean
  list: Array<IGroupItemForm>
}

export interface IGroupsForm {
  groups: Array<IGroupForm> | []
}

// Fetch content

export const FETCH_GROUPS_START = 'groups/FETCH_GROUPS_START'

export interface IFetchGroupsStartAction {
  type: 'groups/FETCH_GROUPS_START'
}

export const FETCH_GROUPS_FINISHED = 'groups/FETCH_GROUPS_FINISHED'

export interface IFetchGroupsFinishedAction {
  type: 'groups/FETCH_GROUPS_FINISHED'
  payload: TGroups
}

export const FETCH_GROUPS_FAILED = 'groups/FETCH_GROUPS_FAILED'

export interface IFetchGroupsFailedAction {
  type: 'groups/FETCH_GROUPS_FAILED'
  payload: TError
}

// Add group

export const ADD_GROUP_START = 'groups/ADD_GROUP_START'

export interface IAddGroupStartAction {
  type: 'groups/ADD_GROUP_START'
}

export const ADD_GROUP_FINISHED = 'groups/ADD_GROUP_FINISHED'

export interface IAddGroupFinishedAction {
  type: 'groups/ADD_GROUP_FINISHED'
  payload: IGroupForm
}

export const ADD_GROUP_FAILED = 'groups/ADD_GROUP_FAILED'

export interface IAddGroupFailedAction {
  type: 'groups/ADD_GROUP_FAILED'
  payload: TError
}

// Add item to group

export const ADD_TO_GROUP_START = 'groups/ADD_TO_GROUP_START'

export interface IAddToGroupStartAction {
  type: 'groups/ADD_TO_GROUP_START'
}

export const ADD_TO_GROUP_FINISHED = 'groups/ADD_TO_GROUP_FINISHED'

export interface IAddToGroupFinishedAction {
  type: 'groups/ADD_TO_GROUP_FINISHED'
  payload: IGroup
}

export const ADD_TO_GROUP_FAILED = 'groups/ADD_TO_GROUP_FAILED'

export interface IAddToGroupFailedAction {
  type: 'groups/ADD_TO_GROUP_FAILED'
  payload: TError
}

// Delete from  group

export const DELETE_FROM_GROUP_START = 'groups/DELETE_FROM_GROUP_START'

export interface IDeleteFromGroupStartAction {
  type: 'groups/DELETE_FROM_GROUP_START'
}

export const DELETE_FROM_GROUP_FINISHED = 'groups/DELETE_FROM_GROUP_FINISHED'

export interface IDeleteFromGroupFinishedAction {
  type: 'groups/DELETE_FROM_GROUP_FINISHED'
  payload: IGroup
}

export const DELETE_FROM_GROUP_FAILED = 'groups/DELETE_FROM_GROUP_FAILED'

export interface IDeleteFromGroupFailedAction {
  type: 'groups/DELETE_FROM_GROUP_FAILED'
  payload: TError
}

// Revoke

export const REVOKE = 'groups/REVOKE'

export interface IRevokeAction {
  type: 'groups/REVOKE'
}

export type TActionHandler =
  | IFetchGroupsStartAction
  | IFetchGroupsFinishedAction
  | IFetchGroupsFailedAction
  | IAddGroupStartAction
  | IAddGroupFinishedAction
  | IAddGroupFailedAction
  | IAddToGroupStartAction
  | IAddToGroupFinishedAction
  | IAddToGroupFailedAction
  | IDeleteFromGroupStartAction
  | IDeleteFromGroupFinishedAction
  | IDeleteFromGroupFailedAction
  | IRevokeAction
