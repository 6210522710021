import Db from '@services/Db'
import { VERSIONS } from '@config/db'

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  REVOKE,
  CLEAR_ERROR,
  SET_VERSION_STARTED,
  SET_VERSION_FINISHED,
  SET_VERSION_FAILED,
  TError,
  TVersion,
  IState,
  TActionHandler,
  TRevokeAction,
  IClearErrorAction,
  ISetVersionStartAction,
  ISetVersionFinishedAction,
  ISetVersionFailedAction,
} from './types'

// ------------------------------------
// Constants
// ------------------------------------

/**
 * Initial State
 */
export const initialState: IState = {
  isReady: false,
  data: {},
  error: null,
}

const _setVersionStart = (): ISetVersionStartAction => {
  return { type: SET_VERSION_STARTED }
}

const _setVersionFinished = (value: TVersion): ISetVersionFinishedAction => {
  return { type: SET_VERSION_FINISHED, payload: value }
}

const _setVersionFailed = (error: TError): ISetVersionFailedAction => {
  return {
    type: SET_VERSION_FAILED,
    payload: error,
  }
}

export const setVersion =
  (value: TVersion): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_setVersionStart())

      await Db.update(`${VERSIONS}`, value)

      dispatch(_setVersionFinished(value))
    } catch (error) {
      dispatch(_setVersionFailed(error))
    }
  }

export function revoke(): TRevokeAction {
  return {
    type: REVOKE,
  }
}

export function clearError(field: TError): IClearErrorAction {
  return {
    type: CLEAR_ERROR,
    payload: field,
  }
}

const ACTION_HANDLERS = {
  [SET_VERSION_STARTED]: (state: IState) => ({
    ...state,
    isReady: false,
  }),
  [SET_VERSION_FINISHED]: (
    state: IState,
    action: ISetVersionFinishedAction,
  ) => {
    return {
      ...state,
      data: { ...state.data, ...action.payload },
      isReady: true,
    }
  },
  [SET_VERSION_FAILED]: (state: IState, action: ISetVersionFailedAction) => ({
    ...state,
    error: action.payload,
    isReady: true,
  }),
  [CLEAR_ERROR]: (state: IState, action: IClearErrorAction) => {
    const error = action.payload === undefined ? null : action.payload
    return {
      ...state,
      error,
    }
  },
  [REVOKE]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function versionsReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
