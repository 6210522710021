import { TErrorStore } from '@services/handleError'

export type TSubscriptionStatus = null | 'pending' | 'fulfilled' | 'rejected'

type TTUnsubscribe = () => void
export type TUnsubscribe = TTUnsubscribe | null
export type TCallbackUnsubscribe = (func: TUnsubscribe) => void

export type TFavorite = any

export type TFavorites = Array<TFavorite> | []

// export interface IFavorites {
//   environmentId: string
//   organizationId: string
//   favorites: TFavorites
//   // deleted?: boolean
//   created?: TTimestamp
//   updated?: TTimestamp
//   version?: number
// }
//
// // eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface IFavoritesForm
//   extends Omit<IEnvironmentUsers, 'deleted' | 'created' | 'updated'> {}

export interface IUseFavorites {
  isReady: boolean
  count: number
  favorites: TFavorites
  subscriptionStatus: TSubscriptionStatus
  isFavoriteId: (id: string) => boolean
  isFavorite: (fullId: string) => boolean
  add: (id: string) => void
  remove: (id: string) => void
  fetchAndSubscribe: (callback: TCallbackUnsubscribe) => void
  subscribe: () => void
  unsubscribe: () => void
  revoke: () => void
}

export interface IState {
  isReady: boolean
  isReadyUpdate: boolean
  list: TFavorites
  subscriptionStatus: TSubscriptionStatus
  error: TErrorStore
  version: number
}

// Change Subscription Status

export const CHANGE_SUBSCRIPTION_STATUS = 'favorites/CHANGE_SUBSCRIPTION_STATUS'

export interface IChangeSubscriptionStatus {
  type: 'favorites/CHANGE_SUBSCRIPTION_STATUS'
  payload: TSubscriptionStatus
}

// Subscribe favorites

export const SUBSCRIBE_STARTED = 'favorites/SUBSCRIBE_STARTED'

export interface ISubscribeStartedAction {
  type: 'favorites/SUBSCRIBE_STARTED'
}

export const SUBSCRIBE_FINISHED = 'favorites/SUBSCRIBE_FINISHED'

export interface ISubscribeFinishedAction {
  type: 'favorites/SUBSCRIBE_FINISHED'
  payload: TFavorites
}

export const SUBSCRIBE_FAILED = 'favorites/SUBSCRIBE_FAILED'

export interface ISubscribeFailedAction {
  type: 'favorites/SUBSCRIBE_FAILED'
  payload: TErrorStore
}

// Add to favorites

export const ADD_STARTED = 'favorites/ADD_STARTED'

export interface IAddStartedAction {
  type: 'favorites/ADD_STARTED'
}

export const ADD_FINISHED = 'favorites/ADD_FINISHED'

export interface IAddFinishedAction {
  type: 'favorites/ADD_FINISHED'
  payload: TFavorites
}

export const ADD_FAILED = 'favorites/ADD_FAILED'

export interface IAddFailedAction {
  type: 'favorites/ADD_FAILED'
  payload: TErrorStore
}

// Remove to favorites

export const REMOVE_STARTED = 'favorites/REMOVE_STARTED'

export interface IRemoveStartedAction {
  type: 'favorites/REMOVE_STARTED'
}

export const REMOVE_FINISHED = 'favorites/REMOVE_FINISHED'

export interface IRemoveFinishedAction {
  type: 'favorites/REMOVE_FINISHED'
  payload: TFavorites
}

export const REMOVE_FAILED = 'favorites/REMOVE_FAILED'

export interface IRemoveFailedAction {
  type: 'favorites/REMOVE_FAILED'
  payload: TErrorStore
}

// Revoke

export const REVOKE = 'favorites/REVOKE'

export interface IRevokeAction {
  type: 'favorites/REVOKE'
}

export type TActionHandler =
  | IAddStartedAction
  | IAddFinishedAction
  | IAddFailedAction
  | IRemoveStartedAction
  | IRemoveFinishedAction
  | IRemoveFailedAction
  | ISubscribeStartedAction
  | ISubscribeFinishedAction
  | ISubscribeFailedAction
  | IRevokeAction
