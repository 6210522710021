export interface ILocalStorageConstants {
  LAST_PAGE: string
  COPY_STORE: string
  PAGE_LIMIT: string
  CURRENT_USER: string
  EMAIL: string
  VISIBILITY: string
  ARTICLES_VISIBILITY: string
  SORTING: string
  IS_MAP: string
  IS_FIRST_RENDERED: string
  FIRST_RENDER_TIME: string
}

const localStorageConstants: ILocalStorageConstants = {
  LAST_PAGE: 'last_page',
  COPY_STORE: 'copy_store',
  PAGE_LIMIT: 'page_limit',
  CURRENT_USER: 'cur_user',
  EMAIL: 'email',
  VISIBILITY: 'visibility',
  ARTICLES_VISIBILITY: 'articles_visibility',
  SORTING: 'sorting',
  IS_MAP: 'is_map',
  IS_FIRST_RENDERED: 'is_first_rendered',
  FIRST_RENDER_TIME: 'first_render_time',
}

export default localStorageConstants
