import React, { ReactElement, ReactNode } from 'react'
import CardComponent from '@mui/material/Card'
import { useBreakpointDown } from '@styles/useMediaQuery'
import constants from '@stylesConstants'

interface IProps {
  children: ReactNode
  className?: string
  classes?: object
  elevation?: number
  raised?: boolean
  square?: boolean
}

export default function Card({
  children,
  classes = {},
  className,
  elevation,
  raised,
  square,
}: IProps): ReactElement {
  const isXs = useBreakpointDown('sm')
  const isSquare = isXs ? square || true : square
  const isRaised = isXs ? raised || false : raised
  const curElevation = isXs ? elevation || 0 : elevation

  return (
    <CardComponent
      classes={classes}
      sx={{ marginTop: isXs ? 0 : `${constants.margin.card}px` }}
      className={className}
      elevation={curElevation}
      raised={isRaised}
      square={isSquare}
    >
      {children}
    </CardComponent>
  )
}

// square elevation={0}
