export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyDzTTa5ZDLj7-e2rNa8Qt_qFQsuNFslcfM',
  authDomain: 'school-hub-a5254.firebaseapp.com',
  databaseURL:
    'https://school-hub-a5254-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'school-hub-a5254',
  // storageBucket: 'school-hub-a5254.appspot.com',
  storageBucket: 'school-hub-a5254-storage-us-central1',
  messagingSenderId: '916071523055',
  appId: '1:916071523055:web:ba37c2e95cf892367ef11a',
  measurementId: 'G-8524BB0RRG',
}

export default firebaseConfig
