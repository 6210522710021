import {
  SHOW,
  PUSH,
  HIDE,
  REVOKE,
  IState,
  TCurrentType,
  IShowProps,
  IShowAction,
  IPushAction,
  IHideAction,
  IRevokeAction,
  TActionHandler,
} from './types'

export const initialState: IState = {
  currentType: null,
  data: {},
  props: {},
  styles: {},
}

export function showDialog(
  currentType: TCurrentType,
  { data, props, styles }: IShowProps,
): IShowAction {
  return {
    type: SHOW,
    payload: {
      currentType,
      data,
      props,
      styles,
    },
  }
}

export function pushDialog(
  currentType: TCurrentType,
  { data, props, styles }: IShowProps,
): IPushAction {
  return {
    type: PUSH,
    payload: {
      currentType,
      data,
      props,
      styles,
    },
  }
}

export function hideDialog(): IHideAction {
  return {
    type: HIDE,
  }
}

export function revokeDialog(): IRevokeAction {
  return {
    type: REVOKE,
  }
}

const ACTION_HANDLERS = {
  [SHOW]: (state: IState, action: IShowAction) => {
    const { currentType, data, props, styles } = action.payload
    return {
      ...state,
      currentType,
      data: data || {},
      props: props || {},
      styles: styles || {},
    }
  },
  [PUSH]: (state: IState, action: IPushAction) => {
    const { currentType, data, props, styles } = action.payload
    return {
      ...state,
      currentType,
      data: data || {},
      props: props || {},
      styles: styles || {},
    }
  },
  [HIDE]: () => {
    return initialState
  },
  [REVOKE]: () => {
    return initialState
  },
}

export default function dialogReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
