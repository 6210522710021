import { showSnackBar } from '@containers/Snackbar/modules'
import firebase, { auth, Timestamp } from '@services/firebase'
import { ThunkDispatch } from 'redux-thunk'
import { updateUser } from '@features/User/modules'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { TLocale } from '@i18n'
import { routes, TPush } from '@routers'

import {
  LOGIN_MICROSOFT_FAILED,
  LOGIN_MICROSOFT_FINISHED,
  LOGIN_MICROSOFT_STARTED,
} from '../types'

// All Action Handlers are located in ../modules.ts

const _loginMicrosoftStart = () => {
  return { type: LOGIN_MICROSOFT_STARTED }
}

const _loginMicrosoftFinished = () => {
  return { type: LOGIN_MICROSOFT_FINISHED }
}

const _loginMicrosoftFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGIN_MICROSOFT_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const loginMicrosoft =
  (locale: TLocale, push: TPush) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_loginMicrosoftStart())

      const provider = new firebase.auth.OAuthProvider('microsoft.com')
      provider.addScope('profile')
      const result = await auth.signInWithPopup(provider)

      if (result !== null && result?.additionalUserInfo?.isNewUser) {
        const {
          additionalUserInfo: {
            // @ts-ignore
            profile: { displayName, givenName, surname },
          },
          // @ts-ignore
          user: { uid, email },
        } = result

        dispatch(
          updateUser(
            uid,
            {
              created: Timestamp.now(),
              deleted: false,
              displayName,
              email,
              emailVerified: true,
              firstName: givenName,
              lastName: surname,
              locale,
            },
            () => {
              dispatch(_loginMicrosoftFinished())
              push(routes.HOME)
            },
          ),
        )
      } else {
        dispatch(_loginMicrosoftFinished())
      }
    } catch (error) {
      dispatch(_loginMicrosoftFailed('auth.popupMicrosoft', error))
    }
  }

export default loginMicrosoft
