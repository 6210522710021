import { COMPANY, VERSION } from '@config'

const year = new Date().getFullYear()

const lang = {
  footer: {
    COPYRIGHT: `@ 2020-${year} ${COMPANY} - Навчальні заклади України`,
    VERSION: `Версія: ${VERSION}`,
    YEARS: `@ 2020-${year} `,
    DESCRIPTION: 'Навчальні заклади України',
    ABOUT_US: 'Про нас',
    SERVICES: 'Наші послуги',
    STATISTIC: 'Статистика сайту',
    CONTACTS: 'Контакти',
    TERMS_OF_USE: 'Угода користувача',
  },
}

export default lang
