const lang = {
  kyiv: 'Киев',
  kharkiv: 'Харьков',
  odesa: 'Одесса',
  lviv: 'Львов',
  dnipro: 'Днепр',
  vinnytsia: 'Винница',
  lutsk: 'Луцк',
  donetsk: 'Донецк',
  zhytomyr: 'Житомир',
  uzhhorod: 'Ужгород',
  zaporizhzhia: 'Запорожье',
  'ivano-frankivsk': 'Ивано-Франковск',
  kropyvnytskyi: 'Кропивницкий',
  luhansk: 'Луганск',
  mykolaiv: 'Николаев',
  poltava: 'Полтава',
  rivne: 'Ровно',
  sumy: 'Сумы',
  ternopil: 'Тернополь',
  kherson: 'Херсон',
  khmelnytskyi: 'Хмельницкий',
  cherkasy: 'Черкасы',
  chernivtsi: 'Черновцы',
  chernihiv: 'Чернигов',
  simferopol: 'Симферополь',
  haisyn: 'Гайсин',
  zhmerynka: 'Жмеринка',
  tulchyn: 'Тульчин',
  'mohyliv-podilskyi': 'Могилев-Подольский',
  khmilnyk: 'Хмельник',
  'volodymyr-volynskyi': 'Владимир-Волынский',
  'kamin-kashyrskyi': 'Камень-Каширский',
  kovel: 'Ковель',
  kamianske: 'Каменское',
  'kryvyi-rih': 'Кривой Рог',
  nikopol: 'Никополь',
  novomoskovsk: 'Новомосковск',
  pavlohrad: 'Павлоград',
  synelnykove: 'Синельниково',
  berdiansk: 'Бердянск',
  vasylivka: 'Василевка',
  melitopol: 'Мелитополь',
  polohy: 'Пологи',
  berehove: 'Береговое',
  mukachiv: 'Мукачев',
  rakhiv: 'Рахов',
  tiachiv: 'Тячев',
  khust: 'Хуст',
  berdychiv: 'Бердичев',
  korosten: 'Коростень',
  'novohrad-volynskyi': 'Новоград-Волынский',
  verkhovyna: 'Верховина',
  kalush: 'Калуш',
  kolomyia: 'Коломыя',
  kosiv: 'Косов',
  nadvirna: 'Надворная',
  holovanivsk: 'Головановск',
  novoukrainka: 'Новоукраинка',
  oleksandriia: 'Александрия',
  drohobych: 'Дрогобыч',
  zolochiv: 'Золочев',
  sambir: 'Самбор',
  stryi: 'Стрый',
  chervonohrad: 'Червоноград',
  yavoriv: 'яворов',
  bashtanka: 'Баштанка',
  voznesensk: 'Вознесенск',
  pervomaisk: 'Первомайск',
  izmail: 'Измаил',
  berezivka: 'Березівка',
  'bilhorod-dnistrovskyi': 'Белгород-Днестровский',
  bolhrad: 'Болград',
  podilsk: 'Подольск',
  rozdilna: 'Роздельная',
  kremenchuh: 'Кременчуг',
  lubny: 'Лубны',
  myrhorod: 'Миргород',
  varash: 'Вараш',
  dubno: 'Дубно',
  sarny: 'Сарни',
  konotop: 'Конотоп',
  okhtyrka: 'Ахтырка',
  romny: 'Ромни',
  shostka: 'Шостка',
  kremenets: 'Кременец',
  chortkiv: 'Чортков',
  bohodukhiv: 'Богодухов',
  izium: 'Изюм',
  krasnohrad: 'Красноград',
  kupiansk: 'Купянск',
  lozova: 'Лозовая',
  chuhuiv: 'Чугуев',
  beryslav: 'Берислав',
  henichesk: 'Геническ',
  'nova-kakhovka': 'Новая Каховка',
  skadovsk: 'Скадовск',
  'kamianets-podilskyi': 'Каменец-Подольский',
  shepetivka: 'Шепетовка',
  zvenyhorodka: 'Звенигородка',
  zolotonosha: 'Золотоноша',
  uman: 'Умань',
  vyzhnytsia: 'Вижница',
  kelmentsi: 'Кельменцы',
  koriukivka: 'Корюковка',
  nizhyn: 'Нежин',
  'novhorod-siverskyi': 'Новгород-Северск',
  pryluky: 'Прилуки',
  region: 'Область',
}

export default lang
