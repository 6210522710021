const lang = {
  validations: {
    auth: {
      INVALID_EMAIL: 'Неправильный email',
      PASSWORD_SHORT:
        'Пароль слишком короткий - должно быть не менее 6 символов',
      INVALID_PASSWORD:
        'Must contain one uppercase, one lowercase, one number and one special character',
      CONFIRM_PASSWORD: 'Пароли должны совпадать',
      ROLE: 'Необходимо выбрать роль пользователя',
    },
    email: {
      INVALID_EMAIL: 'Неправильный email',
      ADDED: 'Пользователь уже добавлен',
      EXIST: 'Пользователь уже существует',
      DELETED: 'Пользователь удален',
    },
    password: {
      PASSWORD_SHORT:
        'Пароль слишком короткий - должно быть не менее 6 символов',
      INVALID_PASSWORD:
        'Must contain one uppercase, one lowercase, one number and one special character',
      CONFIRM_PASSWORD: 'Пароли должны совпадать',
    },
    form: {
      REQUIRED: 'Обязательное поле',
      TOO_SHORT: 'Слишком короткий',
      TOO_LONG: 'Слишком длинный',
    },
    phone: {
      COUNTRY_CODE: 'Неверный код страны',
      LOCAL_NUMBER: 'Пустой номер телефона',
    },
  },
}

export default lang
