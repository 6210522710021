import districtsInRegions from './districtsInRegions'

const lang = {
  ...districtsInRegions,
  holosiivskyi: 'Голосеевский',
  obolonskyi: 'Оболонский',
  pecherskyi: 'Печерский',
  podilskyi: 'Подольский',
  sviatoshynskyi: 'Святошинский',
  solomianskyi: 'Соломенский',
  shevchenkivskyi: 'Шевченковский',
  darnytskyi: 'Дарницкий',
  desnianskyi: 'Деснянский',
  dniprovskyi: 'Днепровский',
  zamostianskyi: 'Замостянский',
  leninskyi: 'Ленинский',
  staromiskyi: 'Старогородской',
  novokodatskyi: 'Новокодакский',
  tsentralnyi: 'Центральный',
  chechelivskyi: 'Чечеловский',
  sobornyi: 'Соборный ',
  'amur-nyzhnodniprovskyi': 'Амур-Нижнеднепровский',
  industrialnyi: 'Индустриальный',
  samarskyi: 'Самарский',
  bohunskyi: 'Богунский',
  korolovskyi: 'Королевский',
  oleksandrivskyi: 'Александровский',
  zavodskyi: 'Заводской',
  komunarskyi: 'Коммунарский',
  voznesenivskyi: 'Вознесеновский',
  khortytskyi: 'Хортицкий',
  fortechnyi: 'Крепостной',
  lychakivskyi: 'Лычаковский',
  sykhivskyi: 'Сыховский',
  frankivskyi: 'Франковский',
  zaliznychnyi: 'Зализнычный',
  halytskyi: 'Галицкий',
  inhulskyi: 'Ингульский',
  korabelnyi: 'Корабельный',
  kyivskyi: 'Киевский',
  malynovskyi: 'Малиновский',
  prymorskyi: 'Приморский',
  suvorovskyi: 'Суворовский',
  moskovskyi: 'Московский',
  nemyshlianskyi: 'Немышлянский',
  slobidskyi: 'Слободской',
  osnovianskyi: 'Основянский',
  sosnivskyi: 'Сосновский',
  prydniprovskyi: 'Приднепровский',
  kholodnohirskyi: 'Холодногорский',
  novobavarskyi: 'Новобаварский',
}

export default lang
