export type TAnchor = 'left' | 'right' | 'top' | 'bottom'
export type TVariant = 'permanent' | 'persistent' | 'temporary'
export type TCurrentType =
  | 'catalog'
  | 'compare'
  | 'favorite'
  | 'filter'
  | 'menu'
  | null

export type IData = object

export interface IParams {
  anchor?: TAnchor
  variant?: TVariant
  className?: string
  classes?: object
  sx?: object
  disableBackdropTransition?: boolean
  disableDiscovery?: boolean
  disableSwipeToOpen?: boolean
  hysteresis?: number
  minFlingVelocity?: number
  swipeAreaWidth?: number
  transitionDuration?: number | { enter?: number; exit?: number }
  title?: string
}

export type TIsOpen = boolean

export interface IState {
  isOpen: TIsOpen
  currentType: TCurrentType
  data: IData
  params: IParams
}

export interface IUseDrawer {
  isOpen: boolean
  currentType: TCurrentType
  params: IParams
  data: IData
  show: (currentType: TCurrentType, data?: IData, params?: IParams) => void
  hide: () => void
  revoke: () => void
}

export const SHOW = 'drawer/SHOW'

export interface IShowAction {
  type: 'drawer/SHOW'
  payload: {
    currentType: TCurrentType
    data: IData
    params: IParams
  }
}

export const HIDE = 'drawer/HIDE'

export interface IHideAction {
  type: 'drawer/HIDE'
}

export const REVOKE = 'drawer/REVOKE'

export interface IRevokeAction {
  type: 'drawer/REVOKE'
}

export type TActionHandler = IShowAction | IHideAction | IRevokeAction
