import React, { createContext, useState, useMemo, ReactElement } from 'react'

interface IProps {
  children: ReactElement
}

interface IContext {
  isReady: (mapId: string) => boolean
  setIsReady: (mapId: string, status: boolean) => void
}

export const MapContext = createContext<IContext>({
  isReady: () => false,
  setIsReady: () => {},
})

export const MapContextProvider = ({ children }: IProps): ReactElement => {
  const [state, setState] = useState<{ [key in string]: boolean }>({})
  const setIsReady = (mapId: string, status: boolean) => {
    setState({ ...state, [mapId]: status })
  }
  const isReady = (mapId: string): boolean => state[mapId]
  const value = useMemo(() => ({ isReady, setIsReady }), [state])

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>
}
