export interface IMap {
  SHOW_ALL: string
  SHOW: string
  HIDE: string
  SMALL_SHOW: string
  SMALL_HIDE: string
}

export interface ISearch {
  LABEL: string
  PLACEHOLDER: string
}

export interface ISorting {
  LABEL: string
  PRIVATE: string
  NUMBER: string
  NEW: string
  RATING: string
  SMALL_PRIVATE: string
  SMALL_NUMBER: string
  SMALL_NEW: string
  SMALL_RATING: string
}

export interface IActions {
  map: IMap
  search: ISearch
  sorting: ISorting
}

const lang: { actions: IActions } = {
  actions: {
    map: {
      SHOW_ALL: 'Подивитись все на мапі',
      SHOW: 'Показати мапу',
      HIDE: 'Сховати мапу',
      SMALL_SHOW: 'Мапа',
      SMALL_HIDE: 'Мапа',
    },
    search: {
      LABEL: 'Швидкий пошук',
      PLACEHOLDER: 'Назва, номер, адреса, контакти',
    },
    sorting: {
      LABEL: 'Сортування',
      PRIVATE: 'Спочатку приватні',
      NUMBER: 'За номером',
      NEW: 'Спочатку нові',
      RATING: 'За рейтингом ЗНО',
      SMALL_PRIVATE: 'Від приватних',
      SMALL_NUMBER: 'За номером',
      SMALL_NEW: 'Новинки',
      SMALL_RATING: 'За рейтингом',
    },
  },
}

export default lang
