import { IGroup } from './types'

export const normalizeGroupsFromDbToForm = ({
  list,
  ...group
}: IGroup): IGroup => {
  return {
    ...group,
    list: list.map(
      ({
        // todo Remove after remove from DB
        // @ts-ignore
        ref,
        ...item
      }) => item,
    ),
  }
}
