import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { firestore } from '@services/firebase'
import { pick } from '@services/common'
import { normalizeFullId } from '@normalizers/normalizeDocName'
import { FAVORITES } from '@config/db'
import { TCategory } from '@routers'
import {
  IState,
  IRevokeAction,
  TActionHandler,
  TCallbackUnsubscribe,
  TFavorites,
  TSubscriptionStatus,
  IChangeSubscriptionStatus,
  ISubscribeFinishedAction,
  ISubscribeFailedAction,
  IAddFinishedAction,
  IAddFailedAction,
  IRemoveFinishedAction,
  IRemoveFailedAction,
  CHANGE_SUBSCRIPTION_STATUS,
  SUBSCRIBE_STARTED,
  SUBSCRIBE_FINISHED,
  SUBSCRIBE_FAILED,
  ADD_STARTED,
  ADD_FINISHED,
  ADD_FAILED,
  REMOVE_STARTED,
  REMOVE_FINISHED,
  REMOVE_FAILED,
  REVOKE,
} from './types'

// ------------------------------------
// Constants
// ------------------------------------

/**
 * Initial State
 */
export const initialState: IState = {
  isReady: true,
  isReadyUpdate: true,
  list: [],
  subscriptionStatus: null,
  error: null,
  version: 0,
}

// Change subscription

export const changeSubscription =
  (status: TSubscriptionStatus): ThunkAction<any, any, any, any> =>
  (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({ type: CHANGE_SUBSCRIPTION_STATUS, payload: status })
  }

// Fetch and subscribe

const _subscribeStart = () => {
  return { type: SUBSCRIBE_STARTED }
}

const _subscribeFinished = (favorites: TFavorites) => {
  return {
    type: SUBSCRIBE_FINISHED,
    payload: favorites,
  }
}

const _subscribeFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: SUBSCRIBE_FAILED,
      payload: handledError,
    },
  ]
}

export const fetchAndSubscribe =
  (
    userId: string,
    callback: TCallbackUnsubscribe,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_subscribeStart())

      const unsubscribe = firestore
        .collection(FAVORITES)
        .doc(userId)
        .onSnapshot(
          (favoritesSnap: any) => {
            dispatch(_subscribeFinished(favoritesSnap.data()?.list || []))
          },
          (error) => {
            dispatch(_subscribeFailed('favorites.SUBSCRIBE', error))
          },
        )

      callback(unsubscribe)
    } catch (error) {
      dispatch(_subscribeFailed('favorites.SUBSCRIBE', error))
    }
  }

// Add to favorite

const _addStart = () => {
  return { type: ADD_STARTED }
}

const _addFinished = (favorites: TFavorites) => {
  return {
    type: ADD_FINISHED,
    payload: favorites,
  }
}

const _addFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: ADD_FAILED,
      payload: handledError,
    },
  ]
}

export const add =
  (
    userId: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    item: any,
    params: {
      category?: TCategory
      region?: string
      city?: string
    },
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_addStart())
      const fullId = normalizeFullId(params, item.id)
      const itemLocation = {
        ...item,
        ...pick(params, ['category', 'region', 'city']),
        fullId,
      }

      const favoritesRef = firestore.collection(FAVORITES).doc(userId)
      const favoritesSnap = await favoritesRef.get()
      const favorites = favoritesSnap.data()
      let list = []

      if (favorites) {
        // @ts-ignore
        list = [...favorites.list, itemLocation]
      } else {
        list.push(itemLocation)
      }

      await favoritesRef.set({ list }, { merge: true })
      dispatch(_addFinished(list))
    } catch (error) {
      dispatch(_addFailed('favorites.ADD', error))
    }
  }

// Remove from favorite

const _removeStart = () => {
  return { type: REMOVE_STARTED }
}

const _removeFinished = (favorites: TFavorites) => {
  return {
    type: REMOVE_FINISHED,
    payload: favorites,
  }
}

const _removeFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: REMOVE_FAILED,
      payload: handledError,
    },
  ]
}

export const remove =
  (userId: string, fullId: string): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_removeStart())

      const favoritesRef = firestore.collection(FAVORITES).doc(userId)
      const favoritesSnap = await favoritesRef.get()
      const favorites = favoritesSnap.data()
      let list = []

      if (favorites) {
        list = favorites.list.filter((filter: any) => {
          return filter.fullId !== fullId
        })
      }

      await favoritesRef.set({ list }, { merge: true })
      dispatch(_removeFinished(list))
    } catch (error) {
      dispatch(_removeFailed('favorites.SUBSCRIBE', error))
    }
  }

// Revoke

export function revoke(): IRevokeAction {
  return { type: REVOKE }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CHANGE_SUBSCRIPTION_STATUS]: (
    state: IState,
    action: IChangeSubscriptionStatus,
  ) => {
    return {
      ...state,
      subscriptionStatus: action.payload,
    }
  },
  [SUBSCRIBE_STARTED]: (state: IState) => {
    return {
      ...state,
      isReady: false,
      subscriptionStatus: 'fulfilled',
      list: [],
      error: null,
    }
  },
  [SUBSCRIBE_FINISHED]: (state: IState, action: ISubscribeFinishedAction) => {
    return {
      ...state,
      list: [...action.payload],
      isReady: true,
      error: null,
    }
  },
  [SUBSCRIBE_FAILED]: (state: IState, action: ISubscribeFailedAction) => {
    return {
      ...state,
      isReady: true,
      error: action.payload,
    }
  },
  [ADD_STARTED]: (state: IState) => {
    return {
      ...state,
      isReadyUpdate: false,
      error: null,
    }
  },
  [ADD_FINISHED]: (state: IState, action: IAddFinishedAction) => {
    return {
      ...state,
      list: action.payload,
      isReadyUpdate: true,
      error: null,
    }
  },
  [ADD_FAILED]: (state: IState, action: IAddFailedAction) => {
    return {
      ...state,
      isReadyUpdate: true,
      error: action.payload,
    }
  },
  [REMOVE_STARTED]: (state: IState) => {
    return {
      ...state,
      isReadyUpdate: false,
      error: null,
    }
  },
  [REMOVE_FINISHED]: (state: IState, action: IRemoveFinishedAction) => {
    return {
      ...state,
      list: action.payload,
      isReadyUpdate: true,
      error: null,
    }
  },
  [REMOVE_FAILED]: (state: IState, action: IRemoveFailedAction) => {
    return {
      ...state,
      isReadyUpdate: true,
      error: action.payload,
    }
  },
  [REVOKE]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function favoritesReducer(
  state: IState = initialState,
  action: TActionHandler | never,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
