const lang = {
  notFound: {
    TITLE: 'Сторінка не знайдена',
    meta: {
      TITLE: '404 — Сторінка не знайдена',
      DESCRIPTION:
        'Перейдить на головну сторінку для плшуку потрібної інформації',
    },
  },
}

export default lang
