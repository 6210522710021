import thunk from 'redux-thunk'
import multiAction from './multiAction'
import asyncAction from './asyncAction'

// ======================================================
// Middleware Configuration
// ======================================================

const middlewares: Array<any> = []

middlewares.push(asyncAction)

middlewares.push(multiAction)

middlewares.push(thunk)

// ------------------------------------
// Export
// ------------------------------------

export default middlewares
