import districts from './districts'
import cities from './cities'
import regions from './regions'

const description =
  'Інформація про заклади, фото, адреси, телефон ті інші контакти, територія обслуговування'

type TPage = string | number

const getPage = (page: TPage): string => {
  return page ? `. Сторінка ${page}` : ''
}

const lang = {
  location: {
    districts,
    cities,
    regions,
    LABEL: 'Розташування',
    REGION_LABEL: (region: string): string => `${region} обл`,
    CITY_LABEL: (city: string): string => `м. ${city}`,
    labels: {
      REGIONS: 'Область *',
      CITIES: 'Місто *',
      DISTRICTS: 'Район',
      DISTANCE: 'Дистанційно',
    },
    default: {
      REGIONS: 'Виберіть область',
      CITIES: 'Виберіть місто',
      DISTRICTS: 'Виберіть район',
    },
    meta: {
      h1City: (category: string, region: string, city: string): string =>
        `${category} в місті ${city}`,
      h1Region: (category: string, region: string): string =>
        `${category}, ${region} область`,
      h1Category: (category: string): string => `Вибір ${category}`,
      titleCity: (
        category: string,
        region: string,
        city: string,
        page: TPage,
      ): string => {
        return `${category} в місті ${city}, ${region} область${getPage(page)}`
      },
      titleRegion: (category: string, region: string, page: TPage): string => {
        return `${category}, ${region} область${getPage(page)}`
      },
      titleCategory: (category: string, page: TPage): string => {
        return `Вибір ${category}${getPage(page)}`
      },
      descriptionCity: (
        category: string,
        region: string,
        city: string,
        page: TPage,
      ): string => {
        return `${category} в місті ${city}, ${region} область. ${description}${getPage(
          page,
        )}`
      },
      descriptionRegion: (
        category: string,
        region: string,
        page: TPage,
      ): string => {
        return `${category}, ${region} область. ${description}${getPage(page)}`
      },
      descriptionCategory: (category: string, page: TPage): string => {
        return `Вибір ${category}. ${description}${getPage(page)}`
      },
    },
  },
}

export default lang
