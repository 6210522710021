import { showSnackBar } from '@containers/Snackbar/modules'
import firebase, { auth, Timestamp } from '@services/firebase'
import { ThunkDispatch } from 'redux-thunk'
import { updateUser } from '@features/User/modules'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { TLocale } from '@i18n'

import {
  LOGIN_FACEBOOK_FAILED,
  LOGIN_FACEBOOK_FINISHED,
  LOGIN_FACEBOOK_STARTED,
} from '../types'
import { IUserUpdate, TRole } from '@features/User'

// All Action Handlers are located in ../modules.ts

const _loginFacebookStart = () => {
  return { type: LOGIN_FACEBOOK_STARTED }
}

const _loginFacebookFinished = () => {
  return { type: LOGIN_FACEBOOK_FINISHED }
}

const _loginFacebookFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGIN_FACEBOOK_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const loginFacebook =
  (locale: TLocale, callback?: () => void, role: TRole = 'user') =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_loginFacebookStart())

      const provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('public_profile')
      provider.addScope('email')
      const result = await auth.signInWithPopup(provider)

      if (result !== null && result?.additionalUserInfo?.isNewUser) {
        const {
          additionalUserInfo: {
            // eslint-disable-next-line camelcase
            profile: { first_name, last_name },
          },
          user: { uid, displayName, email, photoURL, phoneNumber },
        } = result as any

        const user: IUserUpdate = {
          userId: uid,
          displayName,
          role,
          email,
          emailVerified: true,
          firstName: first_name,
          lastName: last_name,
          phoneNumber,
          photoURL,
          locale,
          created: Timestamp.now(),
          deleted: false,
        }

        dispatch(
          updateUser(uid, user, () => {
            dispatch(_loginFacebookFinished())

            if (callback) {
              callback()
            }
          }),
        )
      } else {
        dispatch(_loginFacebookFinished())

        if (callback) {
          callback()
        }
      }
    } catch (error) {
      dispatch(_loginFacebookFailed('auth.popupFacebook', error))
    }
  }

export default loginFacebook
