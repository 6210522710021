const lang = {
  TITLE: 'Активировать email',
  DESCRIPTION: 'Пожалуйста проверьте свой email и обновите страницу',
  RESEND: 'Сборсьте email',
  LOGIN: 'Войти как другой пользователь',
  ACTIVATED: 'Пользователь уже активирован',
  HOME: 'На главную страницу',
  AUTHORIZED: 'Вы уже вошли как пользователь',
  RELOAD: 'Обновить',
}

export default lang
