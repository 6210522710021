import { COMPANY, VERSION } from '@config'

const year = new Date().getFullYear()

const lang = {
  footer: {
    COPYRIGHT: `@ 2020-${year} ${COMPANY} - Учебные заведения Украины`,
    VERSION: `Версия: ${VERSION}`,
    YEARS: `@ 2020-${year} `,
    DESCRIPTION: 'Учебные заведения Украины',
    ABOUT_US: 'Про нас',
    SERVICES: 'Наши услуги',
    STATISTIC: 'Статистика сайта',
    CONTACTS: 'Контакты',
    TERMS_OF_USE: 'Пользовательское соглашение',
  },
}

export default lang
