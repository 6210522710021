export interface IBreakpoints {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}

export type TBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface IMargin {
  card: number
}

export interface IWidth {
  authCard: number
  column: number
  container: number
}

export interface IHeight {
  footer: number
  footerTabs: number
  header: number
}

export interface IConstants {
  margin: IMargin
  width: IWidth
  height: IHeight
  breakpoints: IBreakpoints
}

const constants: IConstants = {
  margin: {
    card: 24,
  },
  width: {
    authCard: 400,
    column: 240,
    container: 800,
  },
  height: {
    footer: 152,
    footerTabs: 72,
    header: 56,
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

export default constants
