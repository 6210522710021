import { TAlt } from '@containers/Actions'

export interface IActionDiscounts {
  alt: { [key in TAlt]: string }
}

const lang: { actionDiscounts: IActionDiscounts } = {
  actionDiscounts: {
    alt: {
      ACTION_75: 'Акція знижка до 75%',
      BLACK_FRIDAY_23: 'Акція знижка до 50%',
      CHRISTMAS_23: 'Акція знижка до 50%',
      JANUARY_24: 'Акція знижка до 45%',
      VALENTINES_DAY_24: 'Акція знижка до 50%',
      MARCH_24: 'Акція знижка до 44%',
      EASTER_24: 'Акція знижка до 35%',
      CHILDRENS_DAY_24: 'Акція знижка до 50%',
      OCTOBER_24: 'Акція знижка до 40%',
      BLACK_FRIDAY_24: 'Акція знижка до 40%',
      CHRISTMAS_24: 'Акція знижка до 50%',
    },
  },
}

export default lang
