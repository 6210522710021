import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { FieldValue, firestore, Timestamp } from '@services/firebase'
import { normalizeGroupFromForm } from '@pages/admin/Groups/normalizers'
import { ISchoolSearchData } from '@pages/Schools/types'
import { searchFinished } from '@features/Search/modules'
import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FINISHED,
  FETCH_GROUPS_FAILED,
  ADD_GROUP_START,
  ADD_GROUP_FINISHED,
  ADD_GROUP_FAILED,
  ADD_TO_GROUP_START,
  ADD_TO_GROUP_FINISHED,
  ADD_TO_GROUP_FAILED,
  REVOKE,
  TGroups,
  TGroupIds,
  IGroup,
  IGroupForm,
  IState,
  TError,
  IRevokeAction,
  IFetchGroupsStartAction,
  IFetchGroupsFinishedAction,
  IFetchGroupsFailedAction,
  IAddGroupStartAction,
  IAddGroupFinishedAction,
  IAddGroupFailedAction,
  IAddToGroupStartAction,
  IAddToGroupFinishedAction,
  IAddToGroupFailedAction,
  TActionHandler,
  IGroupItemForm,
  IGroupItem,
  IDeleteFromGroupStartAction,
  DELETE_FROM_GROUP_START,
  IDeleteFromGroupFinishedAction,
  DELETE_FROM_GROUP_FINISHED,
  DELETE_FROM_GROUP_FAILED,
  IDeleteFromGroupFailedAction,
} from './types'
import { normalizeGroupsFromDbToForm } from '@hoocs/useGroups/normalizers'

export const initialState: IState = {
  isReady: false,
  data: [],
  error: null,
}

// Fetch Groups

function _fetchGroupsStart(): IFetchGroupsStartAction {
  return {
    type: FETCH_GROUPS_START,
  }
}

function _fetchGroupsFinished(data: TGroups): IFetchGroupsFinishedAction {
  return {
    type: FETCH_GROUPS_FINISHED,
    payload: data,
  }
}

function _fetchGroupsFailed(error: TError): IFetchGroupsFailedAction {
  return {
    type: FETCH_GROUPS_FAILED,
    payload: error,
  }
}

export const fetchGroups =
  (
    groupIds?: TGroupIds,
    callback?: () => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchGroupsStart())
      // if (!groupIds) {
      //   dispatch(_fetchGroupsFinished([]))
      //
      //   if (callback) {
      //     callback()
      //   }
      //   return
      // }

      let groups: TGroups = []
      const groupsRef = firestore.collection('groups')

      if (groupIds && groupIds?.length > 0) {
        const promises = groupIds.map(async (groupId) => {
          const groupRef = groupsRef.doc(groupId)
          const groupSnap = await groupRef.get()
          const group = groupSnap.data() as IGroup

          return normalizeGroupsFromDbToForm(group)
        })

        groups = await Promise.all(promises)
      } else {
        const groupsSnap = await groupsRef.get()
        groupsSnap.forEach((doc) => {
          const group = doc.data() as IGroup
          // @ts-ignore
          groups.push(normalizeGroupsFromDbToForm(group))
        })
      }

      dispatch(_fetchGroupsFinished(groups))

      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch(_fetchGroupsFailed(error))
    }
  }

// Add Group

function _addGroupStart(): IAddGroupStartAction {
  return {
    type: ADD_GROUP_START,
  }
}

function _addGroupFinished(data: IGroupForm): IAddGroupFinishedAction {
  return {
    type: ADD_GROUP_FINISHED,
    payload: data,
  }
}

function _addGroupFailed(error: TError): IAddGroupFailedAction {
  return {
    type: ADD_GROUP_FAILED,
    payload: error,
  }
}

type TSearchCategories =
  | 'schools_search'
  | 'kindergartens_search'
  | 'colleges_search'
  | 'courses_search'
  | 'repetitors_search'
  | 'universities_search'
  | 'abroad_search'

type TIds = Array<string> | []
type TSearchLocations = { [key in string]: TIds }
type TSearchGroups = { [key in TSearchCategories]: TSearchLocations } | {}

const updateContent = async (
  groupId: string | null,
  { category, region, city, id }: IGroupItemForm | IGroupItem,
  isUpdate = true,
) => {
  const categoryRef = firestore
    .collection(category)
    .doc(`${region}-${city}-${id}`)

  if (isUpdate) {
    await categoryRef.set({ groupId }, { merge: true })
  }
}

const updateSearch = async (
  categorySearch: TSearchCategories,
  locationKey: string,
  ids: TIds,
  groupId: string,
  dispatch: ThunkDispatch<any, any, any>,
) => {
  const categoryRef = firestore.collection(categorySearch).doc(locationKey)
  const categorySnap = await categoryRef.get()
  const category = categorySnap.data() as ISchoolSearchData

  const newCategory = {
    ...category,
    version: category.version + 1,
    list: category.list.map((item) => {
      return {
        ...item,
        // @ts-ignore
        groupId: ids.find((id) => id === item.id)
          ? groupId
          : item.groupId || null,
      }
    }),
  }

  const key = `${category}-${locationKey}`
  dispatch(searchFinished({ [key]: newCategory.list }))

  return categoryRef.set(newCategory)
}

export const addGroup =
  (group: IGroupForm): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_addGroupStart())

      if (group.list.length) {
        const promisesContent = group.list.map((item) =>
          updateContent(group.id, item),
        )

        await Promise.all(promisesContent)

        const searchGroups = group.list.reduce(
          (acc: TSearchGroups, { category, region, city, id }) => {
            // @ts-ignore
            if (!acc[`${category}_search`]) {
              // @ts-ignore
              acc[`${category}_search`] = {}
            }

            // @ts-ignore
            if (!acc[`${category}_search`][`${region}-${city}`]) {
              // @ts-ignore
              acc[`${category}_search`][`${region}-${city}`] = []
            }

            // @ts-ignore
            if (acc[`${category}_search`][`${region}-${city}`]) {
              // @ts-ignore
              acc[`${category}_search`][`${region}-${city}`].push(id)
            } else {
              // @ts-ignore
              acc[`${category}_search`][`${region}-${city}`] = [id]
            }

            return acc
          },
          {},
        )

        const promisesSearch: Array<Promise<void>> = []
        Object.keys(searchGroups).forEach((categorySearch) => {
          // @ts-ignore
          Object.keys(searchGroups[categorySearch]).forEach((locationKey) => {
            promisesSearch.push(
              updateSearch(
                // @ts-ignore
                categorySearch,
                locationKey,
                // @ts-ignore
                searchGroups[categorySearch][locationKey],
                group.id,
                dispatch,
              ),
            )
          })
        })

        await Promise.all(promisesSearch)
      }

      const groupRef = firestore.collection('groups').doc(group.id)
      const normalizedGroup = normalizeGroupFromForm(group)
      await groupRef.set(normalizedGroup)

      dispatch(_addGroupFinished(group))
    } catch (error) {
      dispatch(_addGroupFailed(error))
    }
  }

// Add item to Group

function _addToGroupStart(): IAddToGroupStartAction {
  return {
    type: ADD_TO_GROUP_START,
  }
}

function _addToGroupFinished(data: IGroup): IAddToGroupFinishedAction {
  return {
    type: ADD_TO_GROUP_FINISHED,
    payload: data,
  }
}

function _addToGroupFailed(error: TError): IAddToGroupFailedAction {
  return {
    type: ADD_TO_GROUP_FAILED,
    payload: error,
  }
}

export const addToGroup =
  (
    groupId: string,
    groupItem: IGroupItem,
    withItemUpdate: boolean,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_addToGroupStart())
      const groupRef = firestore.collection('groups').doc(groupId)
      const groupSnap = await groupRef.get()
      const group = groupSnap.data() as IGroup

      await updateContent(groupId, groupItem, withItemUpdate)

      const newGroup = {
        ...group,
        list: [...group.list, groupItem],
      }
      await groupRef.set(newGroup)

      const ids = newGroup.list.map(({ id }) => id)

      await updateSearch(
        // @ts-ignore
        `search_${groupItem.category}`,
        // @ts-ignore
        `${groupItem.region}-${groupItem.city}`,
        ids,
        groupId,
        dispatch,
      )

      dispatch(_addToGroupFinished(newGroup))
    } catch (error) {
      dispatch(_addToGroupFailed(error))
    }
  }

// Delete from Group

function _deleteFromGroupStart(): IDeleteFromGroupStartAction {
  return {
    type: DELETE_FROM_GROUP_START,
  }
}

function _deleteFromGroupFinished(
  data: IGroup,
): IDeleteFromGroupFinishedAction {
  return {
    type: DELETE_FROM_GROUP_FINISHED,
    payload: data,
  }
}

function _deleteFromGroupFailed(error: TError): IDeleteFromGroupFailedAction {
  return {
    type: DELETE_FROM_GROUP_FAILED,
    payload: error,
  }
}

export const deleteFromGroup =
  (groupId: string, groupItem: IGroupItem): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_deleteFromGroupStart())
      const groupRef = firestore.collection('groups').doc(groupId)
      const groupSnap = await groupRef.get()
      const group = groupSnap.data() as IGroup

      await updateContent(null, groupItem)
      const { category, region, city, id } = groupItem

      const newGroup = {
        ...group,
        list: group.list.reduce((acc: Array<IGroupItem>, cur) => {
          return cur.category === category &&
            cur.region === region &&
            cur.city === city &&
            cur.id === id
            ? acc
            : [...acc, cur]
        }, []),
        updated: Timestamp.now(),
        version: FieldValue.increment(1),
      }
      await groupRef.set(newGroup)

      const ids = newGroup.list.map((item) => item.id)

      await updateSearch(
        // @ts-ignore
        `search_${groupItem.category}`,
        // @ts-ignore
        `${groupItem.region}-${groupItem.city}`,
        ids,
        groupId,
        dispatch,
      )

      dispatch(_deleteFromGroupFinished(newGroup))
    } catch (error) {
      dispatch(_deleteFromGroupFailed(error))
    }
  }

// Revoke

export function revokeGroups(): IRevokeAction {
  return {
    type: REVOKE,
  }
}

const ACTION_HANDLERS = {
  [FETCH_GROUPS_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_GROUPS_FINISHED]: (
    state: IState,
    action: IFetchGroupsFinishedAction,
  ) => {
    const data = [...state.data]
    action.payload.forEach((group) => {
      if (!data.find(({ id }) => group.id === id)) {
        data.push(group)
      }
    })

    return {
      ...state,
      isReady: true,
      data,
      error: null,
    }
  },
  [FETCH_GROUPS_FAILED]: (state: IState, action: IFetchGroupsFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [ADD_GROUP_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [ADD_GROUP_FINISHED]: (state: IState, action: IAddGroupFinishedAction) => ({
    ...state,
    isReady: true,
    data: [...state.data, action.payload],
    error: null,
  }),
  [ADD_GROUP_FAILED]: (state: IState, action: IAddGroupFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [ADD_TO_GROUP_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [ADD_TO_GROUP_FINISHED]: (
    state: IState,
    action: IAddToGroupFinishedAction,
  ) => {
    return {
      ...state,
      isReady: true,
      data: state.data.map((group) => {
        return group.id === action.payload.id ? action.payload : group
      }),
      error: null,
    }
  },
  [ADD_TO_GROUP_FAILED]: (state: IState, action: IAddToGroupFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [DELETE_FROM_GROUP_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [DELETE_FROM_GROUP_FINISHED]: (
    state: IState,
    action: IDeleteFromGroupFinishedAction,
  ) => {
    return {
      ...state,
      isReady: true,
      data: state.data.map((group) => {
        return group.id === action.payload.id ? action.payload : group
      }),
      error: null,
    }
  },
  [DELETE_FROM_GROUP_FAILED]: (
    state: IState,
    action: IDeleteFromGroupFailedAction,
  ) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [REVOKE]: initialState,
}

export default function groupsReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
