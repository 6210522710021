const lang = {
  home: {
    meta: {
      TITLE: 'Школи Києва — список, фото, умови, контакти',
      DESCRIPTION:
        'Школи Києва — інформація, спеціалізація, фото, адреса, телефон, рейтинг ЗНО, умови вступу, особливості і форма здобуття освіти',
    },
    text: {
      promo: {
        H1: 'Знайдіть свою школу',
        D1: 'Тільки достовірна і перевірена інформація',
        D2: 'Список постійно оновлюється і доповнюється',
        BUTTON_1: 'Школи Києва',
        BUTTON_2: 'Школи Київської області',
      },
      features: {
        H2: 'Переваги SchoolHub',
        ACTUAL_H3: 'Актуальність інформації',
        ACTUAL_P: 'Вся інформація перевіряєтся в ручну',
        COUNT_H3: 'Кількість інформації',
        COUNT_P: (count: number): string =>
          `До вибору доступно більше ${count} шкіл в Києві та області`,
        FAVORITE_H3: 'Обрані школи',
        FAVORITE_P:
          'Після реєстрації ви зможете скласти власний список «Обраних шкіл»',
        SEARCH_H3: 'Зручний пошук',
        SEARCH_P: 'Знаходьте навчальні заклади по параметрам, важливим для вас',
        PAYMENT_H3: 'Приватні школи',
        PAYMENT_P: 'Більше можливостей при пошуку приватніх шкіл',
        MAP_H3: 'Місцезнаходження на мапі',
        MAP_P: 'Оцініть зручність розташування начального закладу на мапі',
      },
      recommendations: {
        H2: 'Рекомендовані навчальні заклади',
      },
      actions: {
        ACTION_75: 'Акція знижка до 75%',
      },
    },
  },
}

export default lang
