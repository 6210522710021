export interface IGroups {
  NAME: string
  NAME_UA: string
  NAME_RU: string
  ITEMS: string
  actions: {
    LABEL: string
    ADD: string
    REMOVE: string
  }
  admin: {
    ID_GROUP: string
    NAME_PLACEHOLDER_UA: string
    NAME_PLACEHOLDER_RU: string
    ID: string
    ADD_ROW: string
  }
}

const lang: { groups: IGroups } = {
  groups: {
    NAME: 'Назва',
    NAME_UA: 'Назва (UA)',
    NAME_RU: 'Назва (RU)',
    ITEMS: 'Записи',
    actions: {
      LABEL: 'Дії',
      ADD: 'Додати',
      REMOVE: 'Видалити',
    },
    admin: {
      ID_GROUP: 'Id групи',
      NAME_PLACEHOLDER_UA: 'Назва групи українською',
      NAME_PLACEHOLDER_RU: 'Назва групи російською',
      ID: 'Id закладу',
      ADD_ROW: 'Добавити строку',
    },
  },
}

export default lang
