const cloudConfig = process.env?.CLOUD_RUNTIME_CONFIG

let env = process.env.NODE_ENV

if (cloudConfig) {
  env = JSON.parse(cloudConfig)?.dotenv?.node_env
}

export const IS_TEST = env === 'test'
export const IS_DEV = env === 'development'
export const IS_SNAP = global?.navigator?.userAgent === 'ReactSnap'
export const IS_PROD = env === 'production'

export const LENGTH_H1 = 50
export const LENGTH_SHORT_TITLE = 40
export const LENGTH_TITLE = 48

export const LENGTH_DESCRIPTION = 140
export const VERSION = '1.6.0'
export const COMPANY = 'SchoolHub'

export const TWITTER = '@SchoolHubComUa'

export const TINY_KEY = 'imy5u6q23tq36svk9wg9gmzqby6r9cxec8ysihw8r1i5t7v6'

export const PUBLIC_DOMAIN = 'schoolhub.com.ua'
export const PUBLIC_HOST = 'https://schoolhub.com.ua'
export const IMG_HOST = 'https://schoolhub.com.ua/img/'
export const HOST = IS_DEV ? 'https://localhost:3000' : PUBLIC_HOST

export const FIREBASE_REGION = 'europe-west3'

export const ADSENSE_ID = 'ca-pub-6794643649426513'
export const IS_ADSENSE_ENABLED = true

export const IS_ADSENSE = !IS_SNAP && ADSENSE_ID && IS_ADSENSE_ENABLED

// export const SENDGRID =
//   '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'

// todo use free version
// interface IFingerprint {
//   apiKey: string
//   region: 'eu'
// }

// export const FINGERPRINT: IFingerprint = {
//   apiKey: 'Z4RfCNdHRzcvNXRChokF',
//   region: 'eu',
// }
