// import './styles/classnameGenerator'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import stylesConstants from './styles/constants'

const rootElement = document.getElementById('root') as HTMLElement
const isHydrateDesktop = true

if (!rootElement?.hasChildNodes()) {
  console.log('render app')
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
} else if (
  !isHydrateDesktop &&
  window.innerWidth > stylesConstants.breakpoints.sm
) {
  console.log('render app web')

  rootElement.textContent = ''

  const divs = document.getElementsByClassName('MuiPopover-root')
  if (divs) {
    // eslint-disable-next-line no-plusplus
    for (let i = divs.length - 1; i >= 0; i--) {
      divs[i].remove()
    }
  }

  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
} else {
  console.log('hydrate app')

  ReactDOM.hydrateRoot(rootElement, <App />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
