import { TError as TErrorData } from '@services/handleError'
import { TCategory } from '@routers'
import { ISchoolDatabase } from '@pages/School/types'
import { TImages } from '@utils/types/image'

export type TIsReady = boolean
export type TContentData = { [id in string]: ISchoolDatabase }
export type TError = TErrorData

export interface IState {
  isReady: TIsReady
  data: TContentData
}

// Fetch content

export const FETCH_CONTENT_START = 'content/FETCH_CONTENT_START'

export interface IFetchContentStartAction {
  type: 'content/FETCH_CONTENT_START'
}

export const FETCH_CONTENT_FINISHED = 'content/FETCH_CONTENT_FINISHED'

export interface IFetchContentFinishedAction {
  type: 'content/FETCH_CONTENT_FINISHED'
  payload: TContentData
}

export const FETCH_CONTENT_FAILED = 'content/FETCH_CONTENT_FAILED'

export interface IFetchContentFailedAction {
  type: 'content/FETCH_CONTENT_FAILED'
  payload: TError
}

// Fetch images

export const FETCH_IMAGES_START = 'content/FETCH_IMAGES_START'

export interface IFetchImagesStartAction {
  type: 'content/FETCH_IMAGES_START'
}

export const FETCH_IMAGES_FINISHED = 'content/FETCH_IMAGES_FINISHED'

export interface IFetchImagesFinishedAction {
  type: 'content/FETCH_IMAGES_FINISHED'
  payload: {
    id: string
    images: TImages
  }
}

export const FETCH_IMAGES_FAILED = 'content/FETCH_IMAGES_FAILED'

export interface IFetchImagesFailedAction {
  type: 'content/FETCH_IMAGES_FAILED'
  payload: TError
}

// Fetch

export const FETCH_START = 'content/FETCH_START'

export interface IFetchStartAction {
  type: 'content/FETCH_START'
}

export const FETCH_FINISHED = 'content/FETCH_FINISHED'

export interface IFetchFinishedAction {
  type: 'content/FETCH_FINISHED'
  payload: TContentData
}

export const FETCH_FAILED = 'content/FETCH_FAILED'

export interface IFetchFailedAction {
  type: 'content/FETCH_FAILED'
  payload: TError
}

// Revoke

export const REVOKE = 'content/REVOKE'

export interface IRevokeAction {
  type: 'content/REVOKE'
}

export type TActionHandler =
  | IFetchContentStartAction
  | IFetchContentFinishedAction
  | IFetchContentFailedAction
  | IFetchImagesStartAction
  | IFetchImagesFinishedAction
  | IFetchImagesFailedAction
  | IRevokeAction

export interface IParamTypes {
  category: TCategory
  region: string
  city: string
  id: string
}

export interface IUseContent<D> {
  isReady: TIsReady
  content?: D | null
  fetchId: (id: string, callback: (data: TContentData) => void) => void
  fetchImages: (id: string, callback: (data: TImages) => void) => void
}
