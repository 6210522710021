import {
  firestore,
  IQueryDocumentSnapshot,
  ISnapshotOptions,
} from '@services/firebase'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type { TLocale } from '@i18n'
import {
  FETCH_ARTICLE_START,
  FETCH_ARTICLE_FINISHED,
  FETCH_ARTICLE_FAILED,
  FETCH_ARTICLES_START,
  FETCH_ARTICLES_FINISHED,
  FETCH_ARTICLES_FAILED,
  FETCH_ALL_ARTICLES_START,
  FETCH_ALL_ARTICLES_FINISHED,
  FETCH_ALL_ARTICLES_FAILED,
  REVOKE,
} from './types'
import type {
  IState,
  TError,
  TArticlesSearch,
  IArticle,
  IRevokeAction,
  IFetchArticleStartAction,
  IFetchArticleFinishedAction,
  IFetchArticleFailedAction,
  IFetchArticlesStartAction,
  IFetchArticlesFinishedAction,
  IFetchArticlesFailedAction,
  IFetchAllArticlesStartAction,
  IFetchAllArticlesFinishedAction,
  IFetchAllArticlesFailedAction,
  TActionHandler,
} from './types'
import { ARTICLES, ARTICLES_SEARCH } from '@config/firestore'
import { IStore } from '@store'

export const initialState: IState = {
  isReady: false,
  search: [],
  contents: {
    ua: {},
    ru: {},
  },
}

function _fetchArticleStart(payload: {
  id: string
  lang: TLocale
}): IFetchArticleStartAction {
  return {
    type: FETCH_ARTICLE_START,
    payload,
  }
}

function _fetchArticleFinished(data: IArticle): IFetchArticleFinishedAction {
  return {
    type: FETCH_ARTICLE_FINISHED,
    payload: data,
  }
}

function _fetchArticleFailed(error: TError): IFetchArticleFailedAction {
  return {
    type: FETCH_ARTICLE_FAILED,
    payload: error,
  }
}

export const fetchArticleService = async (
  id: string,
  lang: TLocale,
): Promise<IArticle> => {
  const articlesRef = await firestore
    .collection(ARTICLES)
    .where('id', '==', id)
    .where('lang', '==', lang)
    .get()

  if (articlesRef.docs.length === 1) {
    return articlesRef.docs[0].data() as IArticle
  } else if (articlesRef.empty) {
    throw new Error('Article not found')
  } else {
    throw new Error('More than 1 Article')
  }
}

export const fetchArticle =
  (
    id: string,
    lang: TLocale,
    callback?: (data: IArticle) => void,
    force = false,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => IStore) => {
    try {
      const state = getState()

      if (!state.articles.contents?.[lang]?.id || force) {
        dispatch(_fetchArticleStart({ id, lang }))

        const data = await fetchArticleService(id, lang)
        dispatch(_fetchArticleFinished(data))

        if (callback) {
          callback(data)
        }
      }
    } catch (error) {
      dispatch(_fetchArticleFailed(error))
    }
  }

// Fetch Articles
function _fetchArticlesStart(payload: TLocale): IFetchArticlesStartAction {
  return {
    type: FETCH_ARTICLES_START,
    payload,
  }
}

function _fetchArticlesFinished(data: {
  lang: TLocale
  data: TArticlesSearch
}): IFetchArticlesFinishedAction {
  return {
    type: FETCH_ARTICLES_FINISHED,
    payload: data,
  }
}

function _fetchArticlesFailed(error: TError): IFetchArticlesFailedAction {
  return {
    type: FETCH_ARTICLES_FAILED,
    payload: error,
  }
}

const converterNotAdmin = {
  toFirestore(content: any) {
    return content
  },
  fromFirestore(snapshot: IQueryDocumentSnapshot, options: ISnapshotOptions) {
    const data = snapshot.data(options) as TArticlesSearch
    return data.filter(({ visibility, deleted }) => visibility && !deleted)
  },
}

const _fetchArticles = async (
  lang: TLocale,
  isAdmin = false,
): Promise<TArticlesSearch> => {
  let articlesSearchRef = firestore
    .collection(ARTICLES_SEARCH)
    .where('lang', '==', lang)

  if (!isAdmin) {
    articlesSearchRef = articlesSearchRef.withConverter(converterNotAdmin)
  }

  const articlesSearchSnap = await articlesSearchRef.get()
  return articlesSearchSnap.docs.map((doc) => {
    return doc.data() as IArticle
  })
}

export const fetchArticles =
  (
    lang: TLocale,
    isAdmin = false,
    callback?: (articles: TArticlesSearch) => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchArticlesStart(lang))

      const data = await _fetchArticles(lang, isAdmin)

      dispatch(_fetchArticlesFinished({ lang, data }))

      if (callback) {
        callback(data)
      }
    } catch (error) {
      dispatch(_fetchArticlesFailed(error))
    }
  }

// Fetch All Articles

function _fetchAllArticlesStart(): IFetchAllArticlesStartAction {
  return { type: FETCH_ALL_ARTICLES_START }
}

function _fetchAllArticlesFinished(
  data: TArticlesSearch,
): IFetchAllArticlesFinishedAction {
  return {
    type: FETCH_ALL_ARTICLES_FINISHED,
    payload: data,
  }
}

function _fetchAllArticlesFailed(error: TError): IFetchAllArticlesFailedAction {
  return {
    type: FETCH_ALL_ARTICLES_FAILED,
    payload: error,
  }
}

export const fetchAllArticles =
  (
    callback?: (articles: TArticlesSearch) => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchAllArticlesStart())
      // @ts-ignore
      const promises = ['ua', 'ru'].map(async (lang: TLocale) => {
        const data = await _fetchArticles(lang, true)
        return data || []
      })

      const articlesArray = await Promise.all(promises)
      // @ts-ignore
      const articles = [
        ...articlesArray[0],
        ...articlesArray[1],
      ] as TArticlesSearch

      dispatch(_fetchAllArticlesFinished(articles))

      if (callback) {
        callback(articles)
      }
    } catch (error) {
      dispatch(_fetchAllArticlesFailed(error))
    }
  }

export function revokeArticle(): IRevokeAction {
  return { type: REVOKE }
}

const ACTION_HANDLERS = {
  [FETCH_ARTICLE_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_ARTICLE_FINISHED]: (
    state: IState,
    action: IFetchArticleFinishedAction,
  ) => {
    const contents = action.payload
    return {
      ...state,
      isReady: true,
      contents: {
        ...state.contents,
        [contents.lang]: {
          ...state.contents[contents.lang],
          [contents.id]: contents,
        },
      },
      error: null,
    }
  },
  [FETCH_ARTICLE_FAILED]: (
    state: IState,
    action: IFetchArticleFailedAction,
  ) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [FETCH_ARTICLES_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_ARTICLES_FINISHED]: (
    state: IState,
    action: IFetchArticlesFinishedAction,
  ) => {
    const { lang, data } = action.payload
    return {
      ...state,
      isReady: true,
      search: {
        ...state.search,
        [lang]: data,
      },
      error: null,
    }
  },
  [FETCH_ARTICLES_FAILED]: (
    state: IState,
    action: IFetchArticlesFailedAction,
  ) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [FETCH_ALL_ARTICLES_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_ALL_ARTICLES_FINISHED]: (
    state: IState,
    action: IFetchAllArticlesFinishedAction,
  ) => {
    return {
      ...state,
      isReady: true,
      search: action.payload,
      error: null,
    }
  },
  [FETCH_ALL_ARTICLES_FAILED]: (
    state: IState,
    action: IFetchAllArticlesFailedAction,
  ) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [REVOKE]: initialState,
}

export default function contentReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
