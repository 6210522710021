import { i18nService } from '@i18n'
import { ADMIN } from '@config/client'

export default {
  auth: {
    PATCH: '/auth',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    RESEND: '/auth/resend',
    PRIVACY_POLICY: '/auth/privacy-policy',
    FINISH_SIGNUP: '/auth/finish-signup',
  },
  get HOME() {
    return i18nService.language === 'ua' ? '/' : '/home'
  },
  CATALOG: '/catalog',
  SCHOOLS: '/catalog/schools',
  SITEMAP: '/sitemap',
  SERVICES: '/services',
  STATISTIC: '/statistic',
  OFFER: '/documents/offer',
  TERMS_OF_USE: '/terms-of-use',
  CONTACTS: '/contacts',
  ABOUT_US: '/about-us',
  COMMENTS: `/${ADMIN}/comments`,
  GROUPS: `/${ADMIN}/groups`,
  ADMIN_ARTICLES: `/${ADMIN}/articles`,
  NOT_FOUND: '/404',
}
