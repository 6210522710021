import { IActionDiscounts } from '@i18n/locales/ua/components/actionDiscounts'

const lang: { actionDiscounts: IActionDiscounts } = {
  actionDiscounts: {
    alt: {
      ACTION_75: 'Акция скидка до 75%',
      BLACK_FRIDAY_23: 'Акция скидка до 50%',
      CHRISTMAS_23: 'Акция скидка до 50%',
      JANUARY_24: 'Акция скидка до 45%',
      VALENTINES_DAY_24: 'Акция скидка до 50%',
      MARCH_24: 'Акция скидка до 44%',
      EASTER_24: 'Акция скидка до 35%',
      CHILDRENS_DAY_24: 'Акция скидка до 50%',
      OCTOBER_24: 'Акция скидка до 40%',
      BLACK_FRIDAY_24: 'Акция скидка до 40%',
      CHRISTMAS_24: 'Акция скидка до 50%',
    },
  },
}

export default lang
