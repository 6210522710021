import { TVersion } from '@features/Versions/types'
import { isEmpty } from '@services/common'
import {
  IGroupedSearch,
  ISearchData,
  TFilter,
  TGroupedSearchData,
  TSearchData,
} from '@features/Search/types'
import { IForm } from '@pages/Schools/types'
import { IMG_HOST } from '@config'
import { normalizeContactsList } from '@containers/Contacts/normalizers'
import { ADMIN } from '@config/client'
import { TCategory } from '@routers'
import { IContacts } from '@containers/Contacts/types'
import { TGroups } from '@hoocs/useGroups/types'
import { isVip } from '@services/vip'

export const normalizeFilterToSearch = (values: TVersion): TFilter => {
  const filters = {}
  Object.keys(values).forEach((key) => {
    // @ts-ignore
    if (typeof values[key] === 'object') {
      // @ts-ignore
      Object.keys(values[key]).forEach((name) => {
        if (
          key === 'location' &&
          name === 'district' &&
          // @ts-ignore
          values[key][name] !== ''
        ) {
          // @ts-ignore
          filters.district = values[key][name]
        } else if (
          !['collapsed', 'isReady'].includes(name) &&
          // @ts-ignore
          !['', false].includes(values[key][name]) &&
          // @ts-ignore
          !(key === 'level' && values[key][name] === 'full') &&
          !(
            key === 'location' &&
            // @ts-ignore
            ['region', 'city'].includes(name)
          )
        ) {
          // @ts-ignore
          if (!filters[key]) {
            // @ts-ignore
            filters[key] = []
          }
          // @ts-ignore
          filters[key].push(name)
        }
      })
    } else {
      // @ts-ignore
      // eslint-disable-next-line no-lonely-if
      if (!(key === 'payment' && values[key] === 'all')) {
        // @ts-ignore
        filters[key] = values[key]
      }
    }
  })

  return filters
}

export const normalizeInitialFilters = (
  initialValues: IForm,
  filters: TFilter,
): any => {
  const result: any = { ...initialValues }
  Object.keys(filters).forEach((key) => {
    // @ts-ignore
    if (typeof filters[key] === 'object') {
      // @ts-ignore
      filters[key].forEach((item) => {
        result[key][item] = true
      })
    } else {
      // @ts-ignore
      result[key] = filters[key]
    }
  })
  return result
}

interface IFilteredGroupedSearch {
  list: Array<any>
  groupIndexes: { [key in string]: number }
}

export const applyFilterToSearchData = (
  search: TSearchData,
  filters: TFilter,
  groups: TGroups,
): TGroupedSearchData => {
  if (isEmpty(filters)) {
    return search
  }

  const vipPlatinum = search.filter((item) => {
    return isVip(item, 'platinum')
  })

  const filtered = search.filter((item) => {
    if (isVip(item, 'platinum')) {
      return false
    }

    // @ts-ignore
    return Object.keys(filters).reduce((acc, cur) => {
      if (!acc) {
        return false
      }

      // @ts-ignore
      if (typeof filters[cur] === 'object' && typeof item[cur] === 'object') {
        // @ts-ignore
        return filters[cur]?.reduce((accKey, curKey) => {
          return accKey ? true : !!item[cur][curKey]
        }, false)
      }

      // @ts-ignore
      if (typeof filters[cur] === 'object') {
        // @ts-ignore
        return filters[cur].indexOf(item[cur]) !== -1
      }

      // @ts-ignore
      if (typeof item[cur] === 'object' && typeof filters[cur] === 'object') {
        // @ts-ignore
        return filters[cur]?.reduce((accKey, curKey) => {
          return accKey ? true : !!item[cur][curKey]
        }, false)
      }

      if (cur === 'quickSearch') {
        return (
          // @ts-ignore
          item.ua.search?.includes(filters[cur]) ||
          // @ts-ignore
          item.ru.search?.includes(filters[cur])
        )
      }

      // @ts-ignore
      return item[cur] === filters[cur]
    }, true)
  })

  const grouped = [...vipPlatinum, ...filtered].reduce(
    (acc: IFilteredGroupedSearch, cur) => {
      // Not a group
      if (!cur.groupId) {
        acc.list.push(cur)
        return acc

        // Group
      } else {
        const group = groups.find(({ id }) => cur.groupId === id)
        const lang = location.pathname?.includes('/ru/') ? 'ru' : 'ua'
        const groupIndex = acc.groupIndexes[cur.groupId]
        if (groupIndex) {
          acc.list[groupIndex].groupList.push(cur)
        } else {
          acc.groupIndexes[cur.groupId] = acc.list.length
          acc.list.push({
            type: 'group',
            itemNumber: 9999,
            groupId: cur.groupId,
            id: cur.id,
            name: group?.name[lang],
            groupList: [cur],
            vip: isVip(cur),
            vipLevel: cur.vipLevel,
            vipFrom: cur.vipFrom,
            vipTo: cur.vipTo,
            vipUpdated: cur.vipUpdated,
          })
        }
        return acc
      }
    },
    { list: [], groupIndexes: {} },
  )

  return grouped.list
}

interface IParams {
  category?: TCategory
  region?: string
  city?: string
}

const normalizeSearchItem = (
  // docName: string,
  searchItem: ISearchData,
  params: IParams,
  language: string,
  isAdmin: boolean,
  tContacts: IContacts,
) => {
  const {
    id,
    city,
    region,
    logo,
    contacts,
    geo,
    url,
    phone,
    distance,
    educationForm,
  } = searchItem
  const logoData = logo
    ? {
        ...logo,
        src: {
          jpeg: `${IMG_HOST}logo/schools/public/200x200/${logo.src.jpeg}`,
          webp: `${IMG_HOST}logo/schools/public/200x200/${logo.src.webp}`,
        },
      }
    : undefined

  const langData = searchItem[language] || {}

  const contactsList = normalizeContactsList(contacts, tContacts)
  const to = isAdmin
    ? `/${ADMIN}/${params?.category}/edit/${region}/${city}/${id}`
    : `/catalog/${params?.category}/${region}/${city}/${id}`

  return {
    id,
    city,
    region,
    geo,
    vip: isVip(searchItem),
    logo: logoData,
    langData,
    contactsList,
    to,
    url,
    phone,
    educationForm,
    distance: distance ? (distance / 1000).toFixed(2) : null,
  }
}

const normalizeSearchGroup = (
  searchGroup: IGroupedSearch,
  params: IParams,
  language: string,
  isAdmin: boolean,
  tContacts: IContacts,
) => {
  const {
    id: idOfGroup,
    groupId,
    name,
    groupList,
    itemNumber,
    type,
  } = searchGroup
  const normalizedGroupList = groupList.map((groupItem) => {
    const {
      id,
      city,
      region,
      logo,
      contacts,
      geo,
      url,
      phone,
      distance,
      educationForm,
    } = groupItem
    const logoData = logo
      ? {
          ...logo,
          src: {
            jpeg: `${IMG_HOST}logo/schools/public/200x200/${logo.src.jpeg}`,
            webp: `${IMG_HOST}logo/schools/public/200x200/${logo.src.webp}`,
          },
        }
      : undefined

    const langData = groupItem[language] || {}

    const contactsList = normalizeContactsList(contacts, tContacts)
    const to = isAdmin
      ? `/${ADMIN}/${params?.category}/edit/${region}/${city}/${id}`
      : `/catalog/${params?.category}/${region}/${city}/${id}`

    return {
      id,
      city,
      region,
      geo,
      vip: isVip(groupItem),
      logo: logoData,
      langData,
      contactsList,
      to,
      url,
      phone,
      educationForm,
      distance: distance ? (distance / 1000).toFixed(2) : null,
    }
  })

  return {
    id: idOfGroup,
    groupId,
    name,
    type,
    groupList: normalizedGroupList,
    itemNumber,
  }
}

export const normalizeSearchData = (
  searchData: TGroupedSearchData,
  params: IParams,
  language: string,
  isAdmin: boolean,
  tContacts: IContacts,
) => {
  return searchData.map((item) => {
    if (item.type === 'group') {
      return normalizeSearchGroup(item, params, language, isAdmin, tContacts)
    } else {
      return normalizeSearchItem(item, params, language, isAdmin, tContacts)
    }
  })
}

export const normalizeGetEmails = (normalizedSearchData: any) => {
  return normalizedSearchData.map(
    // @ts-ignore
    ({ id, langData: { shortTitle, search } }) => {
      const email = search
        .split(' ')
        .filter((text: string) => text.includes('@'))
      const title = shortTitle.replace('\\"', '')
      return `${id};${title};${email}`
    },
  )
}
