export interface IAdminArticles {
  vips: {
    LABEL: string
    VIP: string
    NOT_VIP: string
    ALL: string
  }
  languages: {
    LABEL: string
    UA: string
    RU: string
    ALL: string
  }
  articleTypes: {
    LABEL: string
    NEWS: string
    SCHOOLS: string
    OTHER: string
    ALL: string
  }
}

const lang: { adminArticles: IAdminArticles } = {
  adminArticles: {
    vips: {
      LABEL: 'VIP стаття',
      VIP: 'VIP',
      NOT_VIP: 'не VIP',
      ALL: 'Всі',
    },
    languages: {
      LABEL: 'Мова статті',
      UA: 'Українська',
      RU: 'Російська',
      ALL: 'Всі',
    },
    articleTypes: {
      LABEL: 'Тип статті',
      NEWS: 'Новини',
      SCHOOLS: 'Школи',
      OTHER: 'Інше',
      ALL: 'Всі',
    },
  },
}

export default lang
