import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { USERS_NOTIFICATIONS } from '@config/db'
import { USERS } from '@config/firestore'
import Db from '@services/Db'
import { firestore, Timestamp, IUser as IFbUser } from '@services/firebase'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { showSnackBar } from '@containers/Snackbar/modules'
import { normalizeUser } from './normalizers'
import {
  SET_CURRENT_USER,
  UPDATE_USER_STARTED,
  UPDATE_USER_FINISHED,
  UPDATE_USER_FAILED,
  REVOKE,
  CLEAR_ERROR,
  CREATE_NEW_USER_STARTED,
  CREATE_NEW_USER_FINISHED,
  CREATE_NEW_USER_FAILED,
  SET_NOTIFICATIONS,
  IUserUpdate,
  IUserUpdateDB,
  IState,
  TActionHandler,
  INotifications,
  ISetCurrentUserAction,
  TRevokeAction,
  IClearErrorAction,
  ICreateNewUserStartAction,
  ICreateNewUserFinishedAction,
  ICreateNewUserFailedAction,
  ISetNotificationsAction,
} from './types'

// ------------------------------------
// Constants
// ------------------------------------

/**
 * Initial State
 */
export const initialState: IState = {
  isReady: false,
  isReadyNewUser: true,
  error: null,
  data: {
    userId: null,
    displayName: null,
    role: 'guest',
    email: null,
    emailVerified: false,
    firstName: '',
    lastName: '',
    phoneNumber: null,
    photoURL: null,
    locale: 'ua',
    created: null,
    deleted: false,
  },
  notifications: {
    compare: 0,
    favorite: 0,
    notifications: 0,
  },
}

export function setCurrentUser(user: IUserUpdate): ISetCurrentUserAction {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  }
}

export function setNotifications(
  notifications: INotifications,
): ISetNotificationsAction {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications,
  }
}

const _updateUserStart = () => {
  return { type: UPDATE_USER_STARTED }
}

const _updateUserFinished = (data: IUserUpdateDB) => {
  return {
    type: UPDATE_USER_FINISHED,
    payload: data || {},
  }
}

const _updateUserFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: UPDATE_USER_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const updateUser =
  (
    userId: string,
    data: IUserUpdate,
    callback?: () => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_updateUserStart())

      const userRef = firestore.collection(USERS).doc(userId)
      const user = await userRef.get()

      const normalizedData: IUserUpdateDB = {
        ...data,
        updated: Timestamp.now(),
        version: (user.data()?.version || 0) + 1,
      }

      await firestore
        .collection(USERS)
        .doc(userId)
        .set(normalizedData, { merge: true })

      dispatch(_updateUserFinished({ ...normalizedData, userId }))

      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch(_updateUserFailed('user.UPDATE_USER', error))
    }
  }

export function revoke(): TRevokeAction {
  return {
    type: REVOKE,
  }
}

export function clearError(field: TError): IClearErrorAction {
  return {
    type: CLEAR_ERROR,
    payload: field,
  }
}

const _createNewUserStart = (): ICreateNewUserStartAction => {
  return { type: CREATE_NEW_USER_STARTED }
}

const _createNewUserFinished = (): ICreateNewUserFinishedAction => {
  return { type: CREATE_NEW_USER_FINISHED }
}

const _createNewUserFailed = (error: TError): ICreateNewUserFailedAction => {
  return {
    type: CREATE_NEW_USER_FAILED,
    payload: error,
  }
}

export const createNewUser =
  (user: IFbUser): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_createNewUserStart())

      const normalizedUser = normalizeUser(user)
      setCurrentUser(normalizedUser)
      await Db.update(`${USERS_NOTIFICATIONS}/${normalizedUser.userId}`, {
        favorite: 0,
        compare: 0,
        notifications: 0,
      })

      dispatch(_createNewUserFinished())
    } catch (error) {
      dispatch(_createNewUserFailed(error))
    }
  }

const ACTION_HANDLERS = {
  [SET_CURRENT_USER]: (state: IState, action: ISetCurrentUserAction) => {
    const { payload } = action
    return {
      ...state,
      isReady: true,
      data: payload,
    }
  },
  [CREATE_NEW_USER_STARTED]: (state: IState) => {
    return {
      ...state,
      isReadyNewUser: false,
    }
  },
  [CREATE_NEW_USER_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadyNewUser: true,
    }
  },
  [CREATE_NEW_USER_FAILED]: (
    state: IState,
    action: ICreateNewUserFailedAction,
  ) => {
    const error = action.payload === undefined ? null : action.payload
    return {
      ...state,
      isReadyNewUser: true,
      error,
    }
  },
  [SET_NOTIFICATIONS]: (state: IState, action: ISetNotificationsAction) => {
    return {
      ...state,
      notifications: action.payload,
    }
  },
  [CLEAR_ERROR]: (state: IState, action: IClearErrorAction) => {
    const error = action.payload === undefined ? null : action.payload
    return {
      ...state,
      error,
    }
  },
  [REVOKE]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function userReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
