import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { isEmpty } from '@services/common'
import lsConfig from '@config/localStorage'
import { TVersion } from '@features/Versions/types'
import { normalizeFilterToSearch } from './normalizers'
import {
  SEARCH_START,
  SEARCH_FINISHED,
  SEARCH_FAILED,
  FILTER_START,
  FILTER_FINISHED,
  FILTER_FINISHED_SILENCE,
  FILTER_FAILED,
  CHANGE_LIMIT,
  CHANGE_SORTING,
  SHOW_MORE_DATA,
  CHANGE_PAGE,
  SET_IS_READY_SNAP,
  MARK_SNAP,
  REVOKE,
  IState,
  TCashedSearch,
  TError,
  IRevokeAction,
  ISearchStartAction,
  ISearchFinishedAction,
  ISearchFailedAction,
  IFilterStartAction,
  IFilterFinishedAction,
  IFilterFinishedSilenceAction,
  IFilterFailedAction,
  TActionHandler,
  TFilters,
  IChangeDefaultLimitAction,
  IShowMoreDataAction,
  IChangePageAction,
  IChangeSortingAction,
  TSorting,
  ISetIsReadySnapAction,
  TIds,
  IMarkSnapByIdsAction,
  TSnapsList,
  TGroupedSearchData,
  ISearchData,
} from './types'

export const defaultPages = 20
export const defaultSorting = 'private'

const PAGE_LIMIT = +(localStorage.getItem(lsConfig.PAGE_LIMIT) || defaultPages)
const SORTING = (localStorage.getItem(lsConfig.SORTING) ||
  defaultSorting) as TSorting

export const initialState: IState = {
  isReady: false,
  isReadySnap: false,
  data: {},
  filters: {},
  pages: {
    limit: PAGE_LIMIT,
    offset: 0,
    current: 1,
  },
  sorting: SORTING,
  error: null,
}

export function searchStart(): ISearchStartAction {
  return { type: SEARCH_START }
}

export function searchFinished(data: TCashedSearch): ISearchFinishedAction {
  return {
    type: SEARCH_FINISHED,
    payload: data,
  }
}

export function searchFailed(error: TError): ISearchFailedAction {
  return {
    type: SEARCH_FAILED,
    payload: error,
  }
}

function filterStart(): IFilterStartAction {
  return { type: FILTER_START }
}

function filterFinished(filters: TFilters): IFilterFinishedAction {
  return {
    type: FILTER_FINISHED,
    payload: filters,
  }
}

function filterFinishedSilence(
  filters: TFilters,
): IFilterFinishedSilenceAction {
  return {
    type: FILTER_FINISHED_SILENCE,
    payload: filters,
  }
}

function filterFailed(error: TError): IFilterFailedAction {
  return {
    type: FILTER_FAILED,
    payload: error,
  }
}

export const setFilter =
  (
    docName: string,
    values: TVersion,
    isSilence = true,
  ): ThunkAction<any, any, any, any> =>
  (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      if (!isSilence) {
        dispatch(filterStart())
      }

      const filters = normalizeFilterToSearch(values)

      if (!isEmpty(filters)) {
        if (!isSilence) {
          setTimeout(() => {
            dispatch(filterFinished({ [docName]: filters }))
          }, 100)
        } else {
          dispatch(filterFinishedSilence({ [docName]: filters }))
        }
      }
    } catch (error) {
      dispatch(filterFailed(error))
    }
  }

export function showMoreData(): IShowMoreDataAction {
  return { type: SHOW_MORE_DATA }
}

export function changeLimitAction(limit: number): IChangeDefaultLimitAction {
  localStorage.setItem(lsConfig.PAGE_LIMIT, `${limit}`)

  return {
    type: CHANGE_LIMIT,
    payload: limit,
  }
}

export function changeSortingAction(sorting: TSorting): IChangeSortingAction {
  localStorage.setItem(lsConfig.SORTING, sorting)

  return {
    type: CHANGE_SORTING,
    payload: sorting,
  }
}

export function changePageAction(page: number): IChangePageAction {
  return {
    type: CHANGE_PAGE,
    payload: page,
  }
}

export function markSnapByIdsAction(
  docSearchName: string,
  ids: TIds,
): IMarkSnapByIdsAction {
  return {
    type: MARK_SNAP,
    payload: [{ docSearchName, ids }],
  }
}

export function markSnapAction(snapsList: TSnapsList): IMarkSnapByIdsAction {
  return {
    type: MARK_SNAP,
    payload: snapsList,
  }
}

export function markSnapSearchAction(
  docSearchName: string,
  searchData: TGroupedSearchData,
) {
  // @ts-ignore
  const ids: TIds = searchData.reduce((acc: TIds, cur: any) => {
    if (cur?.groupList) {
      cur.groupList.forEach(({ id }: ISearchData) => {
        acc.push(id)
      })
    } else {
      acc.push(cur.id)
    }
    return acc
  }, [])

  return {
    type: MARK_SNAP,
    payload: [{ docSearchName, ids }],
  }
}

export function setIsReadySnap(): ISetIsReadySnapAction {
  return { type: SET_IS_READY_SNAP }
}

export function revokeSearch(): IRevokeAction {
  return { type: REVOKE }
}

const ACTION_HANDLERS = {
  [SEARCH_START]: (state: IState) => ({
    ...state,
    isReady: false,
    error: null,
  }),
  [SEARCH_FAILED]: (state: IState, action: ISearchFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [SEARCH_FINISHED]: (state: IState, action: ISearchFinishedAction) => {
    return {
      ...state,
      isReady: true,
      data: { ...state.data, ...action.payload },
      error: null,
    }
  },
  [FILTER_START]: (state: IState) => ({
    ...state,
    isReady: false,
    filters: {},
    error: null,
  }),
  [FILTER_FAILED]: (state: IState, action: IFilterFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [FILTER_FINISHED]: (state: IState, action: IFilterFinishedAction) => {
    return {
      ...state,
      isReady: true,
      filters: action.payload,
      pages: {
        limit: state.pages.limit,
        offset: 0,
        current: 1,
      },
      error: null,
    }
  },
  [FILTER_FINISHED_SILENCE]: (
    state: IState,
    action: IFilterFinishedSilenceAction,
  ) => {
    return {
      ...state,
      filters: action.payload,
      pages: {
        limit: state.pages.limit,
        offset: 0,
        current: 1,
      },
    }
  },
  [SHOW_MORE_DATA]: (state: IState) => {
    const { pages } = state
    return {
      ...state,
      pages: {
        ...pages,
        offset: pages.offset + pages.limit,
      },
    }
  },
  [CHANGE_LIMIT]: (state: IState, action: IChangeDefaultLimitAction) => {
    return {
      ...state,
      pages: {
        ...state.pages,
        limit: action.payload,
      },
    }
  },
  [CHANGE_PAGE]: (state: IState, action: IChangePageAction) => {
    return {
      ...state,
      pages: {
        ...state.pages,
        offset: 0,
        current: action.payload,
      },
    }
  },
  [CHANGE_SORTING]: (state: IState, action: IChangeSortingAction) => {
    return {
      ...state,
      sorting: action.payload,
    }
  },
  [MARK_SNAP]: (state: IState, action: IMarkSnapByIdsAction) => {
    const newData = action.payload.reduce(
      (acc: any, { docSearchName, ids }) => {
        acc[docSearchName] = state.data[docSearchName]?.map((item) => {
          const snap = ids.find((markedId) => item.id === markedId)
            ? { isSnap: true }
            : {}

          return {
            ...item,
            ...snap,
          }
        })
        return acc
      },
      {},
    )

    return {
      ...state,
      data: {
        ...state.data,
        ...newData,
      },
      isReadySnap: true,
    }
  },
  [SET_IS_READY_SNAP]: (state: IState) => {
    return {
      ...state,
      isReadySnap: true,
    }
  },
  [REVOKE]: () => initialState,
}

export default function searchReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
