import { SHOW, HIDE, TOGGLE, REVOKE } from './types'
import type {
  IState,
  IDisplayData,
  IShowAction,
  IHideAction,
  IToggleAction,
  IRevokeAction,
  TActionHandler,
} from './types'

const defaultDisplayData: IDisplayData = {
  message: '',
  variant: 'default',
  autoHideDuration: 3000,
}

export const initialState: IState = {
  isOpen: false,
  displayData: defaultDisplayData,
}

export function showSnackBar(displayData: IDisplayData): IShowAction {
  return {
    type: SHOW,
    payload: displayData,
  }
}

export function hideSnackBar(): IHideAction {
  return {
    type: HIDE,
  }
}

export function toggleSnackBar(displayData: IDisplayData): IToggleAction {
  return {
    type: TOGGLE,
    payload: displayData,
  }
}

export function revokeSnackBar(): IRevokeAction {
  return {
    type: REVOKE,
  }
}

const ACTION_HANDLERS = {
  [SHOW]: (state: IState, action: IShowAction) => ({
    ...state,
    isOpen: true,
    displayData: {
      ...defaultDisplayData,
      ...action.payload,
    },
  }),
  [HIDE]: (state: IState) => ({
    ...state,
    isOpen: false,
  }),
  [TOGGLE]: (state: IState, action: IToggleAction) => ({
    ...state,
    isOpen: !state.isOpen,
    displayData: action.payload,
  }),
  [REVOKE]: (state: IState) => {
    const { variant } = state.displayData
    const init = { ...initialState }
    init.displayData.variant = variant

    return { ...init }
  },
}

export default function snackbarReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
