import { TError } from '@services/handleError'
import { TTimestamp } from '@services/firebase'
import { TLocale } from '@i18n'

export type TRole = 'guest' | 'user' | 'institution' | 'admin'

export interface IUser {
  userId: string | null
  displayName: string | null
  role: TRole
  email: string | null
  emailVerified: boolean
  firstName: string
  lastName: string
  phoneNumber: string | null
  photoURL: string | null
  locale: TLocale
  created: TTimestamp | null
  deleted: boolean
}

export interface IUserDB extends IUser {
  updated: TTimestamp
  version: number
}

export interface IUserProvider {
  emailVerified: boolean
  photoURL: string | null
}

export interface IUserUpdate {
  userId?: string | null
  displayName?: string | null
  role?: TRole
  email?: string | null
  emailVerified?: boolean
  firstName?: string
  lastName?: string
  phoneNumber?: string | null
  photoURL?: string | null
  locale?: TLocale
  created?: TTimestamp | null
  deleted?: boolean
  updated?: TTimestamp
  version?: number
}

export interface IUserUpdateDB extends IUserUpdate {
  updated: TTimestamp
  version: number
}

export interface IUseUser {
  isReady: boolean
  user: IUser
  userId: string | null
}

export type TNotificationsName = 'compare' | 'favorite' | 'notifications'

export interface INotifications {
  compare?: number
  favorite?: number
  notifications?: number
}

export interface IState {
  isReady: boolean
  isReadyNewUser: boolean
  error: TError
  data: IUser
  notifications: INotifications
}

export const SET_CURRENT_USER = 'user/SET_CURRENT_USER'

export interface ISetCurrentUserAction {
  type: 'user/SET_CURRENT_USER'
  payload: IUserUpdate
}

export const REVOKE = 'user/REVOKE'
export type TRevokeAction = {
  type: 'user/REVOKE'
}

export const CLEAR_ERROR = 'user/CLEAR_ERROR'

export interface IClearErrorAction {
  type: 'user/CLEAR_ERROR'
  payload: TError
}

export const UPDATE_USER_STARTED = 'user/UPDATE_USER_STARTED'

export interface IUpdateUserStartedAction {
  type: 'user/UPDATE_USER_STARTED'
}

export const UPDATE_USER_FINISHED = 'user/UPDATE_USER_FINISHED'

export interface IUpdateUserFinishedAction {
  type: 'user/UPDATE_USER_FINISHED'
  payload: IUser
}

export const UPDATE_USER_FAILED = 'user/UPDATE_USER_FAILED'

export interface IUpdateUserFailedAction {
  type: 'user/UPDATE_USER_FAILED'
  payload: TError
}

export const CREATE_NEW_USER_STARTED = 'user/CREATE_NEW_USER_STARTED'

export interface ICreateNewUserStartAction {
  type: 'user/CREATE_NEW_USER_STARTED'
}

export const CREATE_NEW_USER_FINISHED = 'user/CREATE_NEW_USER_FINISHED'

export interface ICreateNewUserFinishedAction {
  type: 'user/CREATE_NEW_USER_FINISHED'
}

export const CREATE_NEW_USER_FAILED = 'user/CREATE_NEW_USER_FAILED'

export interface ICreateNewUserFailedAction {
  type: 'user/CREATE_NEW_USER_FAILED'
  payload: TError
}

export const SET_NOTIFICATIONS = 'user/SET_NOTIFICATIONS'

export interface ISetNotificationsAction {
  type: 'user/SET_NOTIFICATIONS'
  payload: INotifications
}

export type TActionHandler =
  | ISetCurrentUserAction
  | TRevokeAction
  | IClearErrorAction
  | ICreateNewUserStartAction
  | ICreateNewUserFinishedAction
  | ICreateNewUserFailedAction
  | ISetNotificationsAction
