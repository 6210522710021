const lang = {
  appBar: {
    HOME: 'Главная страница',
    CATEGORIES: 'Категории',
    menu: {
      KINDERGARTENS: 'Детские садики',
      SCHOOLS: 'Школы',
      COLLEGES: 'Коледжи',
      COURSES: 'Курсы',
      REPETITORS: 'Репетиторы',
      UNIVERSITIES: 'ВУЗ',
      ABROAD: 'За границей',
    },
    mobileMenu: {
      ITEM: 'Мобильное меню',
      HOME: 'Главная',
      FILTER: 'Фильтры',
      CATALOG: 'Каталог',
      FAVORITE: 'Избранное',
      COMPARE: 'Сравнить',
      VIEWED: 'Просмотренные',
      REVIEWS: 'Мои отзывы',
      NOTIFICATION: 'Сообщения',
      MENU: 'Меню',
    },
    ARIA_LANGUAGE: 'Выбор языка',
    lang: {
      UA: 'Українською',
      RU: 'По русски',
    },
    langShort: {
      UA: 'UA',
      RU: 'RU',
    },
    profile: {
      LOGIN: 'Вход / Регистрация',
      LOGOUT: 'Выход',
      LOGIN_DESCRIPTION:
        'Авторизируйтесь для получения расширеных возможностей',
      SETTING: 'Настройки',
      INFORMATION: 'Личные данные',
    },
    NO_ITEMS: 'Ничего не найдено',
  },
}

export default lang
