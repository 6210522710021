const lang = {
  form: {
    SEARCH: 'Найти',
    CREATE: 'Создать',
    CLEAR: 'Очистить',
    CLEAR_TOOLTIP: 'Очистить фильтры',
    CREATE_CONTINUE: 'Создать и продолжить',
    CREATE_CLOSE: 'Создать и закрыть',
    EDIT_CONTINUE: 'Изменить и продолжить',
    EDIT_CLOSE: 'Изменить и закрыть',
    DELETE: 'Удалить',
    SHOW_MORE: 'Показать больше',
    ACTIVE_LIST: 'Активные',
    NOT_ACTIVE_LIST: 'Скрытые',
    ACTIVE: 'Активен',
    NOT_ACTIVE: 'Скрытый',
    VIP: 'VIP',
    NOT_VIP: 'Не VIP',
    BANNED: 'Забанен',
    NOT_BANNED: 'Не забанен',
    ALL: 'Все',
    EXPAND: 'Показать',
    COLLAPSE: 'Скрыть',
  },
}

export default lang
