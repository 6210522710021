import {
  IGroup,
  IGroupForm,
  IGroupsForm,
  TGroups,
} from '@hoocs/useGroups/types'

export function normalizeGroupsToForm(groups?: TGroups): IGroupsForm {
  if (!groups) {
    return { groups: [] }
  }

  return {
    groups: groups.map(({ id, name, list }, index: number) => {
      return {
        id,
        index,
        name,
        isOpen: false,
        isEdit: false,
        isNew: false,
        list: list.map((data) => ({
          ...data,
          isNew: false,
        })),
      }
    }),
  }
}

export function normalizeGroupFromForm(groupForm: IGroupForm): IGroup {
  const { index, isOpen, isEdit, isNew, list, ...restGroup } = groupForm

  return {
    ...restGroup,
    list: list.map(({ isNew: isNewItem, ...restItem }) => restItem),
  }
}
