export type TIsReady = boolean
export type ISearchData = any
export type TSearchData = Array<ISearchData> | []

export interface IGroupedSearch {
  id: string
  groupId: string
  name: string
  itemNumber: number
  type: 'group'
  groupList: TSearchData
  vip: boolean
}

export type TGroupedSearchData = Array<ISearchData | IGroupedSearch> | []
export type TSearchAll = { [key in string]: TSearchData }
export type TError = string | null | any
export type TCashedSearch = { [K: string]: TSearchData }
export type DocSearchName = string
export type TFilter = object
export type TFilters = { [K: string]: TFilter } | {}
export type TDefaultLimit = number
export type TPage = number
export type TSorting = 'private' | 'number' | 'rating' | 'new'

export type TPrevNextData = {
  prev: TSearchData
  next: TSearchData
  ids: TIds
  curIndex: number
}

export interface IPages {
  limit: number
  offset: number
  current: number // page
}

export interface IPagesForm extends IPages {
  isFull: boolean
  count: number
  countPages: number
  from: number
  to: number
}

export type TIds = Array<string>
export interface ISnapList {
  docSearchName: string
  ids: TIds
}
export type TSnapsList = Array<ISnapList>

export interface IState {
  isReady: TIsReady
  isReadySnap: TIsReady
  filters: TFilters
  sorting: TSorting
  data: TCashedSearch
  pages: IPages
  error: TError
}

// Search

export const SEARCH_START = 'search/SEARCH_START'

export interface ISearchStartAction {
  type: 'search/SEARCH_START'
}

export const SEARCH_FINISHED = 'search/SEARCH_FINISHED'

export interface ISearchFinishedAction {
  type: 'search/SEARCH_FINISHED'
  payload: TCashedSearch
}

export const SEARCH_FAILED = 'search/SEARCH_FAILED'

export interface ISearchFailedAction {
  type: 'search/SEARCH_FAILED'
  payload: TError
}

// Filter

export const FILTER_START = 'search/FILTER_START'

export interface IFilterStartAction {
  type: 'search/FILTER_START'
}

export const FILTER_FINISHED = 'search/FILTER_FINISHED'

export interface IFilterFinishedAction {
  type: 'search/FILTER_FINISHED'
  payload: TFilters
}

export const FILTER_FINISHED_SILENCE = 'search/FILTER_FINISHED_SILENCE'

export interface IFilterFinishedSilenceAction {
  type: 'search/FILTER_FINISHED_SILENCE'
  payload: TFilters
}

export const FILTER_FAILED = 'search/FILTER_FAILED'

export interface IFilterFailedAction {
  type: 'search/FILTER_FAILED'
  payload: TError
}

// Change default limit

export const CHANGE_LIMIT = 'search/CHANGE_LIMIT'

export interface IChangeDefaultLimitAction {
  type: 'search/CHANGE_LIMIT'
  payload: number
}

// Change page

export const CHANGE_PAGE = 'search/CHANGE_PAGE'

export interface IChangePageAction {
  type: 'search/CHANGE_PAGE'
  payload: number
}

// Change page

export const CHANGE_SORTING = 'search/CHANGE_SORTING'

export interface IChangeSortingAction {
  type: 'search/CHANGE_SORTING'
  payload: TSorting
}

// Show More Data

export const SHOW_MORE_DATA = 'search/SHOW_MORE_DATA'

export interface IShowMoreDataAction {
  type: 'search/SHOW_MORE_DATA'
}

// Change page

export const MARK_SNAP = 'search/MARK_SNAP'
export interface IMarkSnapByIdsAction {
  type: 'search/MARK_SNAP'
  payload: TSnapsList
}

// set isReadySnap to true

export const SET_IS_READY_SNAP = 'search/SET_IS_READY_SNAP'

export interface ISetIsReadySnapAction {
  type: 'search/SET_IS_READY_SNAP'
}

// Revoke

export const REVOKE = 'search/REVOKE'

export interface IRevokeAction {
  type: 'search/REVOKE'
}

export type TActionHandler =
  | ISearchStartAction
  | ISearchFinishedAction
  | ISearchFailedAction
  | IFilterStartAction
  | IFilterFinishedAction
  | IFilterFinishedSilenceAction
  | IFilterFailedAction
  | IChangePageAction
  | IChangeSortingAction
  | IShowMoreDataAction
  | ISetIsReadySnapAction
  | IRevokeAction
