import createStore from './createStore'
import { TCashedSearch } from '@features/Search/types'

export { Provider, useDispatch, useSelector } from 'react-redux'
export type { IStore } from './reducers'

declare global {
  interface Window {
    __INITIAL_STATE__: any
    __PRELOADED_STATE__: any
    snapSaveState: () => {}
  }
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

// Store Initialization
// ------------------------------------
const store = createStore(preloadedState || window.__INITIAL_STATE__)

// Tell react-snap how to save Redux state
window.snapSaveState = () => {
  const state = store.getState()

  // Remove unused search data
  const data = Object.keys(state.search.data).reduce(
    (acc: TCashedSearch, cur: string) => {
      acc[cur] = state.search.data[cur].filter(({ isSnap }) => isSnap)

      return acc
    },
    {},
  )

  const filteredState = window?.location?.pathname.includes('sitemap')
    ? state
    : {
        ...state,
        search: {
          ...state.search,
          data,
        },
      }

  // const isSearch =
  //   window?.location?.pathname === '/ua/catalog/schools/kyivska/kyiv' ||
  //   window?.location?.pathname === '/ru/catalog/schools/kyivska/kyiv'
  // if (!isSearch) {
  //   state.search.data = {}
  // }

  return {
    __PRELOADED_STATE__: filteredState,
  }
}

export default store
