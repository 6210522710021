export interface IPagination {
  NAV: string
  PREV: string
  NEXT: string
  PAGE: (page?: number | null) => string
  CURRENT: (page?: number | null) => string
}

const lang: { pagination: IPagination } = {
  pagination: {
    NAV: 'Навігація по сторінках',
    PREV: 'Попередня сторінка',
    NEXT: 'Наступна сторінка',
    PAGE: (page) => (page ? `Сторінка ${page}` : 'Сторінка'),
    CURRENT: (page) =>
      page ? `Поточна сторінка, сторінка ${page}` : 'Поточна сторінка',
  },
}

export default lang
