import { createTheme } from '@ui'
import constants from '@stylesConstants'
import colors from '@colors'

export {
  styled,
  useTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
export { GlobalStyles } from '@mui/material'
export type { Theme } from '@mui/material/styles'

const theme = createTheme({
  breakpoints: {
    values: constants.breakpoints,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: colors.white,
          paddingLeft: 2,
          paddingRight: 2,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: colors.violet[200],
          fontSize: 32,
          fontWeight: 300,
        },
        h2: {
          color: colors.violet[200],
          fontSize: 24,
          fontWeight: 300,
        },
        h3: {
          color: colors.violet[300],
          fontSize: 20,
          fontWeight: 400,
        },
        h4: {
          color: colors.greyList[800],
          fontSize: 18,
          fontWeight: 500,
        },
        body1: {
          fontSize: 16,
        },
        body2: {
          fontSize: 16,
          fontStyle: 'italic',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '56px !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      light: colors.violet[100],
      main: colors.violet[200],
      dark: colors.violet[300],
      contrastText: colors.white,
    },
    secondary: {
      light: colors.green[100],
      main: colors.green[200],
      dark: colors.green[300],
      contrastText: colors.white,
    },
    error: {
      light: colors.red[100],
      main: colors.red[200],
      dark: colors.red[300],
      contrastText: colors.white,
    },
    warning: {
      light: colors.orange[100],
      main: colors.orange[200],
      dark: colors.orange[300],
      contrastText: colors.blackList[900],
    },
    info: {
      light: colors.blue[100],
      main: colors.blue[200],
      dark: colors.blue[300],
      contrastText: colors.white,
    },
    success: {
      light: colors.green[100],
      main: colors.green[200],
      dark: colors.green[300],
      contrastText: colors.blackList[900],
    },
    grey: colors.greyList,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: colors.blackList[900],
      secondary: colors.blackList[600],
      disabled: colors.blackList[500],
    },
    divider: colors.blackList[300],
    background: {
      paper: colors.white,
      default: colors.greyList[50],
    },
    action: {
      active: colors.blackList[600],
      hover: colors.blackList[100],
      hoverOpacity: 0.04,
      selected: colors.blackList[200],
      selectedOpacity: 0.08,
      disabled: colors.blackList[400],
      disabledBackground: colors.blackList[300],
      disabledOpacity: 0.38,
      focus: colors.blackList[300],
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
})

export default theme
