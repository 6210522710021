const lang = {
  kyiv: 'Київ',
  kharkiv: 'Харків',
  odesa: 'Одеса',
  lviv: 'Львів',
  dnipro: 'Дніпро',
  vinnytsia: 'Вінниця',
  lutsk: 'Луцьк',
  donetsk: 'Донецьк',
  zhytomyr: 'Житомир',
  uzhhorod: 'Ужгород',
  zaporizhzhia: 'Запоріжжя',
  'ivano-frankivsk': 'Івано-Франківськ',
  kropyvnytskyi: 'Кропивницький',
  luhansk: 'Луганськ',
  mykolaiv: 'Миколаїв',
  poltava: 'Полтава',
  rivne: 'Рівне',
  sumy: 'Суми',
  ternopil: 'Тернопіль',
  kherson: 'Херсон',
  khmelnytskyi: 'Хмельницький',
  cherkasy: 'Черкаси',
  chernivtsi: 'Чернівці',
  chernihiv: 'Чернігів',
  simferopol: 'Сімферополь',
  haisyn: 'Гайсин',
  zhmerynka: 'Жмеринка',
  tulchyn: 'Тульчин',
  'mohyliv-podilskyi': 'Могилів-Подільський',
  khmilnyk: 'Хмільник',
  'volodymyr-volynskyi': 'Володимир-Волиньский',
  'kamin-kashyrskyi': 'Камінь-Каширський',
  kovel: 'Ковель',
  kamianske: `кам'янське`,
  'kryvyi-rih': 'Кривий Ріг',
  nikopol: 'Нікополь',
  novomoskovsk: 'Новомосковськ',
  pavlohrad: 'Павлоград',
  synelnykove: 'Синельникове',
  berdiansk: 'Бердянськ',
  vasylivka: 'Василівка',
  melitopol: 'Мелітополь',
  polohy: 'Пологи',
  berehove: 'Берегове',
  mukachiv: 'Мукачів',
  rakhiv: 'Рахів',
  tiachiv: 'Тячів',
  khust: 'Хуст',
  berdychiv: 'Бердичів',
  korosten: 'Коростень',
  'novohrad-volynskyi': 'Новоград-Волиньский',
  verkhovyna: 'Верховина',
  kalush: 'Калуш',
  kolomyia: 'Коломия',
  kosiv: 'Косів',
  nadvirna: 'Надвірна',
  holovanivsk: 'Голованівськ',
  novoukrainka: 'Новоукраїнка',
  oleksandriia: 'Олександрія',
  drohobych: 'Дрогобич',
  zolochiv: 'Золочів',
  sambir: 'Самбір',
  stryi: 'Стрий',
  chervonohrad: 'Червоноград',
  yavoriv: 'Яворів',
  bashtanka: 'Баштанка',
  voznesensk: 'Вознесенськ',
  pervomaisk: 'Первомайськ',
  izmail: 'Ізмаїл',
  berezivka: 'Березівка',
  'bilhorod-dnistrovskyi': 'Білгород-Дністровський',
  bolhrad: 'Болград',
  podilsk: 'Подільск',
  rozdilna: 'Роздільна',
  kremenchuh: 'Кременчуг',
  lubny: 'Лубни',
  myrhorod: 'Миргород',
  varash: 'Вараш',
  dubno: 'Дубно',
  sarny: 'Сарни',
  konotop: 'Конотоп',
  okhtyrka: 'Охтирка',
  romny: 'Ромни',
  shostka: 'Шостка',
  kremenets: 'Кременець',
  chortkiv: 'Чортків',
  bohodukhiv: 'Богодухів',
  izium: 'Ізюм',
  krasnohrad: 'Красноград',
  kupiansk: `Куп'янськ`,
  lozova: 'Лозова',
  chuhuiv: 'Чугуїв',
  beryslav: 'Берислав',
  henichesk: 'Генічеськ',
  'nova-kakhovka': 'Нова Каховка',
  skadovsk: 'Скадовськ',
  'kamianets-podilskyi': `Кам'янець-Подільский`,
  shepetivka: 'Шепетівка',
  zvenyhorodka: 'Звенигородка',
  zolotonosha: 'Золотоноша',
  uman: 'Умань',
  vyzhnytsia: 'Вижниця',
  kelmentsi: 'Кельменці',
  koriukivka: 'Корюківка',
  nizhyn: 'Ніжин',
  'novhorod-siverskyi': 'Новгород-Сіверський',
  pryluky: 'Прилуки',
  region: 'Область',
}

export default lang
