import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './locales/ru'
import ua from './locales/ua'

export type { ReactI18NextChild } from 'react-i18next'

export type TLocale = 'ua' | 'ru'
export type THrefLocale = '/ua' | '/ru'

export { default as i18nService } from './services'
export { default as useTranslation } from './useI18n'
export { default as I18nComponent } from './I18nComponent'

export const resources = {
  ru,
  ua,
} as const

export const ro = { returnObjects: true }
export const fallbackLng = 'ua'

const urlLng = window.location.pathname.substr(1, 2)

// @ts-ignore
const lng = resources[urlLng] ? urlLng : fallbackLng

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng,
    fallbackLng,
    resources,
    ns: ['components', 'pages'],
    nsSeparator: ':',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {})
