const lang = {
  TITLE: 'Войти',
  SUBHEADER: 'Войти как существующий пользователь',
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  REMEMBER_ME: 'Запомнить?',
  REGISTER: 'Зарегистрироваться',
  RESEND: 'Забыли пароль?',
  SUBMIT: 'Войти',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  FAVORITE: 'Войдите для добавление в избранное',
}

export default lang
