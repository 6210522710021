import { ReactElement } from 'react'

export type TVariant =
  | 'default'
  | 'disabled'
  | 'error'
  | 'info'
  | 'infoLight'
  | 'success'
  | 'successInfo'
  | 'warning'
export type TMessage = ReactElement | string
export type TAutoHideDuration = number

export interface IDisplayData {
  message: TMessage
  variant: TVariant
  autoHideDuration?: TAutoHideDuration
}

export type TIsOpen = boolean

export interface IUseSnackBar {
  isOpen: boolean
  snackbar: IDisplayData
  show: (displayData: IDisplayData) => void
  toggle: (displayData: IDisplayData) => void
  hide: () => void
  revoke: () => void
}

export interface IState {
  isOpen: TIsOpen
  displayData: IDisplayData
}

export const SHOW = 'snackBar/SHOW'

export interface IShowAction {
  type: 'snackBar/SHOW'
  payload: IDisplayData
}

export const HIDE = 'snackBar/HIDE'

export interface IHideAction {
  type: 'snackBar/HIDE'
}

export const TOGGLE = 'snackBar/TOGGLE'

export interface IToggleAction {
  type: 'snackBar/TOGGLE'
  payload: IDisplayData
}

export const REVOKE = 'snackBar/REVOKE'

export interface IRevokeAction {
  type: 'snackBar/REVOKE'
}

export type TActionHandler =
  | IShowAction
  | IHideAction
  | IToggleAction
  | IRevokeAction
