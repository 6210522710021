const lang = {
  company: {
    ADDRESS: 'Unit City, ул. Семьи Хохловых 8',
    FULL_ADDRESS: 'Unit City, ул. Семьи Хохловых 8, г. Киев, Украина, 04119',
    COUNTRY: 'Украина',
    CITY: 'Киев',
    LEGAL: 'ФЛП Лазарев А.В.',
  },
}

export default lang
