import { IActions } from '@i18n/locales/ua/components/actions'

const lang: { actions: IActions } = {
  actions: {
    map: {
      SHOW_ALL: 'Посмотреть все на карте',
      SHOW: 'Показать карту',
      HIDE: 'Скрыть карту',
      SMALL_SHOW: 'Карта',
      SMALL_HIDE: 'Карта',
    },
    search: {
      LABEL: 'Быстрый поиск',
      PLACEHOLDER: 'Название, номер, адрес, контакты',
    },
    sorting: {
      LABEL: 'Сортировка',
      PRIVATE: 'Сначала частные',
      NUMBER: 'По номером',
      NEW: 'Сначала новые',
      RATING: 'По рейтингу ВНО',
      SMALL_PRIVATE: 'От частных',
      SMALL_NUMBER: 'По номеру',
      SMALL_NEW: 'Новинки',
      SMALL_RATING: 'По рейтингу',
    },
  },
}

export default lang
