import type { TBreakpoint } from '@stylesConstants'

interface IProps {
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  fullScreen?: boolean
  fullWidth?: boolean
  maxWidth?: TBreakpoint | false
  scroll?: 'body' | 'paper'
  PaperProps?: object | {}
}

export type TCurrentType = 'login' | 'register' | 'resend' | 'finish' | null
export type TData = object | {}
export type TProps = IProps | {}
export type TStyles = object | {}

export interface IState {
  currentType: TCurrentType
  data?: TData
  props?: TProps
  styles?: TStyles
}

export interface IShowProps {
  data?: TData
  props?: TProps
  styles?: TStyles
}

export interface IUseDialog {
  isOpen: boolean
  dialog: IState
  show: (currentType: TCurrentType, data?: TData, props?: TProps) => void
  push: (currentType: TCurrentType, data?: TData, props?: TProps) => void
  hide: () => void
  revoke: () => void
}

export const SHOW = 'dialogs/SHOW'

export interface IShowAction {
  type: 'dialogs/SHOW'
  payload: IState
}

export const PUSH = 'dialogs/PUSH'

export interface IPushAction {
  type: 'dialogs/PUSH'
  payload: IState
}

export const HIDE = 'dialogs/HIDE'

export interface IHideAction {
  type: 'dialogs/HIDE'
}

export const REVOKE = 'dialogs/REVOKE'

export interface IRevokeAction {
  type: 'dialogs/REVOKE'
}

export type TActionHandler =
  | IShowAction
  | IPushAction
  | IHideAction
  | IRevokeAction
