import { showSnackBar } from '@containers/Snackbar/modules'
import {
  auth,
  firestore,
  Timestamp,
  IUser as IFbUser,
} from '@services/firebase'
import { isEmpty } from '@services/common'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IUserProvider, IUserUpdate } from '@features/User'
import {
  updateUser,
  setCurrentUser,
  revoke as revokeUser,
} from '@features/User/modules'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { i18nService, TLocale } from '@i18n'
import { routes, TPush, TReplace } from '@routers'
import { IStore } from '@store'
import lsConst from '@config/localStorage'
import { localStorage } from '@services/localStorage'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import {
//   USERS_FINGERPRINTS,
//   USERS_NOTIFICATIONS,
//   USERS_SESSIONS,
// } from '@config/db'

import {
  IState,
  ILoginEmail,
  IResetEmail,
  IRegisterForm,
  // TFingerprint,
  ISetNotSignedInAction,
  ILoginGoogleFailedAction,
  ILoginMicrosoftFailedAction,
  ILoginFacebookFailedAction,
  ISendResetMailFailedAction,
  ISendEmailVerificationFailedAction,
  ISetLoginFirebaseFinishedAction,
  ISetLoginFirebaseFailedAction,
  ISetFingerprintFinishedAction,
  ISetFingerprintFailedAction,
  ILoginFailedAction,
  ILoginLinkFailedAction,
  IRegisterFailedAction,
  ISignoutFailedAction,
  IRevokeAction,
  TActionHandler,
  SET_FINGERPRINT_FINISHED,
  SET_FINGERPRINT_FAILED,
  SET_NOT_SIGNED_IN,
  SEND_EMAIL_VERIFICATION_STARTED,
  SEND_EMAIL_VERIFICATION_FINISHED,
  SEND_EMAIL_VERIFICATION_FAILED,
  SET_LOGIN_FIREBASE_STARTED,
  SET_LOGIN_FIREBASE_FINISHED,
  SET_LOGIN_FIREBASE_FAILED,
  LOGIN_STARTED,
  LOGIN_FAILED,
  LOGIN_FINISHED,
  LOGIN_LINK_STARTED,
  LOGIN_LINK_FAILED,
  LOGIN_LINK_FINISHED,
  REGISTER_STARTED,
  REGISTER_FINISHED,
  REGISTER_FAILED,
  SEND_RESET_MAIL_STARTED,
  SEND_RESET_MAIL_FINISHED,
  SEND_RESET_MAIL_FAILED,
  LOGOUT,
  REVOKE,
  LOGOUT_FAILED,
  UPDATE_AUTH_USER_STARTED,
  UPDATE_AUTH_USER_FINISHED,
  UPDATE_AUTH_USER_FAILED,
  LOGIN_GOOGLE_STARTED,
  LOGIN_GOOGLE_FINISHED,
  LOGIN_GOOGLE_FAILED,
  LOGIN_FACEBOOK_STARTED,
  LOGIN_FACEBOOK_FINISHED,
  LOGIN_FACEBOOK_FAILED,
  LOGIN_MICROSOFT_STARTED,
  LOGIN_MICROSOFT_FINISHED,
  LOGIN_MICROSOFT_FAILED,
  ILoginLink,
  IProfileForm,
} from './types'
import { USERS } from '@config/firestore'

// ------------------------------------
// Constants
// ------------------------------------

export { default as loginGoogle } from './providers/authGoogle'
export { default as loginFacebook } from './providers/authFacebook'
export { default as loginMicrosoft } from './providers/authMicrosoft'

/**
 * Initial State
 */
export const initialState: IState = {
  fingerprint: null,
  isReadyLogin: false,
  isReadySendEmailVerified: false,
  isLoggedIn: false,
  error: null,
  isReadyRegister: true,
  isReadyGoogle: true,
  isReadyFacebook: true,
  isReadyMicrosoft: true,
  isEmailVerified: true,
  isReadySendResetMail: true,
  isReadyUpdateAuthUser: true,
}

// const _setFingerprintFinished = (fingerprint: TFingerprint) => {
//   return {
//     type: SET_FINGERPRINT_FINISHED,
//     payload: fingerprint,
//   }
// }
//
// const _setFingerprintFailed = (error: TError) => {
//   return {
//     type: SET_FINGERPRINT_FAILED,
//     payload: error,
//   }
// }
//
// export const setFingerprint =
//   (userId: string): ThunkAction<any, any, any, any> =>
//   async (dispatch: ThunkDispatch<any, any, any>) => {
//     try {
//       const fp = await FingerprintJS.load()
//       const { visitorId } = await fp.get()
//
//       dispatch(_setFingerprintFinished(visitorId))
//
//       const fingerprintsCount = await Db.get(
//         `${USERS_FINGERPRINTS}/${visitorId}/${userId}`,
//       )
//       await Db.update(`${USERS_FINGERPRINTS}/${visitorId}`, {
//         [userId]: fingerprintsCount.val() + 1,
//       })
//
//       const sessionsCount = await Db.get(
//         `${USERS_SESSIONS}/${userId}/${visitorId}`,
//       )
//       await Db.update(`${USERS_SESSIONS}/${userId}`, {
//         [visitorId]: sessionsCount.val() + 1,
//       })
//     } catch (error) {
//       dispatch(_setFingerprintFailed(error))
//     }
//   }

const _logoutFailed = (code: TErrorCode, error: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGOUT_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

const _logoutFinished = () => {
  return [revokeUser(), { type: LOGOUT }]
}

export const logout =
  (push?: TPush): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      await auth.signOut()
      dispatch(_logoutFinished())
      if (push) {
        push(routes.HOME)
      }
    } catch (error) {
      dispatch(_logoutFailed('auth.LOG_OUT', error))
    }
  }

// Login

const _loginEmailPasswordStart = () => {
  return { type: LOGIN_STARTED }
}

const _loginEmailPasswordFinished = () => {
  return { type: LOGIN_FINISHED }
}

const _loginEmailPasswordFailed = (code: TErrorCode, error: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGIN_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const loginEmailPassword =
  ({ email, password, rememberMe }: ILoginEmail, callback?: () => void) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_loginEmailPasswordStart())

      await auth.signInWithEmailAndPassword(email, password)

      if (rememberMe) {
        await localStorage.setItem(lsConst.EMAIL, email)
      } else {
        await localStorage.removeItem(lsConst.EMAIL)
      }

      dispatch(_loginEmailPasswordFinished())

      if (callback) {
        callback()
        // push(routes.DASHBOARD)
      }
    } catch (error) {
      dispatch(_loginEmailPasswordFailed('auth.signIn', error))
    }
  }

// Login Link

const _loginEmailLinkStart = () => {
  return { type: LOGIN_LINK_STARTED }
}

const _loginEmailLinkFinished = () => {
  return { type: LOGIN_LINK_FINISHED }
}

const _loginEmailLinkFailed = (code: TErrorCode, error: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGIN_LINK_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const loginEmailLink =
  ({ email, url }: ILoginLink, replace: TReplace) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_loginEmailLinkStart())

      await auth.signInWithEmailLink(email, url)

      dispatch(_loginEmailLinkFinished())
    } catch (error) {
      dispatch(_loginEmailLinkFailed('auth.signInLink', error))

      replace(routes.HOME)
    }
  }

// sendEmailVerification

const _sendEmailVerificationStarted = () => {
  return { type: SEND_EMAIL_VERIFICATION_STARTED }
}

const _sendEmailVerificationFinished = () => {
  return [
    { type: SEND_EMAIL_VERIFICATION_FINISHED },
    showSnackBar({
      message: i18nService.t('snackbars.auth.MAIL_VERIFICATION_SEND'),
      variant: 'success',
    }),
  ]
}

const _sendEmailVerificationFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: SEND_EMAIL_VERIFICATION_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const sendEmailVerification =
  (user: IFbUser | null) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_sendEmailVerificationStarted)
      if (user !== null) {
        await user.sendEmailVerification()
        dispatch(_sendEmailVerificationFinished())
      } else {
        dispatch(_sendEmailVerificationFailed('auth.NO_USER'))
      }
    } catch (error) {
      dispatch(_sendEmailVerificationFailed('auth.EMAIL_VERIFICATION', error))
    }
  }

// Register

const _userSavedFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

const _registerStart = () => {
  return { type: REGISTER_STARTED }
}

const _registerFinished = () => {
  return { type: REGISTER_FINISHED }
}

const _registerFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: REGISTER_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const register =
  (
    locale: TLocale,
    { firstName, lastName, email, password, role }: IRegisterForm,
    callback: () => void,
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    const onError = (code: TErrorCode, error?: TError) => {
      dispatch(_registerFailed(code, error))
      dispatch(logout())
    }

    try {
      dispatch(_registerStart())

      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password,
      )

      if (user === null) {
        onError('auth.NO_USER')
        return
      }

      const displayName = `${firstName} ${lastName}`

      try {
        await user.updateProfile({ displayName })
      } catch (error) {
        dispatch(_userSavedFailed('user.USER_NOT_SAVED'))
      }

      await dispatch(sendEmailVerification(user))

      dispatch(
        updateUser(
          user.uid,
          {
            created: Timestamp.now(),
            deleted: false,
            displayName,
            email,
            emailVerified: false,
            firstName,
            lastName,
            role,
            locale,
          },
          () => {
            dispatch(_registerFinished())
            if (callback) {
              callback()
            }
          },
        ),
      )
    } catch (error) {
      onError('auth.signUp', error)
    }
  }

// Update AuthUser

const _updateAuthUserStarted = () => {
  return { type: UPDATE_AUTH_USER_STARTED }
}

const _updateAuthUserFinished = (user: IUserUpdate) => {
  return [setCurrentUser(user), { type: UPDATE_AUTH_USER_FINISHED }]
}

const _updateAuthUserFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: UPDATE_AUTH_USER_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const updateAuthUser =
  (userId: string, profile: IProfileForm) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    const onError = (code: TErrorCode, error?: TError) => {
      dispatch(_updateAuthUserFailed(code, error))
    }

    try {
      dispatch(_updateAuthUserStarted())

      dispatch(updateUser(userId, profile))

      dispatch(_updateAuthUserFinished({ userId, ...profile }))
    } catch (error) {
      onError('error.UPDATE_AUTH_USER', error)
    }
  }

// Login Firebase

export const setNotLoggedIn = (): ISetNotSignedInAction => {
  return { type: SET_NOT_SIGNED_IN }
}

const _setLoginFirebaseStarted = () => {
  return { type: SET_LOGIN_FIREBASE_STARTED }
}

const _setLoginFirebaseFinished = (user: IUserUpdate) => {
  return [
    setCurrentUser(user),
    {
      type: SET_LOGIN_FIREBASE_FINISHED,
      payload: user.emailVerified,
    },
  ]
}

const _setLoginFirebaseFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: SET_LOGIN_FIREBASE_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const setLoginFirebase =
  (push: TPush, { uid, emailVerified, photoURL }: IFbUser) =>
  async (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => IStore,
  ): Promise<any> => {
    const onError = (code: TErrorCode, error?: TError) => {
      dispatch(_setLoginFirebaseFailed(code, error))
      dispatch(logout(push))
    }

    const state = getState()
    if (
      !state.auth.isReadyRegister ||
      !state.auth.isReadyGoogle ||
      !state.auth.isReadyFacebook ||
      !state.auth.isReadyMicrosoft
    ) {
      return
    }

    try {
      dispatch(_setLoginFirebaseStarted())

      if (uid === process.env.REACT_APP_ADMIN_UID) {
        // @ts-ignore
        localStorage.setItem('Y7PhC2fg', process.env.REACT_APP_Y7PhC2fg)
      }

      const userRef = firestore.collection(USERS).doc(uid)
      const userPromise = await userRef.get()
      const userData = userPromise.data()

      if (userData) {
        await localStorage.setItem(lsConst.CURRENT_USER, uid)
        const updated: IUserProvider = {
          emailVerified: userData.emailVerified || false,
          photoURL: userData.photoURL || null,
        }
        if (!userData.emailVerified && emailVerified) {
          updated.emailVerified = true
        }
        if (!userData.photoURL && photoURL) {
          updated.photoURL = photoURL
        }

        if (!isEmpty(updated)) {
          dispatch(updateUser(uid, { ...updated }))
          dispatch(
            _setLoginFirebaseFinished({ userId: uid, ...userData, ...updated }),
          )
        } else {
          dispatch(_setLoginFirebaseFinished({ userId: uid, ...userData }))
        }
      } else {
        onError('auth.NO_USER')
      }
    } catch (error) {
      onError('error.SIGN_IN_FIREBASE', error)
    }
  }

export function revoke(): IRevokeAction {
  return { type: REVOKE }
}

// Send Reset Mail

const _sendResetMailStart = () => {
  return { type: SEND_RESET_MAIL_STARTED }
}

const _sendResetMailFinished = () => {
  return [
    { type: SEND_RESET_MAIL_FINISHED },
    showSnackBar({
      message: i18nService.t('snackbars.auth.EMAIL_RESET'),
      variant: 'success',
    }),
  ]
}

const _sendResetMailFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: SEND_RESET_MAIL_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const sendResetMail =
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { email }: IResetEmail,
    callback: () => void,
  ) =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_sendResetMailStart())

      // await auth.sendPasswordResetEmail(email)

      dispatch(_sendResetMailFinished())

      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch(_sendResetMailFailed('auth.resetEmail', error))
    }
  }

// Register Facebook

// const _registerFacebookStart = () => {
//   return { type: REGISTER_FACEBOOK_STARTED }
// }
//
// const _registerFacebookFinished = (expirationDate: TExpirationDate) => {
//   return {
//     type: REGISTER_FACEBOOK_FINISHED,
//     payload: expirationDate,
//   }
// }
//
// const _registerFacebookFailed = (error: TError) => {
//   return {
//     type: REGISTER_FACEBOOK_FAILED,
//     payload: error,
//   }
// }
//
// export const registerFacebook = (action) => async (dispatch) => {
//   const onError = (error: TError) => {
//     dispatch(_registerFacebookFailed(error))
//     dispatch(logout())
//   }
//
//   try {
//     dispatch(_registerFacebookStart())
//
//     const provider = new firebase.auth.FacebookAuthProvider()
//     provider.addScope('user_friends')
//     await firebase.auth().signInWithPopup(provider)
//
//     const idToken: TToken = await firebase.auth().currentUser.getIdToken(true)
//     const expirationDate: TExpirationDate = _getExpirationDate(idToken)
//     dispatch(_registerFacebookFinished(expirationDate))
//
//   } catch (error) {
//     dispatch(onError(error))
//   }
// }

// updatePhotoURL

// const _updatePhotoStart = () => {
//   return { type: UPDATE_PHOTO_STARTED }
// }
//
// const _updatePhotoFinished = (data) => {
//   return [
//     {
//       type: UPDATE_PHOTO_FINISHED,
//       payload: data,
//     },
//     showSnackBar({
//       message: getPages('quiz.AVATAR_UPDATED'),
//       variant: 'success',
//     }),
//   ]
// }
//
// const _updatePhotoFailed = (error) => {
//   return [
//     {
//       type: UPDATE_PHOTO_FAILED,
//       payload: error,
//     },
//     showSnackBar({
//       message: getPages('quiz.AVATAR_ERROR'),
//       variant: 'error',
//     }),
//   ]
// }
//
// export const updatePhoto = (photoURL) => async (dispatch) => {
//   try {
//     dispatch(_updatePhotoStart())
//
//     const user = await auth.currentUser
//
//     try {
//       await user.updateProfile({ photoURL })
//       dispatch(_updatePhotoFinished())
//     } catch (error) {
//       dispatch(_updatePhotoFailed(error))
//     }
//
//   } catch (error) {
//     dispatch(_updatePhotoFailed(error))
//   }
// }

// ------------------------------------
// Action Handlers
// ------------------------------------
// Also all handlers from providers are located here
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_NOT_SIGNED_IN]: (state: IState) => {
    return {
      ...state,
      isReadyLogin: true,
      isLoggedIn: false,
    }
  },
  [SET_LOGIN_FIREBASE_STARTED]: (state: IState) => {
    return state
  },
  [SET_LOGIN_FIREBASE_FINISHED]: (
    state: IState,
    action: ISetLoginFirebaseFinishedAction,
  ) => {
    return !state.error
      ? {
          ...state,
          isEmailVerified: action.payload,
          isReady: true,
          isLoggedIn: true,
          error: null,
        }
      : { ...state }
  },
  [SET_LOGIN_FIREBASE_FAILED]: (
    state: IState,
    action: ISetLoginFirebaseFailedAction,
  ) => {
    return {
      ...state,
      isReady: true,
      isLoggedIn: false,
      error: action.payload,
    }
  },
  [SET_FINGERPRINT_FINISHED]: (
    state: IState,
    action: ISetFingerprintFinishedAction,
  ) => {
    return {
      ...state,
      fingerprint: action.payload,
    }
  },
  [SET_FINGERPRINT_FAILED]: (
    state: IState,
    action: ISetFingerprintFailedAction,
  ) => {
    return !state.error
      ? {
          ...state,
          fingerprint: null,
          isError: true,
          error: action.payload,
        }
      : { state }
  },
  [UPDATE_AUTH_USER_STARTED]: (state: IState) => {
    return {
      ...state,
      isReadyUpdateAuthUser: false,
    }
  },
  [UPDATE_AUTH_USER_FINISHED]: (state: IState) => {
    return !state.error
      ? {
          ...state,
          isReadyUpdateAuthUser: true,
          error: null,
        }
      : { ...state }
  },
  [UPDATE_AUTH_USER_FAILED]: (
    state: IState,
    action: ISetLoginFirebaseFailedAction,
  ) => {
    return {
      ...state,
      isReadyUpdateAuthUser: true,
      error: action.payload,
    }
  },
  [LOGIN_STARTED]: (state: IState) => {
    return {
      ...state,
      isReadyLogin: false,
      isLoggedIn: false,
      error: null,
    }
  },
  [LOGIN_FINISHED]: (state: IState) => {
    return !state.error
      ? {
          ...state,
          isReadyLogin: true,
          isLoggedIn: true,
          error: null,
        }
      : { ...state }
  },
  [LOGIN_FAILED]: (state: IState, action: ILoginFailedAction) => {
    return {
      ...state,
      isReadyLogin: true,
      isLoggedIn: false,
      error: action.payload,
    }
  },
  [LOGIN_LINK_STARTED]: (state: IState) => {
    return {
      ...state,
      // isReadyLogin: false,
      isLoggedIn: false,
      error: null,
    }
  },
  [LOGIN_LINK_FINISHED]: (state: IState) => {
    return !state.error
      ? {
          ...state,
          isReadyLogin: true,
          isLoggedIn: true,
          error: null,
        }
      : { ...state }
  },
  [LOGIN_LINK_FAILED]: (state: IState, action: ILoginLinkFailedAction) => {
    return {
      ...state,
      isReadyLogin: true,
      isLoggedIn: false,
      error: action.payload,
    }
  },
  [SEND_EMAIL_VERIFICATION_STARTED]: (state: IState) => ({
    ...state,
    isReadySendEmailVerified: false,
  }),
  [SEND_EMAIL_VERIFICATION_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadySendEmailVerified: true,
    }
  },
  [SEND_EMAIL_VERIFICATION_FAILED]: (
    state: IState,
    action: ISendEmailVerificationFailedAction,
  ) => ({
    ...state,
    error: action.payload,
    isReadySendEmailVerified: true,
  }),
  [REGISTER_STARTED]: (state: IState) => ({
    ...state,
    isReadyRegister: false,
  }),
  [REGISTER_FINISHED]: (state: IState) => {
    return !state.error
      ? {
          ...state,
          isEmailVerified: false,
          isReadyRegister: true,
          isLoggedIn: true,
          error: null,
        }
      : { ...state }
  },
  [REGISTER_FAILED]: (state: IState, action: IRegisterFailedAction) => ({
    ...state,
    error: action.payload,
    isReadyRegister: true,
  }),
  [SEND_RESET_MAIL_STARTED]: (state: IState) => ({
    ...state,
    isReadySendResetMail: false,
  }),
  [SEND_RESET_MAIL_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadySendResetMail: true,
    }
  },
  [SEND_RESET_MAIL_FAILED]: (
    state: IState,
    action: ISendResetMailFailedAction,
  ) => ({
    ...state,
    error: action.payload,
    isReadySendResetMail: true,
  }),
  [LOGIN_GOOGLE_STARTED]: (state: IState) => ({
    ...state,
    isReadyGoogle: false,
  }),
  [LOGIN_GOOGLE_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadyGoogle: true,
      isLoggedIn: true,
    }
  },
  [LOGIN_GOOGLE_FAILED]: (state: IState, action: ILoginGoogleFailedAction) => ({
    ...state,
    isReadyGoogle: true,
    error: action.payload,
  }),
  [LOGIN_FACEBOOK_STARTED]: (state: IState) => ({
    ...state,
    isReadyFacebook: false,
  }),
  [LOGIN_FACEBOOK_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadyFacebook: true,
      isLoggedIn: true,
    }
  },
  [LOGIN_FACEBOOK_FAILED]: (
    state: IState,
    action: ILoginFacebookFailedAction,
  ) => ({
    ...state,
    isReadyFacebook: true,
    error: action.payload,
  }),
  [LOGIN_MICROSOFT_STARTED]: (state: IState) => ({
    ...state,
    isReadyMicrosoft: false,
  }),
  [LOGIN_MICROSOFT_FINISHED]: (state: IState) => {
    return {
      ...state,
      isReadyMicrosoft: true,
      isLoggedIn: true,
    }
  },
  [LOGIN_MICROSOFT_FAILED]: (
    state: IState,
    action: ILoginMicrosoftFailedAction,
  ) => ({
    ...state,
    isReadyMicrosoft: true,
    error: action.payload,
  }),
  [LOGOUT]: (state: IState) => {
    return {
      ...initialState,
      isReadyLogin: state.isReadyLogin,
    }
  },
  [LOGOUT_FAILED]: (state: IState, action: ISignoutFailedAction) => {
    return {
      ...state,
      error: action.payload,
    }
  },
  [REVOKE]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function authReducer(
  state: IState = initialState,
  action: TActionHandler | never,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
