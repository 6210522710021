import {
  useLocation as useLocationDom,
  useHistory as useHistoryDom,
  useParams as useParamsDom,
  Route as RouteDom,
  BrowserRouter as BrowserRouterDom,
  Redirect as RedirectDom,
  Switch as SwitchDom,
  NavLink as NavLinkDom,
} from 'react-router-dom'
import LinkDom from '@containers/Link'
import type { Path, History, LocationState } from 'history'
import routesList from './routes'

export type { TCategory } from './types'

export const routes = routesList
export const useLocation = useLocationDom
export const useHistory = useHistoryDom
export const useParams = useParamsDom
export const Route = RouteDom
export const BrowserRouter = BrowserRouterDom
export const Redirect = RedirectDom
export const Switch = SwitchDom
export const Link = LinkDom
export const NavLink = NavLinkDom

const getLanguage = () => {
  return location.pathname?.includes('/ru/') ? '/ru' : '/ua'
}

export const useIsOnRoute = (): ((path: Path, exact?: boolean) => boolean) => {
  const { pathname } = useLocation()

  return (path: Path, exact = false): boolean => {
    const lng = getLanguage()
    return (
      pathname === path ||
      (exact
        ? pathname === `${lng}${path}`
        : pathname.indexOf(`${lng}${path}`) !== -1)
    )
  }
}

export type TPush = (path: Path, state?: LocationState) => void
export const usePush = (): TPush => {
  const history: History = useHistory()

  return (path: Path, state?: LocationState): void => {
    const lng = getLanguage()
    const url = path === '/' ? '/' : `${lng}${path}`
    history.push(url, state)
  }
}

export type TReplace = (path: Path, state?: LocationState) => void
export const useReplace = (): TReplace => {
  const history: History = useHistory()

  return (path: Path, state?: LocationState): void => {
    const lng = getLanguage()
    const url = path === '/' ? '/' : `${lng}${path}`
    history.replace(url, state)
  }
}
