const lang = {
  'bila-tserkva': 'Белоцерковский',
  boryspil: 'Бориспольский',
  brovary: 'Броварской',
  bucha: 'Бучанский',
  vyshhorod: 'Вышгородский',
  obukhiv: 'Обуховский',
  fastiv: 'Фастовский',
}

export default lang
