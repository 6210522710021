import React, { forwardRef } from 'react'
import Typography from './Typography'
import { IProps, TTypography } from './types'

const H2 = forwardRef(
  (
    {
      children,
      align,
      classes,
      className,
      color,
      display,
      gutterBottom,
      noWrap,
      paragraph,
      itemProp,
      dense,
      notUndefined = false,
    }: IProps,
    ref,
  ): TTypography | null => {
    return !notUndefined || children !== undefined ? (
      // @ts-ignore
      <Typography
        variant="h2"
        ref={ref}
        sx={{
          padding: dense ? 0 : '16px 0 8px',
          width: '100%',
        }}
        align={align}
        classes={classes}
        className={className}
        color={color}
        display={display}
        gutterBottom={gutterBottom}
        noWrap={noWrap}
        itemProp={itemProp}
        paragraph={paragraph}
      >
        {children}
      </Typography>
    ) : null
  },
)

export default H2
