interface IUsers {
  TRAFFIC: string
  VIEWS: string
  VIEWS_RATIO: string
  USERS_FROM_UKRAINE: string
  USERS_FROM_KYIV: string
  MOBILE: string
  FEMALE: string
}

interface IDomain {
  GOOGLE_INDEX: string
  BING_INDEX: string
  AGE: string
  DR: string
  UR: string
  TF: string
  CF: string
  DA: string
  PA: string
  AS: string
  SDR: string
  DT: string
}

interface ITooltip extends IDomain {
  TRAFFIC: string
}

type TChartData = { [key in string]: string }

export interface IChartData extends TChartData {
  TITLE: string
  OTHER: string
  TOTAL: string
}

export interface IStatistic {
  users: IUsers
  domain: IDomain
  tooltip: ITooltip
  vendors: IDomain
  charts: {
    countries: IChartData
  }
}

const lang: {
  statistic: IStatistic
} = {
  statistic: {
    users: {
      TRAFFIC: 'Кількість унікальних відвідувачив',
      VIEWS: 'Кількість переглядів сторінок',
      VIEWS_RATIO: 'Середня кількість переглядів сторінок за сеанс',
      USERS_FROM_UKRAINE: 'Кількість користувачів з України',
      USERS_FROM_KYIV: 'Кількість користувачів з Києва',
      MOBILE: 'Кількість відвідувань з мобільних пристроїв',
      FEMALE: 'Більшість користувачів - жінки',
    },
    domain: {
      GOOGLE_INDEX: 'Індекс Google',
      BING_INDEX: 'Індекс Bing',
      AGE: 'Вік домену',
      DR: 'Domain Rank',
      UR: 'URL Rank',
      TF: 'Trust Flow',
      CF: 'Citation Flow',
      DA: 'Domain Authority',
      PA: 'Page Authority',
      AS: 'Authority Score',
      SDR: 'Serpstat Domain Rank',
      DT: 'Domain Trust',
    },
    vendors: {
      AGE: '',
      GOOGLE_INDEX: 'google.com',
      BING_INDEX: 'bing.com',
      DR: 'ahrefs.com',
      UR: 'ahrefs.com',
      TF: 'majestic.com',
      CF: 'majestic.com',
      DA: 'moz.com',
      PA: 'moz.com',
      AS: 'semrush.com',
      SDR: 'serpstat.com',
      DT: 'seranking.com',
    },
    tooltip: {
      TRAFFIC:
        'Кількість відвідувачів майданчика на місяць. Джерело даних: Google Analytics.',
      GOOGLE_INDEX: 'Кількість сторінок сайту в пошуковій видачі Google.',
      BING_INDEX: 'Кількість сторінок сайту в пошуковій видачі Bing.',
      AGE: 'Вік домену відповідно до інформації whois.',
      DR: 'Domain Rank (DR) - Оцінка авторитету сайту на основі якості маси посилань за даними Ahrefs.com. Чим більше тим краще.',
      UR: 'Рейтинг URL-адреси (UR) показує силу посилання профілю головної сторінки за 100-бальною логарифмічною шкалою.',
      TF: 'Trust Flow (TF) - Оцінка сайту на основі якості маси посилань за даними majestic.com. Чим більше тим краще.',
      CF: 'Citation Flow (CF) - Оцінний параметр домену на основі кількості зворотних посилань та згадок за даними majestic.com.',
      DA: 'Domain Authority (DA) – Авторитет (траст) за даними moz.com. Вимірюється від 1 до 100.',
      PA: 'Page Authority (PA) – Авторитет сторінки (траст) за даними moz.com. Вимірюється від 1 до 100.',
      AS: 'Authority Score (AS) - Оцінка авторитетності за данними Semrush.com метрика, яка вимірює репутацію домену. Він враховує кількість і якість своїх зворотних посилань, органічний пошуковий трафік і загальну автентичність свого профілю.',
      SDR: "Serpstat Domain Rank (SDR) — Показник авторитетності домену за шкалою від 0 до 100. Залежить від кількості сайтів, що посилаються на аналізований домен, а також від кількості сайтів, що посилаються на ці сайти, що посилаються. Таким чином, обліковуються всі сайти в індексі, пов'язані з аналізованим доменом. Оновлюється щомісяця.",
      DT: 'Domain Trust – Довіра до домену за даними SE Ranking. Вимірюється від 1 до 100.',
    },
    charts: {
      countries: {
        TITLE: 'Країни відвідування користувачами',
        OTHER: 'Інші країни',
        TOTAL: 'Всього відвідувачів',
        ukraine: 'Україна',
        germany: 'Німечина',
        poland: 'Польша',
        unitedStates: 'США',
        russia: 'росія',
      },
    },
  },
}

export default lang
