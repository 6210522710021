import type { TError } from '@services/handleError'
import { TRole } from '@features/User/types'

export type TExpirationDate = number | boolean | undefined
export type TToken = string
export type TFingerprint = string | null

export interface ILoginEmail {
  email: string
  password: string
  rememberMe: boolean
}

export interface ILoginLink {
  email: string
  url: string
}

export interface IResetEmail {
  email: string
}

export interface IRegisterForm {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  role: TRole
}

export interface IProfileForm {
  firstName?: string
  lastName?: string
  displayName?: string | null
  email?: string | null
  photoURL?: string | null
}

export type TPush = (path: string) => void

export interface IUseAuth {
  isDisabled: boolean
  isRegistrationProcess: boolean
  isEmailVerified: boolean
  isLoggedInChecked: boolean
  isLoggedIn: boolean
  userId: string | null
  error: TError
  loginEmailPassword: (loginForm: ILoginEmail) => void
  loginEmailLink: (loginLinkForm: ILoginLink) => void
  register: (registerForm: IRegisterForm) => void
  loginGoogle: (role?: TRole) => void
  loginFacebook: (role?: TRole) => void
  loginMicrosoft: () => void
  sendResetMail: (resetForm: IResetEmail) => void
  sendEmailVerification: () => Promise<void>
  loginFirebase: () => Promise<void>
  logout: () => void
  updateAuthUser: (profile: IProfileForm) => void
  revoke: () => void
}

// Register Google

export const LOGIN_GOOGLE_STARTED = 'auth/LOGIN_GOOGLE_STARTED'

export interface ILoginGoogleStartedAction {
  type: 'auth/LOGIN_GOOGLE_STARTED'
}

export const LOGIN_GOOGLE_FINISHED = 'auth/LOGIN_GOOGLE_FINISHED'

export interface ILoginGoogleFinishedAction {
  type: 'auth/LOGIN_GOOGLE_FINISHED'
}

export const LOGIN_GOOGLE_FAILED = 'auth/LOGIN_GOOGLE_FAILED'

export interface ILoginGoogleFailedAction {
  type: 'auth/LOGIN_GOOGLE_FAILED'
  payload: TError
}

// Register Microsoft

export const LOGIN_MICROSOFT_STARTED = 'auth/LOGIN_MICROSOFT_STARTED'

export interface ILoginMicrosoftStartedAction {
  type: 'auth/LOGIN_MICROSOFT_STARTED'
}

export const LOGIN_MICROSOFT_FINISHED = 'auth/LOGIN_MICROSOFT_FINISHED'

export interface ILoginMicrosoftFinishedAction {
  type: 'auth/LOGIN_MICROSOFT_FINISHED'
}

export const LOGIN_MICROSOFT_FAILED = 'auth/LOGIN_MICROSOFT_FAILED'

export interface ILoginMicrosoftFailedAction {
  type: 'auth/LOGIN_MICROSOFT_FAILED'
  payload: TError
}

// Register Facebook

export const LOGIN_FACEBOOK_STARTED = 'auth/LOGIN_FACEBOOK_STARTED'

export interface ILoginFacebookStartedAction {
  type: 'auth/LOGIN_FACEBOOK_STARTED'
}

export const LOGIN_FACEBOOK_FINISHED = 'auth/LOGIN_FACEBOOK_FINISHED'

export interface ILoginFacebookFinishedAction {
  type: 'auth/LOGIN_FACEBOOK_FINISHED'
}

export const LOGIN_FACEBOOK_FAILED = 'auth/LOGIN_FACEBOOK_FAILED'

export interface ILoginFacebookFailedAction {
  type: 'auth/LOGIN_FACEBOOK_FAILED'
  payload: TError
}

// export const UPDATE_PHOTO_STARTED = 'auth/UPDATE_PHOTO_STARTED'
// export const UPDATE_PHOTO_FINISHED = 'auth/UPDATE_PHOTO_FINISHED'
// export const UPDATE_PHOTO_FAILED = 'auth/UPDATE_PHOTO_FAILED'

// Email Reset

export const SEND_RESET_MAIL_STARTED = 'auth/SEND_RESET_MAIL_STARTED'

export interface ISendResetMailStartedAction {
  type: 'auth/SEND_RESET_MAIL_STARTED'
}

export const SEND_RESET_MAIL_FINISHED = 'auth/SEND_RESET_MAIL_FINISHED'

export interface ISendResetMailFinishedAction {
  type: 'auth/SEND_RESET_MAIL_FINISHED'
}

export const SEND_RESET_MAIL_FAILED = 'auth/SEND_RESET_MAIL_FAILED'

export interface ISendResetMailFailedAction {
  type: 'auth/SEND_RESET_MAIL_FAILED'
  payload: TError
}

// Email verification

export const SEND_EMAIL_VERIFICATION_STARTED =
  'auth/SEND_EMAIL_VERIFICATION_STARTED'

export interface ISendEmailVerificationStartedAction {
  type: 'auth/SEND_EMAIL_VERIFICATION_STARTED'
}

export const SEND_EMAIL_VERIFICATION_FINISHED =
  'auth/SEND_EMAIL_VERIFICATION_FINISHED'

export interface ISendEmailVerificationFinishedAction {
  type: 'auth/SEND_EMAIL_VERIFICATION_FINISHED'
}

export const SEND_EMAIL_VERIFICATION_FAILED =
  'auth/SEND_EMAIL_VERIFICATION_FAILED'

export interface ISendEmailVerificationFailedAction {
  type: 'auth/SEND_EMAIL_VERIFICATION_FAILED'
  payload: TError
}

// Register

export const REGISTER_STARTED = 'auth/REGISTER_STARTED'

export interface IRegisterStartedAction {
  type: 'auth/REGISTER_STARTED'
}

export const REGISTER_FINISHED = 'auth/REGISTER_FINISHED'

export interface IRegisterFinishedAction {
  type: 'auth/REGISTER_FINISHED'
}

export const REGISTER_FAILED = 'auth/REGISTER_FAILED'

export interface IRegisterFailedAction {
  type: 'auth/REGISTER_FAILED'
  payload: TError
}

export const SET_FINGERPRINT_FINISHED = 'auth/SET_FINGERPRINT_FINISHED'
export interface ISetFingerprintFinishedAction {
  type: 'auth/SET_FINGERPRINT_FINISHED'
  payload: TFingerprint
}

export const SET_FINGERPRINT_FAILED = 'auth/SET_FINGERPRINT_FAILED'
export interface ISetFingerprintFailedAction {
  type: 'auth/SET_FINGERPRINT_FAILED'
  payload: TError
}

// Not signed In

export const SET_NOT_SIGNED_IN = 'auth/SET_NOT_SIGNED_IN'

export interface ISetNotSignedInAction {
  type: 'auth/SET_NOT_SIGNED_IN'
}

// Sign in Firebase

export const SET_LOGIN_FIREBASE_STARTED = 'auth/SET_LOGIN_FIREBASE_STARTED'

export interface ISetLoginFirebaseStartedAction {
  type: 'auth/SET_LOGIN_FIREBASE_STARTED'
}

export const SET_LOGIN_FIREBASE_FINISHED = 'auth/SET_LOGIN_FIREBASE_FINISHED'

export interface ISetLoginFirebaseFinishedAction {
  type: 'auth/SET_LOGIN_FIREBASE_FINISHED'
  payload: boolean
}

export const SET_LOGIN_FIREBASE_FAILED = 'auth/SET_LOGIN_FIREBASE_FAILED'

export interface ISetLoginFirebaseFailedAction {
  type: 'auth/SET_LOGIN_FIREBASE_FAILED'
  payload: TError
}

// Sign In

export const LOGIN_STARTED = 'auth/LOGIN_STARTED'

export interface ILoginStartedAction {
  type: 'auth/LOGIN_STARTED'
}

export const LOGIN_FINISHED = 'auth/LOGIN_FINISHED'

export interface ILoginFinishedAction {
  type: 'auth/LOGIN_FINISHED'
}

export const LOGIN_FAILED = 'auth/LOGIN_FAILED'

export interface ILoginFailedAction {
  type: 'auth/LOGIN_FAILED'
  payload: TError
}

// Sign In

export const UPDATE_AUTH_USER_STARTED = 'auth/UPDATE_AUTH_USER_STARTED'

export interface IUpdateAuthUserStartedAction {
  type: 'auth/UPDATE_AUTH_USER_STARTED'
}

export const UPDATE_AUTH_USER_FINISHED = 'auth/UPDATE_AUTH_USER_FINISHED'

export interface IUpdateAuthUserFinishedAction {
  type: 'auth/UPDATE_AUTH_USER_FINISHED'
}

export const UPDATE_AUTH_USER_FAILED = 'auth/UPDATE_AUTH_USER_FAILED'

export interface IUpdateAuthUserFailedAction {
  type: 'auth/UPDATE_AUTH_USER_FAILED'
  payload: TError
}

// SignIn Link

export const LOGIN_LINK_STARTED = 'auth/LOGIN_LINK_STARTED'

export interface ILoginLinkStartedAction {
  type: 'auth/LOGIN_LINK_STARTED'
}

export const LOGIN_LINK_FINISHED = 'auth/LOGIN_LINK_FINISHED'

export interface ILoginLinkFinishedAction {
  type: 'auth/LOGIN_LINK_FINISHED'
}

export const LOGIN_LINK_FAILED = 'auth/LOGIN_LINK_FAILED'

export interface ILoginLinkFailedAction {
  type: 'auth/LOGIN_LINK_FAILED'
  payload: TError
}

// SignOut

export const LOGOUT = 'auth/LOGOUT'

export interface ISignoutAction {
  type: 'auth/LOGOUT'
}

export const LOGOUT_FAILED = 'auth/LOGOUT_FAILED'

export interface ISignoutFailedAction {
  type: 'auth/LOGOUT_FAILED'
  payload: TError
}

export const REVOKE = 'auth/REVOKE'

export interface IRevokeAction {
  type: 'auth/REVOKE'
}

export interface IState {
  isReadyLogin: boolean
  isLoggedIn: boolean
  isReadyRegister: boolean
  isReadyGoogle: boolean
  isReadyFacebook: boolean
  isReadyMicrosoft: boolean
  isEmailVerified: boolean
  isReadySendEmailVerified: boolean
  isReadySendResetMail: boolean
  isReadyUpdateAuthUser: boolean
  fingerprint: TFingerprint
  error: TError
}

export type TActionHandler =
  // | ISetLoginFirebaseStartedAction
  // | ISetLoginFirebaseFinishedAction
  // | ISetLoginFirebaseFailedAction
  | ISetNotSignedInAction
  | ISetFingerprintFinishedAction
  | ISetFingerprintFailedAction
  | IUpdateAuthUserStartedAction
  | IUpdateAuthUserFinishedAction
  | IUpdateAuthUserFailedAction
  | ILoginStartedAction
  | ILoginFinishedAction
  | ILoginFailedAction
  | ILoginLinkStartedAction
  | ILoginLinkFinishedAction
  | ILoginLinkFailedAction
  | ISendEmailVerificationStartedAction
  | ISendEmailVerificationFinishedAction
  | ISendEmailVerificationFailedAction
  | IRegisterStartedAction
  | IRegisterFinishedAction
  | IRegisterFailedAction
  | ISendResetMailStartedAction
  | ISendResetMailFinishedAction
  | ISendResetMailFailedAction
  | ILoginGoogleStartedAction
  | ILoginGoogleFinishedAction
  | ILoginGoogleFailedAction
  | ILoginFacebookStartedAction
  | ILoginFacebookFinishedAction
  | ILoginFacebookFailedAction
  | ILoginMicrosoftStartedAction
  | ILoginMicrosoftFinishedAction
  | ILoginMicrosoftFailedAction
  | ISignoutAction
  | ISignoutFailedAction
  | IRevokeAction
