import { get } from '@services/common'
import { TLocale } from '@i18n'

type TData = object
type TName = string
type TDefaultValue = string

let _data = {}
let _language: TLocale = 'ua'
let _isReady = false

export function setData(language: TLocale, data: TData): void {
  _data = data
  _language = language
  _isReady = true
}

export function t(name: TName, defaultValue?: TDefaultValue): string {
  return get(
    _data,
    name,
    defaultValue ? get(_data, defaultValue, defaultValue) : '',
  )
}

const services = {
  setData(language: TLocale, data: TData): void {
    _language = language
    _data = data
    _isReady = true
  },
  t(name: TName, defaultValue?: TDefaultValue): string {
    return get(
      _data,
      name,
      defaultValue ? get(_data, defaultValue, defaultValue) : '',
    )
  },
  get isReady(): boolean {
    return _isReady
  },
  get language(): TLocale {
    return _language
  },
  get all(): TData {
    return _data
  },
}

export default services
