import { v4 as uuidv4 } from 'uuid'

export { default as get } from 'lodash/get'
export { default as isEqual } from 'lodash/isEqual'
export { default as pick } from 'lodash/pick'
export { default as omit } from 'lodash/omit'
export { default as uniq } from 'lodash/uniq'

export const getUUID = (): string => uuidv4()

export const time = (): number => {
  return Date.now()
}

type TEmpty = string | [any] | object | undefined | null

export function isEmpty(obj: TEmpty): boolean {
  return obj === null || obj === undefined || Object.keys(obj).length === 0
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isObject(obj: any): boolean {
  return typeof obj === 'object' && obj !== null
}

export function removeTokenFromUrl(url: string): string {
  return url.split('&token=')[0]
}

export function getFileNameExtension(fileName: string): string {
  const fileNameParts = fileName.split('.')
  return fileNameParts.slice(-1)[0]
}

export function removeFileNameExtension(fileName: string): string {
  const fileNameParts = fileName.split('.')
  return fileNameParts.slice(0, -1).join('')
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// @ts-ignore
export const randomInteger = (
  min: number,
  max: number,
  prevValue?: number | Array<number>,
): number => {
  const rand = min - 0.5 + Math.random() * (max - min + 1)
  const result = Math.round(rand)

  return (typeof prevValue === 'object' && prevValue.indexOf(result) !== -1) ||
    prevValue === result
    ? randomInteger(min, max, prevValue)
    : result
}

export const randomList = (
  min: number,
  max: number,
  length: number,
): Array<number> => {
  const diff = max - min
  const limit = length < diff ? length : diff

  const result = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < limit; i++) {
    const item = randomInteger(min, max, result)
    result.push(item)
  }

  return result
}

export const randomRange = (
  idx = 1,
  range = 1000,
  rangeBetween = 500,
): number => {
  const prevIdx = idx - 1
  const min = prevIdx * (range + rangeBetween) + 1
  const max = prevIdx * rangeBetween + idx * range
  return randomInteger(min, max)
}

export const chunkArray = (array: Array<any>, chunk: number) => {
  const newArray = []
  const itemLength = Math.ceil(array.length / chunk)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < chunk; i++) {
    newArray.push(array.slice(i * itemLength, (i + 1) * itemLength))
  }

  return newArray
}

export const isOdd = (number: number): boolean => {
  return !!(number % 2)
}
