import { memo, useEffect } from 'react'
import { TLocale } from '@i18n'
import useTranslation from './useI18n'
import { setData } from './services'

const I18nComponent = () => {
  const { t, i18n } = useTranslation('components')

  useEffect(() => {
    setData(i18n.language as TLocale, {
      errors: t('errors', { returnObjects: true }),
      validations: t('validations', { returnObjects: true }),
      snackbars: t('snackbars', { returnObjects: true }),
    })
  }, [i18n.language])

  return null
}

export default memo(I18nComponent)
