import company from '@i18n/locales/ua/components/company'
import { ITServices } from '@i18n/locales/ua/pages/services'

const lang: {
  services: ITServices
} = {
  services: {
    meta: {
      TITLE_SHORT: 'Послуги сайта SchoolHub',
      TITLE:
        'Платные услуги для школ на SchoolHub: подборка пакетов послуг для оптимального развития',
      DESCRIPTION:
        'Ознакомьтесь с нашим списком платных услуг для школ на SchoolHub. Выбирайте из различных опций, чтобы повысить видимость вашего учебного заведения.',
    },
    content: {
      levels: {
        free: 'Free',
        silver: 'Silver',
        gold: 'Gold',
        platinum: 'Platinum',
      },
      features: {
        REGISTRATION: 'Регистрация школы',
        OFFICE: 'Кабинет школы',
        EDIT_DESCRIPTION: 'Опция редактирования школы',
        EDIT_LOGO: 'Возможность смены логотипа и фотографий',
        EDIT_CONTACTS: 'Управление контактами школы',
        CLOSE_COMMENTS: 'Закрытие комментариев',
        REPLY_COMMENTS: 'Ответы на комментарии от имени школы',
        ADVANCED_EDIT_DESCRIPTION:
          'Расширенные возможности редактирования описания школы',
        VIDEO: 'Видеопрезентация школы на странице школы',
        SEARCH_LOGO: 'Логотип на странице поиска',
        SEARCH_VIP: 'VIP статус на странице школы',
        SCHOOL_VIP: 'VIP дизайн на странице поиска',
        SEARCH_PRIORITY: 'Приоритет в поиске в рамках города',
        SCHOOL_DOFOLLOW_LINK: 'Прямая dofollow ссылка на сайт школы',
        ADS_LIST:
          'Название школы присутствует в списке структурированного описания Google Ads',
        PICK_UP_SILVER_GOLD:
          'Три бесплатных поднятия на странице поиска среди Silver и Gold',
        SCHOOL_CLOSEST:
          'Размещение в разделе “Ближайшие” на странице других школ',
        MAP_CLOSEST:
          'Размещение на карте города как “Ближайшее” заведение на странице другой школы',
        MAP_VIP: 'VIP дизайн на карте города',
        HOME_LOGO: 'Логотип на главной странице',
        SEARCH_PRIORITY_PLATINUM:
          'Приоритет размещения в результатах поиска в рамках города над Silver и Gold',
        SEARCH_RESULT_ANY:
          'Присутствие в результатах поиска по тексту при несовпадении',
        PICK_UP_PLATINUM:
          'Три бесплатных поднятия на странице поиска среди Platinum',
      },
      description: {
        REGISTRATION:
          'На SchoolHub мы постоянно дополняем наш каталог новыми учебными заведениями, добавленными нашими администраторами. Но мы приветствуем возможность добавления вашей школы лично.',
        REGISTRATION_SNACKBAR:
          'Если вы хотите самостоятельно добавить вашу школу в наш список, отправьте нам запрос на электронный адрес, и мы с радостью поможем вам это сделать.',
        OFFICE: 'Не доступно!!!!',
        EDIT_DESCRIPTION:
          'Мы понимаем, что никто не знает вашу школу лучше вас. Поэтому у вас есть возможность изменять описание вашего учебного заведения.',
        EDIT_DESCRIPTION_SNACKBAR:
          'Сейчас эта возможность доступна по нашему электронному адресу. Просто отправьте нам обновленное описание, и мы сразу обновим вашу страницу, обеспечивая вам контроль над тем, как вы хотите представить вашу школу на нашем сайте.',
        EDIT_LOGO:
          'Мы понимаем, как важно отображение вашего учебного заведения уникальным и привлекательным способом. Поэтому у вас есть возможность изменять логотип и фотографии вашей школы.',
        EDIT_LOGO_SNACKBAR:
          'В настоящее время эта возможность доступна с помощью нашего электронного адреса. Просто отправьте нам ваши изменения, и мы оперативно обновим вашу страницу, помогая вам создать яркое и выразительное представление вашей школы на нашем сайте.',
        EDIT_CONTACTS:
          'Мы понимаем, что актуальные контактные данные важны для обеспечения связи с вашей школой. Поэтому мы предлагаем изменение контактных данных, включая почту, адрес, телефоны, а также ваши профили в социальных сетях.',
        EDIT_CONTACTS_SNACKBAR:
          'В настоящее время эта возможность доступна через наш электронный адрес. Просто отправьте нам обновленные контактные данные, и мы оперативно обновим вашу страницу, обеспечивая точную и актуальную информацию для связи с вашей школой на нашем сайте.',
        CLOSE_COMMENTS:
          'Мы предоставляем вам возможность закрыть все комментарии на странице вашей школы, позволяя обеспечить приватность и защиту от любых нежелательных комментариев. Это легкая и удобная опция, которая позволяет оставлять контроль только в вашем русле.',
        REPLY_COMMENTS:
          'Хотите принять участие в дискуссиях как представитель вашей школы? Наша функция ответа от имени школы позволяет вам это сделать легко и эффективно. Вы сможете взаимодействовать с родителями и учениками, давать объяснения и ответы на их вопросы, все это от имени учебного заведения. Просто активируйте эту опцию в настройках и вы станете активным участником диалога на странице вашей школы.',
        ADVANCED_EDIT_DESCRIPTION: 'Не доступно!!!!',
        VIDEO:
          'Привлеките внимание родителей и учащихся с помощью видео-презентации из Youtube на странице вашей школы. Вы можете добавить видео, которое расскажет об особенностях вашего учебного заведения и покажет его атмосферу. Если у Вас нет Youtube канала, мы можем разместить Ваше видео на канале SchoolHub.',
        VIDEO_SNACKBAR:
          'Сейчас эта возможность доступна по нашему электронному адресу. Просто отправьте нам ссылку на видео-ролик на Youtube, и мы добавим его на страницу школы.',
        SEARCH_LOGO:
          'Добавьте вашу школу в эксклюзивный раздел "Ведущие школы" на странице поиска. Этот раздел позволяет вашему учебному заведению выделиться среди других и привлечь внимание родителей и учащихся. Ваш логотип будет отображаться рядом с вашим списком в разделе "Ведущие школы", предоставляя вашему учебному заведению престижный статус и особую связь с целевой аудиторией.',
        SEARCH_VIP:
          'Подчеркните видимость вашей школы с помощью VIP дизайна на странице поиска. Ваше учебное заведение будет отличаться на фоне других школ, обеспечивая вам важное преимущество в привлечении внимания родителей и учащихся. Этот VIP дизайн позволит вам быть заметным и отличиться в качестве лидера в списке учебных заведений. Выбрать эту функцию, и ваша школа будет привлекать взгляды еще до первого взгляда на страницу поиска.',
        SCHOOL_VIP:
          'Сделайте свою школу узнаваемой с помощью VIP дизайна на ее странице. Этот дизайн позволяет отличаться вашему учебному заведению на фоне других, обеспечивая видимость и преимущество. С минимальными, но важными изменениями, ваша страница будет привлекать больше внимания и поражать учащихся и родителей. Выбрать эту функцию, и ваша школа станет настоящим лидером на странице поиска учебных заведений.',
        SEARCH_PRIORITY:
          'Сохраните внимание пользователей, предоставляя вашей странице особый VIP дизайн. Это позволит подчеркнуть уникальность вашей школы.',
        SCHOOL_DOFOLLOW_LINK:
          'Укрепите свое присутствие в поисковых системах благодаря функции Dofollow ссылку на странице вашей школы. Эта возможность позволит вам обеспечить повышенную видимость и рейтинг поисковых запросов. При активации этой функции ссылки с вашей страницы будут увеличивать авторитет и позиции вашего учебного заведения в результатах поиска. Сделайте свою школу более заметной для поисковиков и потенциальных клиентов, выбрав эту опцию для улучшения вашего SEO.',
        ADS_LIST:
          'Увеличьте узнаваемость вашей школы благодаря функции, включающей название вашего учебного заведения в структурированный список в рекламе SchoolHub на Google Ads. Эта возможность добавляет вашу школу в выбранный список школ, представленных в рамках рекламной кампании SchoolHub. Ваш бренд будет заметен и способен привлечь больше внимания уже на начальном этапе поиска. Ваша школа станет неотъемлемой частью рекламной кампании SchoolHub, помогая вам привлечь больше клиентов и повысить узнаваемость бренда.',
        PICK_UP_SILVER_GOLD:
          'Мы предлагаем вам три бесплатных поднятия в результатах поиска среди Silver и Gold пакетов. Это позволяет поднять позицию вашей школы на странице результатов поиска, предоставляя ей более видимое место и больше шансов быть замеченным пользователями.',
        PICK_UP_SILVER_GOLD_SNACKBAR:
          'Просто отправьте запрос на наш почтовый адрес, и мы обеспечим поднятие вашей школы на более высокий уровень видимости.',
        SCHOOL_CLOSEST:
          'Обратите внимание на вашу школу благодаря размещению в блоке "Ближайшие" на страницах других учебных заведений. Ваша школа будет представлена рядом с три ближайшими ведущими учебными заведениями, предоставляя родителям и учащимся возможность рассмотреть альтернативы вблизи их места жительства. Этот блок поможет позиционировать вашу школу как удобный выбор, что может конкурировать с другими ведущими учебными заведениями.',
        MAP_CLOSEST:
          'Обнаружите вашу школу на карте, разместив ее в списке из трех ведущих ближайших учебных заведений, дополняющих то, на котором сейчас находится пользователь. Это позволяет посетителям увидеть вашу ведущую школу как альтернативу, которая находится в их непосредственной среде. Такая возможность поможет сделать обоснованный выбор и открывает возможность рассмотрения вашей школы в контексте ее конкурентов.',
        MAP_VIP:
          'Обеспечьте особую видимость вашей школы благодаря VIP иконке на карте, которая будет присутствовать на странице поиска и на странице вашей школы. Эта иконка выделит Вашу школу среди других, обеспечивая вам особый статус и привлекая больше внимания от родителей и учащихся. Ваша школа будет заметна на карте, позволяя выделиться в шумном мире образовательных учреждений.',
        HOME_LOGO:
          'Поднимите свой бренд на более высокий статус благодаря выделению вашего логотипа на главной странице. Эта функция придаст учебному заведению выдающуюся видимость и статусный вид, позволяя вам выбиться на фоне других школ. Ваш логотип будет отображаться с почетным статусом, показывая значимость вашей школы.',
        SEARCH_PRIORITY_PLATINUM:
          'Определите вашу школу на более высокий уровень благодаря приоритетному позиционированию в результатах поиска в рамках вашего города, превышающего позиции Silver и Gold пакетов. Эта функция позволяет вашей школе выступить в качестве ведущего выбора среди других учебных заведений. Выберите этот функционал, чтобы обеспечить вашей школе наивысший приоритет в выдаче поиска, увеличивая вероятность обращений родителей и учащихся в ваше заведение.',
        SEARCH_RESULT_ANY:
          'Обеспечьте вашу школу стабильной видимостью в результатах поиска, даже если используются широкие наборы фильтров. Эта функция гарантирует, что ваша VIP школа всегда будет присутствовать в списке подходящих образовательных учреждений, даже если запрос пользователя включает большое количество параметров. Выбирайте этот вариант, чтобы уверенно получать внимание родителей и учащихся вне зависимости от большого объема фильтров, применяемых в их поиске.',
        PICK_UP_PLATINUM:
          'Получите возможность провести 3 бесплатных поднятия вашей школы на видимые позиции среди Platinum учебных заведений в результатах поиска. Эта функция дает вам шанс выдвинуться на передовые позиции, обеспечивая вашу школу первоочередной видимостью для родителей и учащихся.',
        PICK_UP_PLATINUM_SNACKBAR:
          'Просто отправьте запрос на наш почтовый адрес.',
      },
      tooltips: {
        MANUAL:
          'Временно (до создания кабинета школы) доступно по запросу через email',
      },
      price: {
        CURRENCY: 'грн',
        TITLE: 'Стоимость',
        ACTION: 'Акция до 27 ноября',
        MONTHS_3: '3 месяца, грн',
        MONTHS_6: '6 месяцев, грн',
        MONTHS_6_DISCOUNT: (discount) => `Скидка ${discount}%`,
      },
      headers: {
        SERVICES: 'Услуги сайта SchoolHub',
        LEADING: 'Ведущая школа',
        INFO_ORDER: 'Заказ размещения ведущей школы',
        FEATURE_DESCRIPTIONS: 'Подробное описание услуг',
        ADDITIONAL_DISCOUNTS: 'Дополнительные скидки',
        CONTINUATION_SUBSCRIPTION: 'Продление подписки',
        GROUP_SUBSCRIPTION: 'Групповая подписка',
      },
      paragraphs: {
        SERVICES:
          'SchoolHub — ведущий образовательный портал в Украине, который предлагает самый полный и удобный каталог школ. Здесь собрана подробная информация о разных учебных заведениях, позволяя родителям и учащимся найти оптимальное образовательное учреждение.',
        NUMBER_OF_USERS: (number: string) =>
          `Более ${number} пользователей в месяц`,
        STATISTIC: 'Полная статистика проекта доступна на странице ',
        STATISTIC_LINK: 'Статистика сайта',
        LEADING_1:
          'Мы предлагаем несравнимые возможности для вашего образовательного учреждения выйти на передовой план и поразить вашу целевую аудиторию.',
        LEADING_2:
          'Наша цель – помочь вам реализовать полный потенциал вашей школы, обеспечивая доступ к широкому спектру дополнительных платных услуг. С этими услугами вы сможете повысить видимость вашего учебного заведения, привлечь внимание родителей и учащихся, и обеспечить наилучшие условия для обучения и развития.',
        LEADING_3:
          'Ваша школа сможет выбирать среди различных предложений, включая размещение промо-видео на странице школы, эксклюзивный дизайн и выгодные позиции в поисковых результатах. Также вы сможете получить VIP статус, который дополнительно подчеркнет вашу уникальность и лучшие характеристики.',
        LEADING_4:
          'Подарите вашей школе возможность сиять на SchoolHub. Выберите опции, которые лучше всего отвечают вашим потребностям, и помогите вашей школе стать лидером в образовательном пространстве.',
        INFO_ORDER_STEP_1: 'Шаг 1',
        INFO_ORDER_STEP_2: 'Шаг 2',
        INFO_ORDER_STEP_3: 'Шаг 3',
        INFO_ORDER_STEP_4: 'Шаг 4',
        INFO_ORDER_1: `Отправьте запрос на почту ${company.company.EMAIL}. Укажите школу и выбранный пакет услуг.`,
        INFO_ORDER_2: 'Получите счет-фактуру для оплаты.',
        INFO_ORDER_3: 'Переведите средства на указанный счет.',
        INFO_ORDER_4: 'Получите акт выполненных работ.',
        INFO_ORDER_SUCCESS:
          'Ваша школа подключена к выбранному пакету ведущей школы!',
        CONTINUATION_SUBSCRIPTION_1:
          'Экономьте при продлении подписки на SchoolHub.',
        CONTINUATION_SUBSCRIPTION_2:
          'Мы благодарны за ваш выбор SchoolHub для продвижения вашего учебного заведения. Спасибо за доверие и поддержку!',
        CONTINUATION_SUBSCRIPTION_3: 'Скидка 10% при продлении подписки!',
        CONTINUATION_SUBSCRIPTION_LIST_TITLE: 'Как это работает:',
        CONTINUATION_SUBSCRIPTION_LIST_1_BOLD: 'Продление подписки.',
        CONTINUATION_SUBSCRIPTION_LIST_1:
          'Выберите продление вашей подписки на SchoolHub, чтобы продолжать пользоваться всеми преимуществами нашего сервиса.',
        CONTINUATION_SUBSCRIPTION_LIST_2_BOLD: 'Получите скидку 10%.',
        CONTINUATION_SUBSCRIPTION_LIST_2:
          'Свяжитесь с Администрацией SchoolHub для получения скидки. При продлении вашей подписки вы получаете дополнительную скидку 10% на выбранный пакет.',
        CONTINUATION_SUBSCRIPTION_LIST_3_BOLD: 'Сохраняйте свои выгоды.',
        CONTINUATION_SUBSCRIPTION_LIST_3:
          'Продолжайте привлекать внимание и привлекать новых учеников, экономя при этом.',

        GROUP_SUBSCRIPTION_1:
          'Скидка за групповую подписку на учебные заведения одной франшизы на SchoolHub!',
        GROUP_SUBSCRIPTION_2:
          'Рассматриваете возможность продвижения нескольких учебных заведений своей франшизы? Тогда мы имеем для вас отличную новость! SchoolHub предлагает специальную скидку за групповую подписку, которая позволяет экономить значительную сумму при размещении нескольких заведений на нашем сайте.',
        GROUP_SUBSCRIPTION_3:
          'Поднимите свою франшизу на новый уровень вместе со SchoolHub. Экономьте и получите максимум от размещения своих заведений на нашей платформе!',
        GROUP_SUBSCRIPTION_LIST_TITLE: 'Как это работает:',
        GROUP_SUBSCRIPTION_LIST_1_BOLD:
          'Объедините несколько заведений одной франшизы.',
        GROUP_SUBSCRIPTION_LIST_1:
          'Создайте единственную карточку заведений для более удобного выбора.',
        GROUP_SUBSCRIPTION_LIST_2_BOLD: 'Получите скидку.',
        GROUP_SUBSCRIPTION_LIST_2:
          'Свяжитесь с Администрацией SchoolHub для получения скидки. Размер скидки представлен в таблице ниже.',
        GROUP_SUBSCRIPTION_LIST_3_BOLD: 'Экономьте вместе с групповой скидкой.',
        GROUP_SUBSCRIPTION_LIST_3:
          'Сумма скидки на каждое заведение зависит от их количества и выбранного пакета. Чем больше заведений в вашей группе и чем выше пакет, тем выше скидка!',
        GROUP_SUBSCRIPTION_NUMBER_SCHOOLS: 'Количество заведений',
        GROUP_SUBSCRIPTION_MORE: 'Больше',
        GROUP_SUBSCRIPTION_MORE_DISCOUNT: 'Персональная скидка',
        DISCOUNT_INFO: '* Скидки добавляются к другим акционным скидкам',
      },
      buttons: {
        ORDER: 'Заказать',
      },
    },
  },
}

export default lang
