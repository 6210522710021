import { TCategory } from '@routers'

interface IParams {
  category?: TCategory
  region?: string
  city?: string
  page?: string
}

export function normalizeDocName(
  location: IParams = {},
  separator = '-',
): string {
  if (location === undefined) {
    return ''
  }

  const { city, region } = location

  if (city) {
    return `${region}${separator}${city}`
  }

  if (region) {
    return `${region}`
  }

  return ''
}

export function normalizeDocSearchName(
  location: IParams = {},
  separator = '-',
): string {
  if (location === undefined) {
    return ''
  }

  const { category } = location

  const docName = normalizeDocName(location, separator)

  if (!category) {
    return ''
  }

  return docName === '' ? category : `${category}${separator}${docName}`
}

interface IDocNames {
  docName: string
  docSearchName: string
  docNameAlt: string
  docSearchNameAlt: string
}

export function normalizeFullId(location: IParams = {}, id: string): string {
  if (location === undefined) {
    return ''
  }

  const docSearchName = normalizeDocSearchName(location, '-')

  return `${docSearchName}-${id}`
}

interface IDocNames {
  docName: string
  docSearchName: string
}

const cities = ['kyiv']

export function normalizeDocNames(
  location: IParams = {},
  separator = '-',
): IDocNames {
  const { category, city } = location

  if (!city || !category) {
    return {
      docName: '',
      docSearchName: '',
      docNameAlt: '',
      docSearchNameAlt: '',
    }
  }

  return {
    docName: normalizeDocName(location, separator),
    docSearchName: normalizeDocSearchName(location, separator),
    docNameAlt: normalizeDocName(
      {
        ...location,
        city: cities.includes(location.city || '') ? 'region' : 'kyiv',
      },
      separator,
    ),
    docSearchNameAlt: normalizeDocSearchName(
      {
        ...location,
        city: cities.includes(location.city || '') ? 'region' : 'kyiv',
      },
      separator,
    ),
  }
}
