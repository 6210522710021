const lang = {
  validations: {
    auth: {
      INVALID_EMAIL: 'Невірний email',
      PASSWORD_SHORT: 'Пароль надто короткий - має бути не менше 6 символів',
      INVALID_PASSWORD:
        'Must contain one uppercase, one lowercase, one number and one special character',
      CONFIRM_PASSWORD: 'Паролі повинні співпадати',
      ROLE: 'Потрібно вибрати роль користувача',
    },
    email: {
      INVALID_EMAIL: 'Невірний email',
      ADDED: 'Користувач вже доданий',
      EXIST: 'Користувач вже існує',
      DELETED: 'Користувач видалений',
    },
    password: {
      PASSWORD_SHORT: 'Пароль надто короткий - має бути не менше 6 символів',
      INVALID_PASSWORD:
        'Must contain one uppercase, one lowercase, one number and one special character',
      CONFIRM_PASSWORD: 'Паролі повинні співпадати',
    },
    form: {
      REQUIRED: 'Обов`язкове поле',
      TOO_SHORT: 'Занадто короткий',
      TOO_LONG: 'Занадто довгий',
    },
    phone: {
      COUNTRY_CODE: 'Невірний код країни',
      LOCAL_NUMBER: 'Порожній номер телефону',
    },
  },
}

export default lang
