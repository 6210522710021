import { i18nService } from '@i18n'
import { IS_TEST } from '@config'

export type TErrorStore = string | null
export type TErrorCode = string
export type TMessage = string
export interface IHandledError {
  code: string
  message: string
  description: string | null
}

interface IFirebaseError {
  code: TErrorCode
  message: TMessage
}

type TObjectError = IFirebaseError

export type TError = TObjectError | undefined | null | any

function log(code: TErrorCode, message: TMessage, error: TError): void {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(
      `__ERROR__:`,
      `[${code}]`,
      message,
      '\n__ORIGIN__:',
      `[${error?.code}]`,
      error?.message,
    )
  } else {
    // eslint-disable-next-line no-console
    console.error(`Error:`, `[${code}]`, message)
  }
}

export default function handleError(
  code: TErrorCode,
  error?: TError,
): IHandledError {
  const normalizedCode = error
    ? `errors.${code}.${error?.code}`
    : `errors.${code}`
  const message = i18nService.t(
    normalizedCode,
    error?.message || `${code}.DEFAULT`,
  )

  if (!IS_TEST) {
    log(normalizedCode, message, error)
  }

  return {
    code: normalizedCode,
    message,
    description: error?.message || null,
  }
}
