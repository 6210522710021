import React, { lazy, memo, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from '@routers'
import theme, {
  GlobalStyles,
  ThemeProvider,
  StyledEngineProvider,
} from '@theme'
import { globalStyles } from '@styles/global'
import store from '@store'
import { MapContextProvider } from '@utils/contexts'
import { IS_ADMIN } from '@config/client'

const AppPublic = lazy(() => import('./AppPublic'))
const AppAdmin = lazy(() => import('./AppAdmin'))

function App(): ReactElement {
  const inputGlobalStyles = <GlobalStyles styles={globalStyles} />

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MapContextProvider>
            <Provider store={store}>
              {inputGlobalStyles}
              {IS_ADMIN ? <AppAdmin /> : <AppPublic />}
            </Provider>
          </MapContextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default memo(App)
