const lang = {
  vinnytska: 'Вінницька',
  volynska: 'Волинська',
  dnipropetrovska: 'Дніпропетровська',
  donetska: 'Донецька',
  zhytomyrska: 'Житомирська',
  zakarpatska: 'Закарпатська',
  zaporizka: 'Запорізька',
  'ivano-frankivska': 'Івано-Франківська',
  kyivska: 'Київська',
  kirovohradska: 'Кіровоградська',
  luhanska: 'Луганська',
  lvivska: 'Львівська',
  mykolaivska: 'Миколаївська',
  odeska: 'Одеська',
  poltavska: 'Полтавська',
  rivnenska: 'Рівненська',
  sumska: 'Сумська',
  ternopilska: 'Тернопільська',
  kharkivska: 'Харківська',
  khersonska: 'Херсонська',
  khmelnytska: 'Хмельницька',
  cherkaska: 'Черкаська',
  chernihivska: 'Чернігівська',
  chernivetska: 'Чернівецька',
  ark: 'Автономна Республіка Крим',
}

export default lang
