const lang = {
  contacts: {
    LABEL: 'Контакты',
    list: {
      EMAIL: 'Email',
      FACEBOOK: 'Facebook',
      INSTAGRAM: 'Instagram',
      PHONE: 'Телефон',
      TELEGRAM: 'Telegram',
      URL: 'Сайт',
      VIBER: 'Viber',
      YOUTUBE: 'Youtube',
      TWITTER: 'Twitter',
      LEGAL: 'Юрлицо',
    },
    ADDRESS: 'Адрес',
    NO_ITEMS: 'В избранное ничего не добавлено',
  },
}

export default lang
