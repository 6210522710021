const color = {
  black: '#000',
  white: '#fff',
  pink: {
    100: '#ff4081',
    200: '#f50057',
    300: '#c51162',
  },
  red: {
    100: '#e57373',
    200: '#f44336',
    300: '#d32f2f',
  },
  green: {
    100: '#81c784',
    200: '#5f8f01',
    300: '#0d3d25',
  },
  blue: {
    50: '#ebf6fe',
    100: '#64b5f6',
    200: '#2196f3',
    300: '#1976d2',
  },
  violet: {
    100: '#C9CCFF',
    200: '#3f51b5',
    300: '#303f9f',
  },
  orange: {
    50: '#ffefd5',
    100: '#ffb74d',
    200: '#ff9800',
    300: '#ea6200',
  },
  yellow: {
    50: '#fdf7e2',
    100: '#fff8d5',
  },
  greyList: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  blackList: {
    100: 'rgba(0, 0, 0, 0.04)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.12)',
    400: 'rgba(0, 0, 0, 0.26)',
    500: 'rgba(0, 0, 0, 0.38)',
    600: 'rgba(0, 0, 0, 0.54)',
    700: 'rgba(0, 0, 0, 0.7)',
    900: 'rgba(0, 0, 0, 0.87)',
  },
}

const item = {
  appBar: color.white,
  body: color.greyList[100],
}

const colors = {
  ...color,
  item,
}

export default colors
