const lang = {
  form: {
    SEARCH: 'Знайти',
    CREATE: 'Створити',
    CLEAR: 'Очистити',
    CLEAR_TOOLTIP: 'Очистити фільтри',
    CREATE_CONTINUE: 'Створити і продовжити',
    CREATE_CLOSE: 'Створити і закрити',
    EDIT_CONTINUE: 'Змінити і продовжити',
    EDIT_CLOSE: 'Змінити і закрити',
    DELETE: 'Видалити',
    SHOW_MORE: 'Показати більше',
    ACTIVE_LIST: 'Активні',
    NOT_ACTIVE_LIST: 'Сховані',
    ACTIVE: 'Активен',
    NOT_ACTIVE: 'Схований',
    VIP: 'VIP',
    NOT_VIP: 'Не VIP',
    BANNED: 'Забанен',
    NOT_BANNED: 'Не забанений',
    ALL: 'Всі',
    EXPAND: 'Показати',
    COLLAPSE: 'Сховати',
  },
}

export default lang
