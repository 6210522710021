const lang = {
  school: {
    level: {
      LABEL: 'Аккредитация',
      preschool: 'Дошкольное образование',
      elementary: 'I-й ступени',
      middle: 'II-й ступени',
      high: 'III-й ступени',
      full: 'Все (I-III ступеней)',
    },
    gradient: {
      LABEL: 'Уклон обучения',
      physicist: 'Физмат',
      languages: 'Языки',
      musical: 'Музыкальный',
      sport: 'Спортивный',
      natural: 'Естественный',
    },
    payment: {
      LABEL: 'Форма собственности',
      ONLY_PRIVATE: 'Только для частных школ',
      private: 'Частная',
      state: 'Госсударственная',
      all: 'Все',
    },
    duration: {
      LABEL: 'Продолжительность',
      full: 'Полный день',
      half: 'Пол дня',
      boarding: 'Интернат',
    },
    tests: {
      LABEL: 'Рейтинг',
      minimal: 'Меньше 99 балов ВНО',
      low: '100 - 140 балов ВНО',
      middle: '141 - 170 балов ВНО',
      high: 'Вище 171 балов ВНО',
      none: 'Рейтинг неизвестен',
    },
    languageEducation: {
      LABEL: 'Язык обучения',
      ua: 'Украинский',
      en: 'Английский',
      de: 'Немецкий',
      fr: 'Фрранцузский',
    },
    languageForeign: {
      LABEL: 'Иностранный язык',
      en: 'Английский',
      de: 'Немецкий',
      fr: 'Французский',
      es: 'Испанский',
      pl: 'Польский',
      it: 'Итальянский',
    },
    price: {
      LABEL: 'Цена (грн / месяц)',
      price0: 'до 4,500 грн / месяц',
      price1: '4,501 - 9,000 грн / месяц',
      price2: '9,001 - 18,000 грн / месяц',
      price3: '18,001 - 36,000 грн / месяц',
      price4: 'от 36,001 грн / месяц',
      user: 'Цена, описание',
    },
    priceSmall: {
      LABEL: 'Цена (грн / месяц)',
      price0: '1 - 4,500',
      price1: '4,501 - 9,000',
      price2: '9,001 - 18,000',
      price3: '18,001 - 36,000',
      price4: 'от 36,001',
    },
    stateCategories: {
      LABEL: 'Вид учебного заведения',
      general: 'Общебразовательная',
      lyceum: 'Лицей',
      specialized: 'Специализированная',
      collegium: 'Коллегиум',
      grammar: 'Гимназия',
      nvk: 'Учебно-воспитательный комплекс',
      nightSchool: 'Вечерняя школа',
      alternative: 'Альтернативная школа',
      boardingSchool: 'Интернат',
      inclusion: 'Инклюзивность',
      tooltip: {
        lyceum:
          'Это школы с повышенным образовательным уровнем, открытые на базе профессиональных технических училищ',
        specialized:
          'Колледжи, как правило, предоставляют среднее специальное образование и строятся на базе профессиональных технических училищ и техникумов',
        collegium: '',
        grammar:
          'Гімназії дають підвищений освітній рівень і поглиблення в гуманітарну частину. ' +
          "Цей тип навчального закладу спрямований на обов'язкове продовження навчання " +
          'своїх випускників в освітніх організаціях вищого ступеня (університети, інститути)',
        nvk: '',
        alternative: '',
        nightSchool: '',
        boardingSchool: '',
      },
    },
    specialists: {
      LABEL: 'Специалисты',
      psychologist: 'Психолог',
      speechTherapist: 'Логопед',
      medical: 'Медицинский работник',
      tutor: 'Тьютор',
    },
    sections: {
      LABEL: 'Секции',
      it: 'IT технологии',
      robotics: 'Робототехника',
      speakingClub: 'Speaking Club',
      logic: 'Логика',
      music: 'Музыка',
      vocals: 'Вокал',
      handmade: 'Хэнд-мейд',
      projects: 'Проектная деятельность',
      theater: 'Театр',
      choreography: 'Хореография',
      martial: 'Боевые искусства',
      football: 'Футбол',
      volleyball: 'Волейбол',
      hockey: 'Хокей',
      yoga: 'Йога',
      skiing: 'Лыжи',
      gymnastics: 'Художественная гимнастика',
      aerialGymnastics: 'Воздушная гимнастика',
      basketball: 'Баскетбол',
      chess: 'Шахматы',
      tennis: 'Тенис',
      art: 'Творчество',
      financial: 'Финансовая грамотность',
      scienceClub: 'Научный клуб',
      swimming: 'Плавание',
    },
    addiditionalServices: {
      LABEL: 'Дополнительные услуги',
      preparingSchool: 'Подготовка к школе',
      preparingTests: 'Подготовка к ВНО',
      camp: 'Школьный лагерь',
    },
    ID: 'ID',
    GROUP: 'Группа',
    LOGO: 'Логотип',
    IMAGES: 'Изображение',
    geo: {
      LABEL: 'GPS координаты',
      LATITUDE: 'GPS latitude',
      LONGITUDE: 'GPS longitude',
    },
    languagesCreate: {
      UA: 'UA - Украинский',
      RU: 'RU - Русский',
    },
    TITLE: 'H1 - title',
    SHORT_TITLE: 'Short title',
    ADDRESS: 'Адресс',
    meta: {
      TITLE: 'meta - title',
      DESCRIPTION: 'meta - description',
    },
    visibility: {
      LABEL: 'Доступен всем',
      TRUE: 'Доступен всем',
    },
    banned: {
      LABEL: 'Забанен',
      TRUE: 'Забанен',
    },
    infrastructure: {
      LABEL: 'Инфраструктура',
      transfer: 'Трансфер',
      kitchen: 'Собственная кухня',
      catering: 'Кейтеринг',
      sportsArea: 'Спортивная площадка',
      playground: 'Игровая площадка',
      pool: 'Бассейн',
      shelter: 'Укритие',
      recreationArea: 'Зона отдыха',
      medicalOffice: 'Медицинский кабинет',
      computerClass: 'Компьютерный класс',
    },
    educationForm: {
      LABEL: 'Формат обучения',
      online: 'Online',
      onlineOnly: 'Только Online',
      offline: 'Offline',
      distance: 'Дистанционная',
      mixed: 'Смешанная',
    },
    prev: {
      PREV: 'Предыдущая',
      SCHOOLS: 'школа',
      PREV_SCHOOLS: 'Предыдущая школа',
    },
    next: {
      NEXT: 'Следующие',
      SCHOOLS: 'школы',
      NEXT_SCHOOLS: 'Следующие школы',
    },
    LEADING_SCHOOLS: 'Ведущие школы',
    CLOSEST_SCHOOLS: 'Ближайшие ведущие школы',
  },
}

export default lang
