// import { Buffer } from 'buffer'

// replace console.* for disable log on production
if (process.env.NODE_ENV !== 'development') {
  console.error = () => {}
  console.debug = () => {}
  console.warn = () => {}
}

window.global = window
// global.Buffer = Buffer
global.process = {
  // @ts-ignore
  env: { DEBUG: undefined },
  version: '',
  // eslint-disable-next-line global-require
  nextTick: require('next-tick'),
}

export {}
