import {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LanguageIcon,
  PhoneIcon,
  TelegramIcon,
  TwitterIcon,
  YouTubeIcon,
  WhatsAppIcon,
  ViberIcon,
} from '@icons'
import {
  TContactsOrder,
  IContacts,
  TContactsList,
  TContact,
  IContact,
  TContactIcon,
  IContactLink,
  TContactLinks,
} from './types'

const order: TContactsOrder = [
  'phone',
  'url',
  'email',
  'watsapp',
  'telegram',
  'viber',
  'facebook',
  'instagram',
  'youtube',
  'twitter',
]

export function getIcon(name: TContact): TContactIcon {
  let Icon: TContactIcon = null

  switch (name) {
    case 'email':
      Icon = EmailIcon
      break
    case 'facebook':
      Icon = FacebookIcon
      break
    case 'instagram':
      Icon = InstagramIcon
      break
    case 'phone':
      Icon = PhoneIcon
      break
    case 'watsapp':
      Icon = WhatsAppIcon
      break
    case 'telegram':
      Icon = TelegramIcon
      break
    case 'url':
      Icon = LanguageIcon
      break
    case 'viber':
      Icon = ViberIcon
      break
    case 'youtube':
      Icon = YouTubeIcon
      break
    case 'twitter':
      Icon = TwitterIcon
      break
    default:
      Icon = null
  }
  return () => {
    return Icon
  }
}

function getLinks(
  name: TContact,
  text: string,
  utmCampaign = 'object-card',
): IContactLink {
  let link = ''
  let className
  let itemProp
  const isHttps = text.indexOf('https://') !== -1
  const utmParams = `utm_source=schoolhub&utm_medium=referral&utm_campaign=${utmCampaign}`
  const utm = text.indexOf('?') === -1 ? `?${utmParams}` : `&${utmParams}`

  switch (name) {
    case 'email':
      link = `mailto:${text}`
      className = 'email'
      itemProp = 'email'
      break
    case 'facebook':
      link = !isHttps ? `https://www.facebook.com/${text}` : `${text}`
      link += utm
      break
    case 'instagram':
      link = !isHttps ? `https://www.instagram.com/${text}` : `${text}`
      link += utm
      break
    case 'phone':
      link = `tel:${text}`
      className = 'tel'
      itemProp = 'telephone'
      break
    case 'watsapp':
      link = !isHttps ? `https://wa.me/${text}` : `${text}`
      link += utm
      break
    case 'telegram':
      link = `tg://resolve/?domain=${text}`
      break
    case 'url':
      link = `${text}${utm}`
      className = 'url'
      itemProp = 'url'
      break
    case 'viber':
      link = `viber://chat/?number=${text}`
      break
    case 'youtube':
      link = !isHttps ? `https://www.youtube.com/c/${text}` : `${text}`
      link += utm
      break
    case 'twitter':
      link = `${text}`
      break
    default:
      link = ''
  }

  // @ts-ignore
  return { link, text, className, itemProp }
}

function normalizeContacts(contacts: IContacts, t: IContacts): TContactsList {
  return order.reduce((acc: TContactsList, cur: TContact) => {
    const value = contacts[cur]
    if (value) {
      const icon = getIcon(cur)
      const links: TContactLinks = value
        .split(', ')
        .map((item) => getLinks(cur, item))

      const contact: IContact = {
        name: cur,
        // @ts-ignore
        label: t[cur.toUpperCase()],
        icon,
        links,
      }
      // @ts-ignore
      acc.push(contact)
    }
    return acc
  }, [])
}

export function normalizeContactsList(
  contacts: IContacts = {},
  t: object = {},
): TContactsList {
  return order.reduce((acc: TContactsList, cur: TContact) => {
    const value = contacts[cur]
    if (value) {
      const icon = getIcon(cur)

      const contact: IContact = {
        name: cur,
        // @ts-ignore
        label: t[cur.toUpperCase()],
        icon,
      }
      // @ts-ignore
      acc.push(contact)
    }
    return acc
  }, [])
}

export default normalizeContacts
