import school from './school'
import schools from './schools'
import auth from './auth'
import home from './home'
import notFound from './notFound'
import services from './services'
import sitemap from './sitemap'
import statistic from './statistic'

const index = {
  ...home,
  ...school,
  ...schools,
  ...auth,
  ...notFound,
  ...services,
  ...sitemap,
  ...statistic,
}

export default index
