import { IStatistic } from '@i18n/locales/ua/components/statistic'

const lang: {
  statistic: IStatistic
} = {
  statistic: {
    users: {
      TRAFFIC: 'Количество уникальных посетителей',
      VIEWS: 'Количество просмотров страниц',
      VIEWS_RATIO: 'Среднее количество просмотров страниц за сеанс',
      USERS_FROM_UKRAINE: 'Количество пользователей из Украины',
      USERS_FROM_KYIV: 'Количество пользователей из Киева',
      MOBILE: 'Количество посещений с мобильных устройств',
      FEMALE: 'Большинство пользователей – женщины',
    },
    domain: {
      GOOGLE_INDEX: 'Индекс Google',
      BING_INDEX: 'Индекс Bing',
      AGE: 'Возраст домена',
      DR: 'Domain Rank',
      UR: 'URL Rank',
      TF: 'Trust Flow',
      CF: 'Citation Flow',
      DA: 'Domain Authority',
      PA: 'Page Authority',
      AS: 'Authority Score',
      SDR: 'Serpstat Domain Rank',
      DT: 'Domain Trust',
    },
    vendors: {
      AGE: '',
      GOOGLE_INDEX: 'google.com',
      BING_INDEX: 'bing.com',
      DR: 'ahrefs.com',
      UR: 'ahrefs.com',
      TF: 'majestic.com',
      CF: 'majestic.com',
      DA: 'moz.com',
      PA: 'moz.com',
      AS: 'semrush.com',
      SDR: 'serpstat.com',
      DT: 'seranking.com',
    },
    tooltip: {
      TRAFFIC:
        'Количество посетителей площадки в месяц. Источник данных: Google Analytics.',
      GOOGLE_INDEX: 'Количество страниц сайта в поисковой выдаче Google.',
      BING_INDEX: 'Количество страниц сайта в поисковой выдаче Bing.',
      AGE: 'Возраст домена согласно информации whois.',
      DR: 'Domain Rank (DR) - Оценка авторитета сайта на основе качества массы ссылок по данным Ahrefs.com. Чем больше тем лучше.',
      UR: 'Рейтинг URL-адреса (UR) показывает силу ссылочного профиля главной страницы по 100-бальной логарифмической шкале.',
      TF: 'Trust Flow (TF) - Оценка сайта на основе качества массы ссылок по данным majestic.com. Чем больше тем лучше.',
      CF: 'Citation Flow (CF) - Оценка домена на основе количества обратных ссылок и упоминаний по данным majestic.com.',
      DA: 'Domain Authority (DA) – Авторитет (траст) по данным moz.com. Измеряется от 1 до 100.',
      PA: 'Page Authority (PA) – Авторитет страницы (траста) по данным moz.com. Измеряется от 1 до 100.',
      AS: 'Authority Score (AS) - Оценка авторитетности по данным метрика Semrush.com, которая измеряет репутацию домена. Он учитывает количество и качество своих обратных ссылок, органический поисковый трафик и общую подлинность своего профиля.',
      SDR: 'Serpstat Domain Rank (SDR) — Показатель авторитетности домена по шкале от 0 до 100. Зависит от количества сайтов, ссылающихся на анализируемый домен, а также от количества сайтов, ссылающихся на эти ссылающиеся сайты. Таким образом, учитываются все сайты в индексе, связанные с рассматриваемым доменом. Обновляется ежемесячно.',
      DT: 'Domain Trust – Доверие к домену по данным SE Ranking. Измеряется от 1 до 100.',
    },
    charts: {
      countries: {
        TITLE: 'Страны посещения пользователями',
        OTHER: 'Другие страны',
        TOTAL: 'Всего посетителей',
        ukraine: 'Украина',
        germany: 'Германия',
        poland: 'Польша',
        unitedStates: 'США',
        russia: 'россия',
      },
    },
  },
}

export default lang
