import { combineReducers } from 'redux'

import authReducer, {
  initialState as initialAuth,
} from '@hoocs/useAuth/modules'
import userReducer, {
  initialState as initialUser,
} from '@features/User/modules'
import snackbarReducer, {
  initialState as initialSnackbar,
} from '@containers/Snackbar/modules'
import searchReducer, {
  initialState as initialSearch,
} from '@features/Search/modules'
import drawerReducer, { initialState as initialDrawer } from '@drawers/modules'
import dialogReducer, { initialState as initialDialog } from '@dialogs/modules'
import contentReducer, {
  initialState as initialContent,
} from '@hoocs/useFetchContent/modules'
import versionsReducer, {
  initialState as initialVersions,
} from '@features/Versions/modules'
import lightboxReducer, {
  initialState as initialLightbox,
} from '@features/Lightbox/modules'
import favoritesReducer, {
  initialState as initialFavorites,
} from '@features/Favorites/modules'
import groupsReducer, {
  initialState as initialGroups,
} from '@hoocs/useGroups/modules'
import articlesReducer, {
  initialState as initialArticles,
} from '@hoocs/useFetchArticles/modules'

import type { IState as ISnackbar } from '@containers/Snackbar/types'
import type { IState as IUser } from '@features/User/types'
import type { IState as IAuth } from '@hoocs/useAuth/types'
import type { IState as ISearch } from '@features/Search/types'
import type { IState as IDrawer } from '@drawers/types'
import type { IState as IDialog } from '@dialogs/types'
import type { IState as IContent } from '@hoocs/useFetchContent/types'
import type { IState as IVersions } from '@features/Versions/types'
import type { IState as ILightbox } from '@features/Lightbox/types'
import type { IState as IFavorites } from '@features/Favorites/types'
import type { IState as IGroups } from '@hoocs/useGroups/types'
import type { IState as IArticles } from '@hoocs/useFetchArticles/types'

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  snackbar: snackbarReducer,
  search: searchReducer,
  drawer: drawerReducer,
  dialog: dialogReducer,
  content: contentReducer,
  versions: versionsReducer,
  lightbox: lightboxReducer,
  favorites: favoritesReducer,
  groups: groupsReducer,
  articles: articlesReducer,
})

export interface IStore {
  auth: IAuth
  user: IUser
  snackbar: ISnackbar
  search: ISearch
  drawer: IDrawer
  dialog: IDialog
  content: IContent
  versions: IVersions
  lightbox: ILightbox
  favorites: IFavorites
  groups: IGroups
  articles: IArticles
}

export const initialState: IStore = {
  auth: initialAuth,
  user: initialUser,
  snackbar: initialSnackbar,
  search: initialSearch,
  drawer: initialDrawer,
  dialog: initialDialog,
  content: initialContent,
  versions: initialVersions,
  lightbox: initialLightbox,
  favorites: initialFavorites,
  groups: initialGroups,
  articles: initialArticles,
}
