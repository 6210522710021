export interface IStatisticContent {
  headers: { [key in string]: string }
  paragraphs: {
    NUMBER_OF_USERS: (number: string) => string
    STATISTIC: string
    DOMAIN: string
    USERS_VISITORS: string
  }
}

export interface IStatisticPage {
  meta: {
    TITLE_SHORT: string
    TITLE: string
    DESCRIPTION: string
  }
  content: IStatisticContent
}

const lang: {
  statistic: IStatisticPage
} = {
  statistic: {
    meta: {
      TITLE_SHORT: 'Статистика сайту SchoolHub',
      TITLE: 'Статистика відвідування сайту',
      DESCRIPTION:
        'Статистика відвідування сайту SchoolHub: ключові метрики, профіль користувачів та активність нашої платформи',
    },
    content: {
      headers: {
        STATISTIC: 'Статистика SchoolHub',
        USERS_VISITORS: 'Статистика відвідування сайту',
        DOMAIN: 'Параметри сайту',
      },
      paragraphs: {
        STATISTIC:
          'На цій сторінці ви знайдете ключові статистичні показники нашого сайту, які допоможуть вам краще розуміти, як працює SchoolHub і яким користувачам ми спрямовані. Ці дані допоможуть вам приймати більш обґрунтовані рішення та розвивати вашу школу на нашій платформі.',
        // @ts-ignore
        NUMBER_OF_USERS: (number: string) =>
          `Більше ${number} користувачів за місяць`,
        DOMAIN:
          'Ключові параметри нашого сайту можуть бути частково передані вашому сайту, коли ви розміщуєте посилання на нього зі SchoolHub з функцією Dofollow. Ці параметри можуть впливати на SEO вашого веб-сайту та покращувати вашу видимість у пошукових системах.',
        USERS_VISITORS:
          'Комплексна інформація про активність користувачів на SchoolHub, включаючи кількість відвідувань, профіль користувачів, та інші ключові метрики, які допомагають краще розуміти нашу аудиторію.',
      },
    },
  },
}

export default lang
