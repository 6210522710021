export default ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action?.constructor?.name === 'Promise') {
      action
        .then(
          (result: any) => dispatch(result),
          (error: any) => dispatch({ type: 'ERROR', error }),
        )
        .catch((error: any) => dispatch({ type: 'ERROR', error }))
    } else {
      next(action)
    }
  }
