import appBar from './appBar'
import actions from './actions'
import actionDiscounts from './actionDiscounts'
import company from './company'
import contacts from './contacts'
import groups from './groups'
import favorite from './favorite'
import footer from './footer'
import form from './form'
import location from './location'
import pagination from './pagination'
import snackbars from './snackbars'
import statistic from './statistic'
import user from './user'
import validations from './validations'
import vip from './vip'

const index = {
  ...appBar,
  ...actions,
  ...actionDiscounts,
  ...company,
  ...contacts,
  ...groups,
  ...favorite,
  ...footer,
  ...form,
  ...location,
  ...pagination,
  ...snackbars,
  ...statistic,
  ...user,
  ...validations,
  ...vip,
}

export default index
