import React, { ReactElement } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import type { CircularProgressProps } from '@mui/material/CircularProgress'

function CircularProgressWithLabel({
  value,
  className,
  ...selfProps
}: CircularProgressProps & {
  value: number
  className?: string
}): ReactElement {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={value}
        className={className}
        {...selfProps}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
