const lang = {
  notFound: {
    TITLE: 'Страница не найдена',
    meta: {
      TITLE: '404 — Страница не найдена',
      DESCRIPTION:
        'Перейдите на главную страницу для поиска необходимой информации',
    },
  },
}

export default lang
