import { TError as TErrorData } from '@services/handleError'

export type TNotificationsName = 'compare' | 'favorite' | 'notifications'
export type TError = TErrorData
export type TVersions = Object
export type TVersion = Object

export interface IState {
  isReady: boolean
  data: Object
  error: TError
}

export const REVOKE = 'versions/REVOKE'
export type TRevokeAction = {
  type: 'versions/REVOKE'
}

export const CLEAR_ERROR = 'versions/CLEAR_ERROR'
export interface IClearErrorAction {
  type: 'versions/CLEAR_ERROR'
  payload: TError
}

export const SET_VERSION_STARTED = 'versions/SET_VERSION_STARTED'
export interface ISetVersionStartAction {
  type: 'versions/SET_VERSION_STARTED'
}

export const SET_VERSION_FINISHED = 'versions/SET_VERSION_FINISHED'
export interface ISetVersionFinishedAction {
  type: 'versions/SET_VERSION_FINISHED'
  payload: TVersion
}

export const SET_VERSION_FAILED = 'versions/SET_VERSION_FAILED'
export interface ISetVersionFailedAction {
  type: 'versions/SET_VERSION_FAILED'
  payload: TError
}

export type TActionHandler =
  | TRevokeAction
  | IClearErrorAction
  | ISetVersionStartAction
  | ISetVersionFinishedAction
  | ISetVersionFailedAction

export interface IHook {
  isReady: boolean
  versions: TVersions
  setVersion: (version: TVersion) => void
  getVersions: () => void
}
