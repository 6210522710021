import useMediaQuery from '@mui/material/useMediaQuery'
import json2mq from 'json2mq'
import { useTheme } from '@theme'
import { TBreakpoint } from '@stylesConstants'

export default useMediaQuery

interface IBreakpoint {
  name: TBreakpoint
  val: boolean
}

type TBreakpoints = Array<IBreakpoint>

// const mapBreakpoint = (breakpoint: TBreakpoint): number => {
//   // @ts-ignore
//   const breakKey: TBreakpoint =
//     Object.keys(constants.breakpoints).find((key) => key === breakpoint) || 'xs'
//
//   return constants.breakpoints[breakKey]
// }

export const useBreakpointUp = (breakpoint: TBreakpoint): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const useBreakpointDown = (breakpoint: TBreakpoint): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down(breakpoint))
}

export const useBreakpointBetween = (
  breakpointFrom: TBreakpoint,
  breakpointTo: TBreakpoint,
): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.between(breakpointFrom, breakpointTo))
}

export const useBreakpointOnly = (breakpoint: TBreakpoint): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint))
}

export const useBreakpointIsPortrait = (): boolean => {
  return useMediaQuery(json2mq({ orientation: 'portrait' }))
}

export const useBreakpointIsLandscape = (): boolean => {
  return useMediaQuery(json2mq({ orientation: 'landscape' }))
}

export const useBreakpointIsRetina = (): boolean => {
  return useMediaQuery(json2mq({ resolution: '2dppx' }))
}

export const useBreakpointGetCurrent = (): TBreakpoint => {
  const xs = useBreakpointOnly('xs')
  const sm = useBreakpointOnly('sm')
  const md = useBreakpointOnly('md')
  const lg = useBreakpointOnly('lg')
  const xl = useBreakpointOnly('xl')
  const breakpoints: TBreakpoints = [
    { name: 'xs', val: xs },
    { name: 'sm', val: sm },
    { name: 'md', val: md },
    { name: 'lg', val: lg },
    { name: 'xl', val: xl },
  ]

  return breakpoints.filter((item) => item.val)?.[0]?.name
}

export const useIsMobile = (): boolean => {
  return useBreakpointOnly('xs')
}

export const useIsTablet = (): boolean => {
  const sm = useBreakpointOnly('sm')
  const md = useBreakpointOnly('md')

  return sm || md
}

export const useIsDesktop = (): boolean => {
  const lg = useBreakpointOnly('lg')
  const xl = useBreakpointOnly('xl')

  return lg || xl
}
