import { IStatisticPage } from '@i18n/locales/ua/pages/statistic'

const lang: {
  statistic: IStatisticPage
} = {
  statistic: {
    meta: {
      TITLE_SHORT: 'Статистика сайта SchoolHub',
      TITLE: 'Статистика посещения сайта',
      DESCRIPTION:
        'Статистика посещения сайта SchoolHub: ключевые метрики, профиль пользователей и активность нашей платформы.',
    },
    content: {
      headers: {
        STATISTIC: 'Статистика SchoolHub',
        USERS_VISITORS: 'Статистика посещения сайта',
        DOMAIN: 'Параметры сайта',
      },
      paragraphs: {
        STATISTIC:
          'На этой странице вы найдете ключевые статистические показатели нашего сайта, которые помогут вам лучше понять, как работает SchoolHub и каким пользователям мы направлены. Эти данные помогут вам принимать более обоснованные решения и развивать вашу школу на нашей платформе.',
        // @ts-ignore
        NUMBER_OF_USERS: (number: string) =>
          `Более ${number} пользователей в месяц`,
        DOMAIN:
          'Ключевые параметры нашего сайта могут быть частично переданы вашему сайту, когда вы размещаете ссылку на него из SchoolHub с функией Dofollow. Эти параметры могут влиять на SEO вашего веб-сайта и улучшать вашу видимость в поисковых системах.',
        USERS_VISITORS:
          'Комплексная информация об активности пользователей на SchoolHub, включая количество посещений, профиль пользователей и другие ключевые метрики, которые помогают лучше понимать нашу аудиторию.',
      },
    },
  },
}

export default lang
