import login from './login'
import register from './register'
import resend from './resend'
import finishSignup from './finishSignup'

const index = {
  auth: {
    login,
    register,
    resend,
    finishSignup,
  },
}

export default index
