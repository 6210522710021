const lang = {
  TITLE: 'Зареєструватися',
  SUBHEADER: 'Створити нового користувача',
  FIRST_NAME: `Ім'я`,
  LAST_NAME: 'Прізвище',
  DISPLAY_NAME: ``,
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  CONFIRM_PASSWORD: 'Підтвердження',
  RESEND: 'Забули пароль?',
  LOGIN: 'Ввійти',
  SUBMIT: 'Зареєструватись',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  ROLE_USER: 'Батьки учня',
  ROLE_INSTITUTION: 'Навчальні заклади',
}

export default lang
