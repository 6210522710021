const lang = {
  sitemap: {
    all: {
      meta: {
        TITLE: 'Карта сайту',
        DESCRIPTION: 'Карта сайту. Школи',
      },
      text: {
        H1: 'Карта сайту',
        HOME: 'Головна сторінка',
        SCHOOLS: 'Школи',
        KYIVSKA: 'Київська обл',
        KYIV: 'м. Київ',
      },
    },
  },
}

export default lang
