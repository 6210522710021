const lang = {
  home: {
    meta: {
      TITLE: 'Школы Киева — список, фото, условия, контакты',
      DESCRIPTION:
        'Школы Киева — информация, специализация, фото, адрес, телефон, рейтинг ВНО, условия приема, особенности, форма образования',
    },
    text: {
      promo: {
        H1: 'Найди свою школу',
        D1: 'Только достоверенная и проверенная информация',
        D2: 'Список постоянно обновляется и дополняется',
        BUTTON_1: 'Школы Киева',
        BUTTON_2: 'Школы Киевской области',
      },
      features: {
        H2: 'Преимущества SchoolHub',
        ACTUAL_H3: 'Актуальность информации',
        ACTUAL_P: 'Вся информация проверяется в ручную',
        COUNT_H3: 'Количество информации',
        COUNT_P: (count: number): string =>
          `К выбору доступно более ${count} школ в Киеве и области`,
        FAVORITE_H3: 'Избранные школы',
        FAVORITE_P:
          'После регистрации вы сможете составить свой список «Избранных школ»',
        SEARCH_H3: 'Удобный поиск',
        SEARCH_P: 'Подбирайте учебные заведения по параметрам, важным для вас',
        PAYMENT_H3: 'Частные школы',
        PAYMENT_P: 'Больше возможностей при подборе частной школы',
        MAP_H3: 'Расположение на карте',
        MAP_P: 'Оцените удобство расположения учебного заведения на карте',
      },
      recommendations: {
        H2: 'Рекомендованые учебные заведения',
      },
      actions: {
        ACTION_75: 'Акция скидка до 75%',
      },
    },
  },
}

export default lang
