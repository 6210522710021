const lang = {
  TITLE: 'Активувати email',
  DESCRIPTION: 'Будь ласка перевірте свій email і оновіть сторінку',
  RESEND: 'Скиньте email',
  LOGIN: 'Ввійти як інший користувач',
  ACTIVATED: 'Користувач вже активований',
  HOME: 'На головну сторінку',
  AUTHORIZED: 'Ви вже ввійшли як користувач',
  RELOAD: 'Оновити',
}

export default lang
