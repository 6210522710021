import React, { ReactElement } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

function FacebookDefaultIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" width="24" height="24">
      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    </SvgIcon>
  )
}

export default FacebookDefaultIcon
