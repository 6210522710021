import { ThunkAction } from 'redux-thunk'

export default ({ dispatch }: any) =>
  (next: any) =>
  (action: ThunkAction<any, any, any, any>) => {
    if (action instanceof Array) {
      // eslint-disable-next-line no-restricted-syntax
      for (const _action of action) {
        try {
          dispatch(_action)
        } catch (error) {
          dispatch({ type: 'ERROR', error })
          throw error
        }
      }
    } else {
      next(action)
    }
  }
