export interface IPagination {
  NAV: string
  PREV: string
  NEXT: string
  PAGE: (page?: number | null) => string
  CURRENT: (page?: number | null) => string
}

const lang: { pagination: IPagination } = {
  pagination: {
    NAV: 'Навигация по страницам',
    PREV: 'Предыдущая страница',
    NEXT: 'Следующая страница',
    PAGE: (page) => (page ? `Страница ${page}` : 'Страницы'),
    CURRENT: (page) =>
      page ? `Текущая страница, страница ${page}` : 'Текущая страница',
  },
}

export default lang
