import { db } from '@services/firebase'
import type {
  IDatabase,
  IDataSnapshot,
  IReference,
  IEventType,
  IThenableReference,
} from '@services/firebase'
import { getUUID } from '@services/common'

export type TRefValueString = string
export type TCallback = (a: IDataSnapshot, b?: string | null | undefined) => any
export type TKey = string
type TRefValue = IReference | TRefValueString | null
type TOnComplete = (a: Error | null) => any
type TLimit = number
type TPriority = number
type TTransactionUpdate = (a: any) => any
type TData = Object
type TListenerType = 'on' | 'once'

interface IListenerItem {
  key: TKey
  type: TListenerType
  callback: TCallback
}
interface IListener {
  [ref: string]: {
    // TRefValueString
    [value in IEventType]: Array<IListenerItem>
  }
}

class Db {
  _refValue: TRefValue

  _db: IDatabase

  _listeners: IListener

  constructor() {
    this._refValue = null
    this._db = db
    this._listeners = {} as IListener
  }

  _off = (refValue: TRefValue, value: IEventType, callback?: TCallback) => {
    this.getRef(refValue).off(value, callback)
  }

  _on = (refValue: TRefValue, value: IEventType, callback: TCallback) => {
    this.getRef(refValue).on(value, callback)
  }

  _once = (refValue: TRefValue, value: IEventType, callback: TCallback) => {
    this.getRef(refValue)
      .once(value)
      .then((dataSnapshot: IDataSnapshot) => {
        callback(dataSnapshot)
      })
  }

  _onComplete = (
    refValue: TRefValueString,
    value: IEventType,
    dataSnapshot: IDataSnapshot,
  ) => {
    const list = this._listeners[refValue][value] || []

    list.forEach((item) => {
      item.callback(dataSnapshot)
      if (item.type === 'once') {
        this.off(refValue, value, item.key, () => {})
      }
    })
  }

  off = (
    refValue: TRefValueString,
    value: IEventType,
    key: TKey,
    callback?: TCallback,
  ) => {
    const list = this._listeners[refValue][value] || []
    this._listeners[refValue][value] = list.filter((item) => item.key !== key)

    if (!this._listeners[refValue][value].length) {
      this._off(refValue, value, callback)
    }
  }

  on = (
    refValue: TRefValueString,
    value: IEventType,
    callback: TCallback,
    key: TKey = getUUID(),
    type: TListenerType = 'on',
  ) => {
    const ref = this._getRefValue(refValue)

    if (typeof ref === 'string') {
      if (!this._listeners[ref]) {
        // @ts-ignore
        this._listeners[ref] = {}
        this._listeners[ref][value] = []
      } else if (!this._listeners[ref][value]) {
        this._listeners[ref][value] = []
      }

      this._listeners[ref][value].push({ key, type, callback })

      const on = type === 'once' ? this._once : this._on

      if (this._listeners[ref][value].length === 1) {
        on(ref, value, (dataSnapshot: IDataSnapshot) => {
          this._onComplete(ref, value, dataSnapshot)
        })
      }
    }
  }

  once = (
    refValue: TRefValueString,
    value: IEventType,
    callback: TCallback,
    key: TKey = getUUID(),
  ) => {
    this.on(refValue, value, callback, key, 'once')
  }

  get = (refValue: TRefValue, value: IEventType = 'value') => {
    return this.getRef(refValue).once(value)
  }

  _getRefValue = (refValue: TRefValue = null): TRefValue => {
    return refValue === null ? this._refValue : refValue
  }

  getRef = (refValue?: TRefValue): IReference => {
    const ref = this._getRefValue(refValue)
    let result

    if (typeof ref === 'string') {
      result = this._db.ref(ref)
    } else if (ref !== null) {
      result = ref
    } else {
      throw Error(`No Ref with value: ${refValue}`)
    }

    return result
  }

  setRef = (refValue: TRefValue) => {
    this._refValue = refValue
  }

  child = (refValue: TRefValue, value: IEventType) => {
    this.getRef(refValue).child(value)
  }

  endAt = (refValue: TRefValue, value: IEventType) => {
    this.getRef(refValue).endAt(value)
  }

  equalTo = (refValue: TRefValue, value: IEventType) => {
    this.getRef(refValue).equalTo(value)
  }

  isEqual = (refValue: TRefValue, otherRefValue: TRefValue) => {
    const other = this.getRef(otherRefValue)
    this.getRef(refValue).isEqual(other)
  }

  limitToFirst = (refValue: TRefValue, limit: TLimit) => {
    this.getRef(refValue).limitToFirst(limit)
  }

  limitToLast = (refValue: TRefValue, limit: TLimit) => {
    this.getRef(refValue).limitToLast(limit)
  }

  onDisconnect = (refValue: TRefValue = null) => {
    return this.getRef(refValue).onDisconnect()
  }

  orderByChild = (refValue: TRefValue, value: IEventType) => {
    this.getRef(refValue).orderByChild(value)
  }

  orderByKey = (refValue: TRefValue) => {
    this.getRef(refValue).orderByKey()
  }

  orderByPriority = (refValue: TRefValue) => {
    this.getRef(refValue).orderByPriority()
  }

  orderByValue = (refValue: TRefValue) => {
    this.getRef(refValue).orderByValue()
  }

  push = (
    refValue: TRefValue,
    value?: IEventType,
    onComplete?: TOnComplete,
  ): IThenableReference => {
    return this.getRef(refValue).push(value, onComplete)
  }

  remove = (refValue: TRefValue, onComplete: TOnComplete) => {
    return this.getRef(refValue).remove(onComplete)
  }

  set = (refValue: TRefValue, value: IEventType, onComplete: TOnComplete) => {
    return this.getRef(refValue).set(value, onComplete)
  }

  setPriority = (
    refValue: TRefValue,
    priority: TPriority,
    onComplete: TOnComplete,
  ) => {
    return this.getRef(refValue).setPriority(priority, onComplete)
  }

  setWithPriority = (
    refValue: TRefValue,
    value: IEventType,
    priority: TPriority,
    onComplete: TOnComplete,
  ) => {
    return this.getRef(refValue).setWithPriority(value, priority, onComplete)
  }

  startAt = (refValue: TRefValue, value: IEventType) => {
    return this.getRef(refValue).startAt(value)
  }

  toJSON = (refValue: TRefValue) => {
    return this.getRef(refValue).toJSON()
  }

  toString = (refValue: TRefValue) => {
    return this.getRef(refValue).toString()
  }

  transaction = (
    refValue: TRefValue,
    transactionUpdate: TTransactionUpdate,
    onComplete: TOnComplete = () => {},
  ) => {
    return this.getRef(refValue).transaction(transactionUpdate, onComplete)
  }

  update = (
    refValue: TRefValue,
    value: TData,
    onComplete?: TOnComplete,
  ): Promise<any> => {
    return this.getRef(refValue).update(value, onComplete)
  }
}

export default new Db()
