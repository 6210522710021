import {
  firestore,
  IQueryDocumentSnapshot,
  ISnapshotOptions,
} from '@services/firebase'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type { TLocale } from '@i18n'
import { TCategory } from '@routers'
import {
  FETCH_CONTENT_START,
  FETCH_CONTENT_FINISHED,
  FETCH_CONTENT_FAILED,
  FETCH_IMAGES_START,
  FETCH_IMAGES_FINISHED,
  FETCH_IMAGES_FAILED,
  REVOKE,
} from './types'
import type {
  IState,
  TContentData,
  TError,
  IRevokeAction,
  IFetchContentStartAction,
  IFetchContentFinishedAction,
  IFetchContentFailedAction,
  IFetchImagesStartAction,
  IFetchImagesFinishedAction,
  IFetchImagesFailedAction,
  TActionHandler,
} from './types'
import { TImages } from '@utils/types/image'
import { ISchoolDatabase } from '@pages/School/types'

export const initialState: IState = {
  isReady: false,
  data: {},
}

function _fetchContentStart(): IFetchContentStartAction {
  return {
    type: FETCH_CONTENT_START,
  }
}

function _fetchContentFinished(
  data: TContentData,
): IFetchContentFinishedAction {
  return {
    type: FETCH_CONTENT_FINISHED,
    payload: data,
  }
}

function _fetchContentFailed(error: TError): IFetchContentFailedAction {
  return {
    type: FETCH_CONTENT_FAILED,
    payload: error,
  }
}

const converterRu = {
  toFirestore(content: any) {
    return content
  },
  fromFirestore(snapshot: IQueryDocumentSnapshot, options: ISnapshotOptions) {
    const { ru, ua, ...data } = snapshot.data(options)
    return {
      ...ru,
      ...data,
    }
  },
}

const converterUa = {
  toFirestore(content: any) {
    return content
  },
  fromFirestore(snapshot: IQueryDocumentSnapshot, options: ISnapshotOptions) {
    const { ru, ua, ...data } = snapshot.data(options)
    return {
      ...ua,
      ...data,
    }
  },
}

export const fetchContent =
  (
    id: string,
    category: TCategory,
    lang?: TLocale,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchContentStart())
      let schoolsRef = firestore.collection(category).doc(id)
      if (lang === 'ua') {
        schoolsRef = schoolsRef.withConverter(converterUa)
      } else if (lang === 'ru') {
        schoolsRef = schoolsRef.withConverter(converterRu)
      }
      const doc = await schoolsRef.get()
      const data = doc.data() as ISchoolDatabase
      dispatch(_fetchContentFinished({ [id]: data }))
    } catch (error) {
      dispatch(_fetchContentFailed(error))
    }
  }

export const fetch =
  (
    id: string,
    category: TCategory,
    callback: (data: ISchoolDatabase) => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchContentStart())
      const schoolsRef = firestore.collection(category).doc(id)
      const doc = await schoolsRef.get()
      const data = doc.data() as ISchoolDatabase
      callback(data)
      dispatch(_fetchContentFinished({ [id]: data }))
    } catch (error) {
      dispatch(_fetchContentFailed(error))
    }
  }

function _fetchImagesStart(): IFetchImagesStartAction {
  return {
    type: FETCH_IMAGES_START,
  }
}

function _fetchImagesFinished(data: {
  id: string
  images: TImages
}): IFetchImagesFinishedAction {
  return {
    type: FETCH_IMAGES_FINISHED,
    payload: data,
  }
}

function _fetchImagesFailed(error: TError): IFetchImagesFailedAction {
  return {
    type: FETCH_IMAGES_FAILED,
    payload: error,
  }
}

export const fetchImages =
  (
    id: string,
    category: TCategory,
    callback: (data: TImages) => void,
  ): ThunkAction<any, any, any, any> =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
      dispatch(_fetchImagesStart())
      const schoolsRef = firestore.collection(category).doc(id)
      const doc = await schoolsRef.get()
      const data = doc.data() as ISchoolDatabase
      const images = data.images || []

      callback(images)

      dispatch(_fetchImagesFinished({ id, images }))
    } catch (error) {
      dispatch(_fetchImagesFailed(error))
    }
  }

export function revokeContent(): IRevokeAction {
  return {
    type: REVOKE,
  }
}

const ACTION_HANDLERS = {
  [FETCH_CONTENT_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_CONTENT_FINISHED]: (
    state: IState,
    action: IFetchContentFinishedAction,
  ) => ({
    ...state,
    isReady: true,
    data: {
      ...state.data,
      ...action.payload,
    },
    error: null,
  }),
  [FETCH_CONTENT_FAILED]: (
    state: IState,
    action: IFetchContentFailedAction,
  ) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [FETCH_IMAGES_START]: (state: IState) => ({
    ...state,
    isReady: true,
    error: null,
  }),
  [FETCH_IMAGES_FINISHED]: (
    state: IState,
    action: IFetchImagesFinishedAction,
  ) => {
    const { id, images } = action.payload
    return {
      ...state,
      isReady: true,
      data: {
        ...state.data,
        ...action.payload,
        [id]: {
          ...state.data[id],
          images,
        },
      },
      error: null,
    }
  },
  [FETCH_IMAGES_FAILED]: (state: IState, action: IFetchImagesFailedAction) => ({
    ...state,
    isReady: true,
    error: action.payload,
  }),
  [REVOKE]: initialState,
}

export default function contentReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
