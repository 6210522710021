const lang = {
  school: {
    level: {
      LABEL: 'Акредитація',
      preschool: 'Дошкільна освіта',
      elementary: 'I-го ступеня',
      middle: 'II-го ступеня',
      high: 'III-го ступеня',
      full: 'Всі (I-III ступенів)',
    },
    gradient: {
      LABEL: 'Нахил навчання',
      physicist: 'Фізмат',
      languages: 'Мови',
      musical: 'Музична',
      sport: 'Спортивний',
      natural: 'Природничі',
    },
    payment: {
      LABEL: 'Форма власності',
      ONLY_PRIVATE: 'Тільки для приватних школ',
      private: 'Приватна',
      state: 'Державна',
      all: 'Всі',
    },
    duration: {
      LABEL: 'Тривалість',
      full: 'Повний день',
      half: 'Пів дня',
      boarding: 'інтернат',
    },
    tests: {
      LABEL: 'Рейтинг',
      minimal: 'Менше 99 балів ЗНО',
      low: '100 - 140 балів ЗНО',
      middle: '141 - 170 балів ЗНО',
      high: 'Вище 171 балів ЗНО',
      none: 'Рейтинг невідомий',
    },
    languageEducation: {
      LABEL: 'Мова навчання',
      ua: 'Українська',
      en: 'Англійська',
      de: 'Німецька',
      fr: 'Французька',
    },
    languageForeign: {
      LABEL: 'Іноземна мова',
      en: 'Англійська',
      de: 'Німецька',
      fr: 'Французька',
      es: 'Іспанська',
      pl: 'Польська',
      it: 'Італійська',
    },
    price: {
      LABEL: 'Ціна (грн / місяць)',
      price0: 'до 4,500грн / місяць',
      price1: '4,501 - 9,000 грн / місяць',
      price2: '9,001 - 18,000 грн / місяць',
      price3: '18,001 - 36,000 грн / місяць',
      price4: 'від 36,001 грн / місяць',
      user: 'Ціна, опис',
    },
    priceSmall: {
      LABEL: 'Ціна (грн / місяць)',
      price0: '1 - 4,500',
      price1: '4,501 - 9,000',
      price2: '9,001 - 18,000',
      price3: '18,001 - 36,000',
      price4: 'від 36,001',
    },
    stateCategories: {
      LABEL: 'Вид навчального закладу',
      general: 'Загальноосвітня',
      lyceum: 'Ліцей',
      specialized: 'Спеціалізована',
      collegium: 'Колегіум',
      grammar: 'Гімназія',
      nvk: 'Навчально-виховний комплекс',
      nightSchool: 'Вечірня школа',
      alternative: 'Альтернативна школа',
      boardingSchool: 'Інтернат',
      inclusion: 'Інклюзивність',
      tooltip: {
        lyceum:
          'Это школы с повышенным образовательным уровнем, открытые на базе профессиональных технических училищ',
        specialized:
          'Колледжи, как правило, предоставляют среднее специальное образование и строятся на базе профессиональных технических училищ и техникумов',
        collegium: '',
        grammar:
          'Гімназії дають підвищений освітній рівень і поглиблення в гуманітарну частину. ' +
          "Цей тип навчального закладу спрямований на обов'язкове продовження навчання " +
          'своїх випускників в освітніх організаціях вищого ступеня (університети, інститути)',
        nvk: '',
        alternative: '',
        nightSchool: '',
        boardingSchool: '',
      },
    },
    specialists: {
      LABEL: 'Спеціалісти',
      psychologist: 'Психолог',
      speechTherapist: 'Логопед',
      medical: 'Медичний працівник',
      tutor: 'Тьютор',
    },
    sections: {
      LABEL: 'Секції',
      it: 'Інформаційні технології (ІТ)',
      robotics: 'Робототехніка', //  Конструювання
      speakingClub: 'Speaking Club',
      logic: 'Логіка',
      music: 'Музика',
      vocals: 'Вокал',
      handmade: 'Хэнд-мейд',
      projects: 'Проектна діяльність',
      theater: 'Театр',
      choreography: 'Хореографія',
      martial: 'Бойові мистецтва',
      football: 'Футбол',
      volleyball: 'Волейбол',
      hockey: 'Хокей',
      yoga: 'Йога',
      skiing: 'Лижі',
      gymnastics: 'Художня гімнастика',
      aerialGymnastics: 'Повітрянна гімнастика',
      basketball: 'Баскетбол',
      chess: 'Шахи',
      tennis: 'Теніс',
      art: 'Творчість',
      financial: 'Фінансова грамотність',
      scienceClub: 'Науковий клуб',
      swimming: 'Плавання',
    },
    addiditionalServices: {
      LABEL: 'Додаткові послуги',
      preparingSchool: 'Підготовка до школи',
      preparingTests: 'Підготовка до ЗНО',
      camp: 'Шкільний Табір',
    },
    ID: 'ID',
    KOATUU_ID: 'edbo.gov.ua ID',
    PLACE_ID: 'Place ID',
    PLACE_EMPTY: 'Не знайдено place id',
    GROUP: 'Група',
    LOGO: 'Логотип',
    IMAGES: 'Зображення',
    geo: {
      LABEL: 'GPS координати',
      LATITUDE: 'GPS latitude',
      LONGITUDE: 'GPS longitude',
    },
    languagesCreate: {
      UA: 'UA - Українська',
      RU: 'RU - Російська',
    },
    TITLE: 'H1 - title',
    SHORT_TITLE: 'Short title',
    ADDRESS: 'Адреса',
    meta: {
      TITLE: 'meta - title',
      DESCRIPTION: 'meta - description',
    },
    visibility: {
      LABEL: 'Доступен всім',
      TRUE: 'Доступен всім',
    },
    banned: {
      LABEL: 'Забанен',
      TRUE: 'Забанен',
    },
    IMPORT: 'Імпортувати',
    IMPORT_PHOTOS: 'Загрузити фото',
    IMPORT_ID: 'Імпортувати з ID',
    ITEM_NUMBER: 'Номер обʼєкту (сортування)',
    infrastructure: {
      LABEL: 'Інфраструктура',
      transfer: 'Трансфер',
      kitchen: 'Власна кухня',
      catering: 'Кейтерінг',
      sportsArea: 'Спорт майданчик',
      playground: 'Ігровий майданчик',
      pool: 'Басейн',
      shelter: 'Укриття',
      recreationArea: 'Зона для відпочинку',
      medicalOffice: 'Медичний кабінет',
      computerClass: 'Компьютерний клас',
    },
    educationForm: {
      LABEL: 'Формат навчання',
      online: 'Online',
      onlineOnly: 'Лише Online',
      offline: 'Offline',
      distance: 'Дистанційна',
      mixed: 'Змішана',
    },
    prev: {
      PREV: 'Попередня',
      SCHOOLS: 'школа',
      PREV_SCHOOLS: 'Попередня школа',
    },
    next: {
      NEXT: 'Наступні',
      SCHOOLS: 'школи',
      NEXT_SCHOOLS: 'Наступні школи',
    },
    LEADING_SCHOOLS: 'Провідні школи',
    CLOSEST_SCHOOLS: 'Найближчі провідні школи',
    CLOSED: 'Заклад закрит',
    NOT_CLOSED: 'Заклад не закрит',
    VIDEO_YOUTUBE: 'Video youtube content',
    VIDEO_RATIO: 'Відношення сторін відео',
    VIDEO_RATIO_PLACEHOLDER: 'width / height',
  },
}

export default lang
