const lang = {
  company: {
    NAME: 'SchoolHub',
    ADDRESS: 'Unit City, вулиця Сім’ї Хохлових 8',
    FULL_ADDRESS: 'Unit City, вулиця Сім’ї Хохлових 8, м. Київ, Україна, 04119',
    COUNTRY: 'Україна',
    CITY: 'Київ',
    POSTCODE: '04119',
    ADDRESS_VALUE:
      'https://www.google.com/maps/@50.4672763,30.4601154,18.02z?entry=ttu',
    EMAIL: 'admin@schoolhub.com.ua',
    EMAIL_URL: 'mailto:admin@schoolhub.com.ua',
    // EMAIL: 'schoolhub.com.ua@gmail.com',
    // EMAIL_URL: 'schoolhub.com.ua@gmail.com',
    PHONE: '+380 (99) 202-11-14',
    PHONE_VALUE: '+380992021114',
    PHONE_URL: 'tel:+380992021114',
    VIBER: '+380 (99) 202-11-14',
    VIBER_VALUE: '+380992021114',
    VIBER_URL: 'viber://chat/?number=+380992021114',
    TELEGRAM: '@schoolhub_com_ua',
    TELEGRAM_URL: 'https://t.me/schoolhub_com_ua',
    LEGAL: 'ФОП Лазарєв А.В.',
  },
}

export default lang
