import { IS_SNAP } from '@config'
import { IS_ADMIN } from '@config/client'
import firebase from 'firebase/app'
import firebaseConfig from '@config/firebase'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import 'firebase/analytics'

let analyticsInit
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)

  if ('measurementId' in firebaseConfig && !IS_SNAP && !IS_ADMIN) {
    analyticsInit = firebase.analytics()
    firebase.performance()
  }
}

export const analytics = analyticsInit

export const auth = firebase.auth()
export type IAuth = firebase.auth.Auth
export type IUser = firebase.User
export type IUserCredential = firebase.auth.UserCredential
export type IUserMetadata = firebase.auth.UserMetadata
export type IAdditionalUserInfo = firebase.auth.AdditionalUserInfo

export const db = firebase.database()
export type IDatabase = firebase.database.Database
export type IDataSnapshot = firebase.database.DataSnapshot
export type IReference = firebase.database.Reference
export type IEventType = firebase.database.EventType
export type IThenableReference = firebase.database.ThenableReference

export const firestore = firebase.firestore()
export const { Timestamp, FieldValue } = firebase.firestore
export type TTimestamp = firebase.firestore.Timestamp
export const { GeoPoint } = firebase.firestore
export type IQueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot
export type ISnapshotOptions = firebase.firestore.SnapshotOptions

firestore.enablePersistence({ synchronizeTabs: true }).then()

export const storage = firebase.storage()

export default firebase
