const lang = {
  TITLE: 'Ввійти',
  SUBHEADER: 'Ввійти як існуючий користувач',
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  REMEMBER_ME: 'Запам`ятати?',
  REGISTER: 'Зареєструватися',
  RESEND: 'Забули пароль?',
  SUBMIT: 'Ввійти',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  FAVORITE: 'Ввійдить для додавання до обранного',
}

export default lang
