import { SHOW, HIDE, REVOKE } from './types'
import type {
  IState,
  TImages,
  IShowAction,
  IHideAction,
  IRevokeAction,
  TActionHandler,
} from './types'

export const initialState: IState = {
  isOpen: false,
  images: [],
  startIndex: 0,
}

export function show(images: TImages, startIndex = 0): IShowAction {
  return {
    type: SHOW,
    payload: { images, startIndex },
  }
}

export function hide(): IHideAction {
  return { type: HIDE }
}

export function revoke(): IRevokeAction {
  return { type: REVOKE }
}

const ACTION_HANDLERS = {
  [SHOW]: (state: IState, action: IShowAction) => {
    const { images, startIndex } = action.payload
    return {
      ...state,
      isOpen: true,
      images,
      startIndex,
    }
  },
  [HIDE]: (state: IState) => ({
    ...state,
    isOpen: false,
  }),
  [REVOKE]: () => initialState,
}

export default function lightboxReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
