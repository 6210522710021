const lang = {
  vinnytska: 'Винницкая',
  volynska: 'Волынская',
  dnipropetrovska: 'Днепропетровская',
  donetska: 'Донецкая',
  zhytomyrska: 'Житомирская',
  zakarpatska: 'Закарпатская',
  zaporizka: 'Запорожская',
  'ivano-frankivska': 'Ивано-Франковская',
  kyivska: 'Киевская',
  kirovohradska: 'Кировоградская',
  luhanska: 'Луганская',
  lvivska: 'Львовская',
  mykolaivska: 'Николаевская',
  odeska: 'Одесская',
  poltavska: 'Полтавская',
  rivnenska: 'Ровенская',
  sumska: 'Сумская',
  ternopilska: 'Тернопольская',
  kharkivska: 'Харьковская',
  khersonska: 'Херсонская',
  khmelnytska: 'Хмельницкая',
  cherkaska: 'Черкасская',
  chernihivska: 'Черниговская',
  chernivetska: 'Черновицкая',
  ark: 'Автономная Республика Крым',
}

export default lang
