const lang = {
  TITLE: 'Зарегистрироваться',
  SUBHEADER: 'Создать нового пользователя',
  FIRST_NAME: `Имя`,
  LAST_NAME: 'Фамилия',
  DISPLAY_NAME: `Полное имя`,
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  CONFIRM_PASSWORD: 'Подтверждение пароля',
  RESEND: 'Забыли пароль?',
  LOGIN: 'Войти',
  SUBMIT: 'Зарегистрироваться',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  ROLE_USER: 'Родители ученика',
  ROLE_INSTITUTION: 'Учебное заведение',
}

export default lang
