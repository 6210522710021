const lang = {
  appBar: {
    HOME: 'Головна сторінка',
    CATEGORIES: 'Категорії',
    DEFAULT_CATEGORIES: 'Виберіть категорії',
    menu: {
      KINDERGARTENS: 'Дитячі сади',
      SCHOOLS: 'Школи',
      COLLEGES: 'Коледжі',
      COURSES: 'Курси',
      REPETITORS: 'Репетитори',
      UNIVERSITIES: 'ВНЗ',
      ABROAD: 'За кордоном',
    },
    adminMenu: {
      SCHOOLS: 'Школи',
      COMMENTS: 'Коментарі',
      GROUPS: 'Групи',
      ARTICLES: 'Статті',
    },
    mobileMenu: {
      ITEM: 'Мобільне меню',
      HOME: 'Головна',
      FILTER: 'Фильтри',
      CATALOG: 'Каталог',
      FAVORITE: 'Обране',
      COMPARE: 'Порівняти',
      VIEWED: 'Переглянуті',
      REVIEWS: 'Мої відгуки',
      NOTIFICATION: 'Повідомлення',
      MENU: 'Меню',
    },
    ARIA_LANGUAGE: 'Вибір мови',
    lang: {
      UA: 'Українською',
      RU: 'По русски',
    },
    langShort: {
      UA: 'UA',
      RU: 'RU',
    },
    profile: {
      LOGIN: 'Вхід / Реєстрація',
      LOGOUT: 'Вихід',
      LOGIN_DESCRIPTION: 'Авторизуйтесь для отримання розширених можливостей',
      SETTING: 'Налаштування',
      INFORMATION: 'Особисті дані',
    },
    NO_ITEMS: 'Нічого не знайдено',
  },
}

export default lang
