import { TError as TErrorData } from '@services/handleError'
import { IImage, TImages } from '@utils/types/image'
import { TLocale } from '@i18n'
import { TTimestamp } from '@services/firebase'

export type TArticleType = 'news' | 'schools' | 'other'

export interface IArticleSearch {
  id: string
  lang: TLocale
  articleType: TArticleType
  shortTitle: string
  shortDescription: string
  author: string | null
  internalUrl: string | null
  publicUrl: string | null
  logo: IImage | null
  vipFrom: TTimestamp | null
  vipTo: TTimestamp | null
  vipUpdated: number | null
  vip: boolean
  created: TTimestamp
  updated: TTimestamp
  visibility: boolean
  deleted: boolean
}

export type TArticlesSearch = Array<IArticleSearch>

export interface IArticle extends IArticleSearch {
  title: string
  description: string
  meta: {
    title: string
    description: string
  }
  content: any
  images: TImages | null
}

export type TArticlesObjects = {
  [key in string]: IArticle
}

export type TIsReady = boolean
export type TError = TErrorData

export interface IAllArticles {
  ua: TArticlesObjects
  ru: TArticlesObjects
}

export interface IState {
  isReady: TIsReady
  search: TArticlesSearch
  contents: IAllArticles
}

// Fetch article

export const FETCH_ARTICLE_START = 'articles/FETCH_ARTICLE_START'

export interface IFetchArticleStartAction {
  type: 'articles/FETCH_ARTICLE_START'
  payload: {
    id: string
    lang: TLocale
  }
}

export const FETCH_ARTICLE_FINISHED = 'articles/FETCH_ARTICLE_FINISHED'

export interface IFetchArticleFinishedAction {
  type: 'articles/FETCH_ARTICLE_FINISHED'
  payload: IArticle
}

export const FETCH_ARTICLE_FAILED = 'articles/FETCH_ARTICLE_FAILED'

export interface IFetchArticleFailedAction {
  type: 'articles/FETCH_ARTICLE_FAILED'
  payload: TError
}

// Fetch Articles

export const FETCH_ARTICLES_START = 'articles/FETCH_ARTICLES_START'

export interface IFetchArticlesStartAction {
  type: 'articles/FETCH_ARTICLES_START'
  payload: TLocale
}

export const FETCH_ARTICLES_FINISHED = 'articles/FETCH_ARTICLES_FINISHED'

export interface IFetchArticlesFinishedAction {
  type: 'articles/FETCH_ARTICLES_FINISHED'
  payload: {
    lang: TLocale
    data: TArticlesSearch
  }
}

export const FETCH_ARTICLES_FAILED = 'articles/FETCH_ARTICLES_FAILED'

export interface IFetchArticlesFailedAction {
  type: 'articles/FETCH_ARTICLES_FAILED'
  payload: TError
}

// Fetch All Articles

export const FETCH_ALL_ARTICLES_START = 'articles/FETCH_ALL_ARTICLES_START'

export interface IFetchAllArticlesStartAction {
  type: 'articles/FETCH_ALL_ARTICLES_START'
}

export const FETCH_ALL_ARTICLES_FINISHED =
  'articles/FETCH_ALL_ARTICLES_FINISHED'

export interface IFetchAllArticlesFinishedAction {
  type: 'articles/FETCH_ALL_ARTICLES_FINISHED'
  payload: TArticlesSearch
}

export const FETCH_ALL_ARTICLES_FAILED = 'articles/FETCH_ALL_ARTICLES_FAILED'

export interface IFetchAllArticlesFailedAction {
  type: 'articles/FETCH_ALL_ARTICLES_FAILED'
  payload: TError
}

// Revoke

export const REVOKE = 'articles/REVOKE'

export interface IRevokeAction {
  type: 'articles/REVOKE'
}

// Actions

export type TActionHandler =
  | IFetchArticleStartAction
  | IFetchArticleFinishedAction
  | IFetchArticleFailedAction
  | IFetchArticlesStartAction
  | IFetchArticlesFinishedAction
  | IFetchArticlesFailedAction
  | IFetchAllArticlesStartAction
  | IFetchAllArticlesFinishedAction
  | IFetchAllArticlesFailedAction
  | IRevokeAction

// hook

export interface IUseArticles {
  isReady: TIsReady
  article: IArticle | null
  articlesSearch: TArticlesSearch | null
  fetchArticle: (
    id?: string | null,
    callback?: (data: IArticle) => void,
  ) => void
  fetchArticles: (
    isAdmin?: boolean,
    callback?: (articles: TArticlesSearch) => void,
  ) => void
  fetchAllArticles: (callback?: (articles: TArticlesSearch) => void) => void
}
