import { SHOW, HIDE, REVOKE } from './types'
import type {
  IState,
  IData,
  IParams,
  IShowAction,
  IHideAction,
  IRevokeAction,
  TActionHandler,
  TCurrentType,
} from './types'

const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent)

const defaultParams: IParams = {
  anchor: 'right',
  variant: 'temporary',
  className: '',
  disableBackdropTransition: !iOS,
  disableDiscovery: iOS,
  disableSwipeToOpen: iOS,
  hysteresis: 0.52,
  minFlingVelocity: 450,
  swipeAreaWidth: 20,
  transitionDuration: undefined,
}

export const initialState: IState = {
  isOpen: false,
  currentType: null,
  data: {},
  params: defaultParams,
}

export function showDrawer(
  currentType: TCurrentType,
  data: IData,
  params: IParams,
): IShowAction {
  return {
    type: SHOW,
    payload: { currentType, data, params },
  }
}

export function hideDrawer(): IHideAction {
  return { type: HIDE }
}

export function revokeDrawer(): IRevokeAction {
  return { type: REVOKE }
}

const ACTION_HANDLERS = {
  [SHOW]: (state: IState, action: IShowAction) => {
    const { currentType, data, params } = action.payload
    return {
      ...state,
      isOpen: true,
      currentType,
      data: {
        ...state.data,
        ...data,
      },
      params: {
        ...defaultParams,
        ...params,
      },
    }
  },
  [HIDE]: (state: IState) => ({
    ...state,
    isOpen: false,
  }),
  [REVOKE]: () => initialState,
}

export default function drawerReducer(
  state: IState = initialState,
  action: TActionHandler,
): IState {
  const handler = ACTION_HANDLERS[action.type]

  // @ts-ignore
  return handler ? handler(state, action) : state
}
