const lang = {
  vip: {
    LABEL: 'VIP',
    NOT_VIP: 'Не VIP',
    levels: {
      TITLE: 'Рівень VIP',
      DEFAULT: 'Виберіть рівень VIP',
      list: {
        silver: 'Silver',
        gold: 'Gold',
        platinum: 'Platinum',
      },
    },
    FROM: 'Від',
    TO: 'До',
    COUNT: 'Лічильник',
    IS_UPDATED: 'Оновити',
  },
}

export default lang
