import { IImage } from '@utils/types/image'

export type TIsOpen = boolean

export type TImages = Array<IImage> | []

export interface IState {
  isOpen: TIsOpen
  images: TImages
  startIndex: number
}

export interface IUseLightbox {
  isOpen: TIsOpen
  images: TImages
  startIndex: number
  showLightbox: (images: TImages, startIndex: number) => void
  hideLightbox: () => void
  revokeLightbox: () => void
}

export const SHOW = 'lightbox/SHOW'

export interface IShowAction {
  type: 'lightbox/SHOW'
  payload: {
    images: TImages
    startIndex: number
  }
}

export const HIDE = 'lightbox/HIDE'

export interface IHideAction {
  type: 'lightbox/HIDE'
}

export const REVOKE = 'lightbox/REVOKE'

export interface IRevokeAction {
  type: 'lightbox/REVOKE'
}

export type TActionHandler = IShowAction | IHideAction | IRevokeAction
