const lang = {
  'bila-tserkva': 'Білоцерківський',
  boryspil: 'Бориспільський',
  brovary: 'Броварський',
  bucha: 'Бучанський',
  vyshhorod: 'Вишгородський',
  obukhiv: 'Обухівський',
  fastiv: 'Фастівський',
}

export default lang
