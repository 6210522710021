import React, { ReactElement, ReactNode, Ref } from 'react'
import IconButtonComponent from '@mui/material/IconButton'
import { useIsMobile } from '@styles/useMediaQuery'

interface IProps {
  children: ReactNode
  classes?: object
  className?: string
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  component?: any
  loading?: boolean
  disabled?: boolean
  ref?: Ref<any>
  fullWidth?: boolean
  href?: string
  onClick?: (arg0?: any) => any
  size?: 'large' | 'medium' | 'small'
  type?: string
  variant?: 'contained' | 'outlined' | 'text'
  sx?: object
  disableRipple?: boolean
  disableFocusRipple?: boolean
  edge?: 'start' | 'end' | false
}

export default function IconButton({
  children,
  classes,
  className,
  color,
  component,
  loading,
  disabled,
  ref,
  fullWidth,
  href,
  onClick,
  size,
  type,
  variant,
  disableRipple,
  disableFocusRipple,
  edge,
  ...selfProps
}: IProps): ReactElement {
  const isMobile = useIsMobile()
  const disableRippleVal = disableRipple || isMobile
  const disableFocusRippleVal = disableFocusRipple || isMobile

  return (
    <IconButtonComponent
      classes={classes}
      className={className}
      color={color}
      component={component}
      disabled={disabled || loading}
      ref={ref}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      size={size}
      type={type}
      variant={variant}
      disableRipple={disableRippleVal}
      disableFocusRipple={disableFocusRippleVal}
      edge={edge}
      {...selfProps}
    >
      {children}
    </IconButtonComponent>
  )
}
