import districts from './districts'
import cities from './cities'
import regions from './regions'

const description =
  'Описание заведений, фото, адреса, телефон и другие контакты, территория обслуживания'

type TPage = string | number

const getPage = (page: TPage): string => {
  return page ? `. Сторінка ${page}` : ''
}

const lang = {
  location: {
    districts,
    cities,
    regions,
    label: 'Расположение',
    REGION_LABEL: (region: string): string => `${region} обл`,
    CITY_LABEL: (city: string): string => `г. ${city}`,
    labels: {
      regions: 'Область *',
      cities: 'Город *',
      districts: 'Район',
      distance: 'Дистанционно',
    },
    default: {
      regions: 'Выберите область',
      cities: 'Выберите город',
      districts: 'Виберите район',
    },
    meta: {
      h1City: (category: string, region: string, city: string): string =>
        `${category} в городе ${city}`,
      h1Region: (category: string, region: string): string =>
        `${category}, ${region} область`,
      h1Category: (category: string): string => `Выбор ${category}`,
      titleCity: (
        category: string,
        region: string,
        city: string,
        page: TPage,
      ): string =>
        `${category} в городе ${city}, ${region} область${getPage(page)}`,
      titleRegion: (category: string, region: string, page: TPage): string =>
        `${category}, ${region} область${getPage(page)}`,
      titleCategory: (category: string, page: TPage): string =>
        `Выбор ${category}${getPage(page)}`,
      descriptionCity: (
        category: string,
        region: string,
        city: string,
        page: TPage,
      ): string =>
        `${category} в городе ${city}, ${region} область. ${description}${getPage(
          page,
        )}`,
      descriptionRegion: (
        category: string,
        region: string,
        page: TPage,
      ): string =>
        `${category}, ${region} область. ${description}${getPage(page)}`,
      descriptionCategory: (category: string, page: TPage): string =>
        `Выбор ${category}. ${description}${getPage(page)}`,
    },
  },
}

export default lang
