import { ISearchData } from '@features/Search/types'
import { TVipLevel } from '@pages/School/types'

const now = Math.floor(+Date.now() / 1000)

export const isVip = (
  { vip, vipLevel, vipFrom, vipTo }: ISearchData,
  level?: TVipLevel | null,
  exceptLevel?: TVipLevel,
): boolean => {
  return (
    vip &&
    vipFrom?.seconds &&
    vipFrom?.seconds <= now &&
    vipTo?.seconds &&
    vipTo?.seconds >= now &&
    (!level || vipLevel === level) &&
    (!exceptLevel || vipLevel !== exceptLevel)
  )
}
