import React from 'react'
import Typography from './Typography'
import { IProps, TTypography } from './types'

export default function H4({
  children,
  align,
  classes,
  className,
  color,
  display,
  gutterBottom,
  noWrap,
  paragraph,
  itemProp,
  notUndefined = false,
}: IProps): TTypography {
  return !notUndefined || children !== undefined ? (
    <Typography
      variant="h4"
      align={align}
      classes={classes}
      className={className}
      color={color}
      display={display}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      itemProp={itemProp}
      paragraph={paragraph}
    >
      {children}
    </Typography>
  ) : null
}
