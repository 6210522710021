const lang = {
  sitemap: {
    all: {
      meta: {
        TITLE: 'Карта сайта',
        DESCRIPTION: 'Карта сайта. Школы',
      },
      text: {
        H1: 'Карта сайта',
        HOME: 'Главная страница',
        SCHOOLS: 'Школы',
        KYIVSKA: 'Киевская обл',
        KYIV: 'г. Киев',
      },
    },
  },
}

export default lang
