import colors from '@colors'

export const globalStyles = {
  body: {
    margin: 0,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  code: {
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
  },
  'h1, h2, h3, h4, p, ul, li': {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  h1: {
    color: colors.violet[200],
    fontSize: 32,
    fontWeight: 300,
  },
  h2: {
    color: colors.violet[200],
    fontSize: 24,
    fontWeight: 300,
  },
  h3: {
    color: colors.violet[300],
    fontSize: 20,
    fontWeight: 400,
  },
  h4: {
    color: colors.greyList[800],
    fontSize: 18,
    fontWeight: 500,
  },
  h5: {
    color: colors.greyList[900],
    fontSize: 14,
    fontWeight: 700,
  },
  h6: {
    color: colors.black,
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'italic',
  },
  p: {
    color: colors.blackList[900],
    fontSize: 16,
    fontWeight: 400,
  },
  li: {
    color: colors.blackList[900],
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 8,
    '&::marker': {
      color: colors.green[200],
    },
  },
}
