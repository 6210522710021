import React, { memo, Ref, useMemo, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import useTranslation from '@i18n/useI18n'

interface ITo {
  pathname?: string
  search?: string
  hash?: string
}

type TTo = (location: ITo) => ITo | string

interface IProps {
  to: string | ITo | TTo
  withoutLang?: boolean
  replace?: boolean
  innerRef?: Ref<HTMLAnchorElement>
  children: ReactElement | string
  rel?: string
  target?: string
  className?: string
  isGlobal?: boolean
  itemProp?: string
  ariaLabel?: string
  onClick?: any
}

function LinkComponent({
  to,
  withoutLang,
  replace,
  innerRef,
  children,
  rel,
  target,
  className,
  itemProp,
  ariaLabel,
  onClick,
  isGlobal = false,
}: IProps): ReactElement {
  const { i18n } = useTranslation()

  const lngTo = useMemo(() => {
    const lng = `/${i18n.language}`

    if (typeof to === 'function') {
      return to
    }

    if (typeof to === 'string') {
      return to === '/' ? '/' : `${lng}${to}`
    }

    return { ...to, pathname: `${lng}${to.pathname}` }
  }, [to, i18n.language])

  return isGlobal ? (
    <a
      // @ts-ignore
      href={to}
      className={className}
      rel={rel}
      target={target}
      itemProp={itemProp}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      to={withoutLang ? to : lngTo}
      replace={replace}
      innerRef={innerRef}
      className={className}
      rel={rel}
      target={target}
      itemProp={itemProp}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export default memo(LinkComponent)
