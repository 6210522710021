import { showSnackBar } from '@containers/Snackbar/modules'
import firebase, { auth, Timestamp } from '@services/firebase'
import { ThunkDispatch } from 'redux-thunk'
import { updateUser } from '@features/User/modules'
import handleError, { TError, TErrorCode } from '@services/handleError'
import { TLocale } from '@i18n'

import {
  LOGIN_GOOGLE_FAILED,
  LOGIN_GOOGLE_FINISHED,
  LOGIN_GOOGLE_STARTED,
} from '../types'
import { TRole } from '@features/User'

// All Action Handlers are located in ../modules.ts

const _loginGoogleStart = () => {
  return { type: LOGIN_GOOGLE_STARTED }
}

const _loginGoogleFinished = () => {
  return { type: LOGIN_GOOGLE_FINISHED }
}

const _loginGoogleFailed = (code: TErrorCode, error?: TError) => {
  const handledError = handleError(code, error)
  return [
    {
      type: LOGIN_GOOGLE_FAILED,
      payload: handledError,
    },
    showSnackBar({
      message: handledError.message,
      variant: 'error',
    }),
  ]
}

export const loginGoogle =
  (locale: TLocale, callback?: () => void, role: TRole = 'user') =>
  async (dispatch: ThunkDispatch<any, any, any>): Promise<any> => {
    try {
      dispatch(_loginGoogleStart())

      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope('profile')
      const result = await auth.signInWithPopup(provider)

      if (result !== null && result?.additionalUserInfo?.isNewUser) {
        const {
          additionalUserInfo: {
            // @ts-ignore
            // eslint-disable-next-line camelcase
            profile: { family_name, given_name },
          },
          // @ts-ignore
          user: { uid, displayName, email },
        } = result

        dispatch(
          updateUser(
            uid,
            {
              userId: uid,
              displayName,
              role,
              email,
              emailVerified: true,
              firstName: given_name,
              lastName: family_name,
              phoneNumber: null,
              photoURL: null,
              locale,
              created: Timestamp.now(),
              deleted: false,
            },
            () => {
              dispatch(_loginGoogleFinished())

              if (callback) {
                callback()
              }
            },
          ),
        )
      } else {
        dispatch(_loginGoogleFinished())

        if (callback) {
          callback()
        }
      }
    } catch (error) {
      dispatch(_loginGoogleFailed('auth.popupGoogle', error))
    }
  }

export default loginGoogle
