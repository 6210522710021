import districtsInRegions from './districtsInRegions'

const lang = {
  ...districtsInRegions,
  holosiivskyi: 'Голосіївський',
  obolonskyi: 'Оболонський',
  pecherskyi: 'Печерський',
  podilskyi: 'Подільський',
  sviatoshynskyi: 'Святошинський',
  solomianskyi: `Солом'янський`,
  shevchenkivskyi: 'Шевченківський',
  darnytskyi: 'Дарницький',
  desnianskyi: 'Деснянський',
  dniprovskyi: 'Дніпровський',
  zamostianskyi: 'Замостянський',
  leninskyi: 'Ленінський',
  staromiskyi: 'Староміський',
  novokodatskyi: 'Новокодацький',
  tsentralnyi: 'Центральний',
  chechelivskyi: 'Чечелівський',
  sobornyi: 'Соборний ',
  'amur-nyzhnodniprovskyi': 'Амур-Нижньодніпровський',
  industrialnyi: 'Індустріальний',
  samarskyi: 'Самарський',
  bohunskyi: 'Богунський',
  korolovskyi: 'Корольовський',
  oleksandrivskyi: 'Олександрівський',
  zavodskyi: 'Заводський',
  komunarskyi: 'Комунарський',
  voznesenivskyi: 'Вознесенівський',
  khortytskyi: 'Хортицький',
  podilskii: 'Подільский',
  fortechnyi: 'Фортечний',
  lychakivskyi: 'Личаківський',
  sykhivskyi: 'Сихівський',
  frankivskyi: 'Франківський',
  zaliznychnyi: 'Залізничний',
  halytskyi: 'Галицький',
  inhulskyi: 'Інгульский',
  korabelnyi: 'Корабельний',
  kyivskyi: 'Київський',
  malynovskyi: 'Малиновський',
  prymorskyi: 'Приморський',
  suvorovskyi: 'Суворовський',
  moskovskyi: 'Московський',
  nemyshlianskyi: 'Немишлянський',
  slobidskyi: 'Слобідський',
  osnovianskyi: `Основ'янський`,
  sosnivskyi: 'Соснівський',
  prydniprovskyi: 'Придніпровський',
  kholodnohirskyi: 'Холодногірський',
  novobavarskyi: 'Новобаварський',
}

export default lang
